import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import Layout from "@layout/App";

import Link, { ToLink, ToBack, Logout } from "@component/UI/Link";
import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import _U from "@util/utilities";
import _D from "@config/defines";
import { BackHeader as Header } from "@layout/App/Header";
import _DX from "@util/dx";

import useBalance from "@hook/useBalance";

export default () => {
    const navigate = useNavigate();
    const [balance, setBalance] = useState(0);
    const { iam, saveIam } = useContext(AppContext);

    const [history, setHistory] = useState([]);
    const [winnings, setWinnings] = useState(null);

    const loadData = () => {
        if (!_CK.isLogin()) return;
        _DX.post({
            path: "/api/searchMyPlayList.do",
            data: {
                currPage: 1,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { games } = data;
                setHistory(games?.slice(0, 3));
            })
            .catch((e) => _DX.alert(e));

        _DX.post({ path: "/api/searchPlayerWins.do" })
            .then((res) => res.data)
            .then((data) => setWinnings(data))
            .catch((e) => _DX.alert(e));
    };

    useBalance((balance) => {
        setBalance(balance);
    });

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap">
                <div className="content">
                    <Link href="/app/my/information" className="mypage_profile_box">
                        <div>
                            <div className="mypage_img">
                                <img src="/assets/images/user_profile_lg.png" alt="profile" title="profile" />
                                <img className="profile_btn" src="/assets/images/profile_btn.png" alt="profile" title="profile" />
                            </div>
                            <div className="mypage_text">
                                <div className="mypage_name">{`${iam?.FIRSTNAME}${iam?.LASTNAME ? `, ${iam.LASTNAME}` : ""}`}</div>
                                <div className="mypage_email">{iam?.EMAIL}</div>
                            </div>
                        </div>
                    </Link>

                    <h4 className="title">Play Games</h4>
                    <div className="account">
                        <Link href="/app/my/wallet" className="name btn-arr_sm">
                            <button type="button">
                                My Wallet
                                <img src="/assets/images/arr_white_sm.png" />
                            </button>
                        </Link>
                        <div className="point">
                            <span className="peso">₱</span>
                            {_U.addComma(balance)}
                        </div>
                        <div className="actions">
                            <Link href="/app/my/charge" className="btn-arr_sm">
                                <button type="button">
                                    Charge
                                    <img src="/assets/images/arr_white_sm.png" />
                                </button>
                            </Link>
                            <Link href="/app/my/withdraw" className="btn-arr_sm">
                                <button type="button">
                                    Withdraw
                                    <img src="/assets/images/arr_white_sm.png" />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <h4 className="title">
                        <Link href="/app/my/games">
                            <button type="button">My Plays</button>
                        </Link>
                    </h4>
                    <div className="bl-cont">
                        <ul id="mypage_my_play">
                            {history.map((item, i) => (
                                <Link
                                    key={i}
                                    href={`/app/my/games/${item.PLAYS_ID}`}
                                    className={cx({ mypage_my_plays_li: true, win: item.COUNTWINNINGS > 0, mega: item.SVCNAME === "MEGA", base: item.SVCNAME === "BASE" })}
                                >
                                    <li>
                                        <div className="top">
                                            <div className="mypage_my_plays_date">{moment(item.REGDATE).format("ll HH:mm")}</div>
                                            <div className="mypage_my_plays_game">{_U.addComma(item.DRAWCOUNT)} Draw</div>
                                            <div className="mypage_my_plays_type">
                                                <span className={item.SVCNAME.toLowerCase()}>{item.SVCNAME}</span>
                                            </div>
                                            <div className="mypage_my_plays_result">
                                                {item.ISRAFFLE !== "Y" ? (
                                                    <p className="badge pending">Pending</p>
                                                ) : (
                                                    <p
                                                        className={cx({
                                                            badge: true,
                                                            win: item.COUNTWINNINGS > 0,
                                                            no_prize: item.ISRAFFLE === "Y" && item.COUNTWINNINGS === 0,
                                                        })}
                                                    >
                                                        {item.COUNTWINNINGS > 0 ? "Win" : "No Prize"}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="mypage_my_plays_bets">
                                                No. of Bets : {item.DRAWCOUNT} / ₱ {item.TOTALAMOUNT}
                                            </div>
                                            <div className="mypage_my_plays_ticket">Ticket No. #### #### #### #### #### #### #### ####</div>
                                        </div>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className="mypage_my_winnings">
                        <h4 className="title">My Winnings</h4>
                        <div className="mypage_my_winnings_type mega">
                            <div className="type">
                                <p>MEGA</p>
                            </div>
                            <div className="mypage_my_winnings_box">
                                <div className="mypage_my_winnings_item">
                                    <span>No. of Winnings</span>
                                    <span className="count">{winnings?.length === 2 ? _U.addComma(winnings[0].WINSCNT) : 0}</span>
                                </div>
                                <div className="mypage_my_winnings_item">
                                    <span>Unclaimed</span>
                                    <span className="count">{winnings?.length === 2 ? _U.addComma(winnings[0].CLAIMCNT) : 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="mypage_my_winnings_type base">
                            <div className="type">
                                <p>BASE</p>
                            </div>
                            <div className="mypage_my_winnings_box">
                                <div className="mypage_my_winnings_item">
                                    <span>No. of Winnings</span>
                                    <span className="count">{winnings?.length === 2 ? _U.addComma(winnings[1].WINSCNT) : 0}</span>
                                </div>
                                <div className="mypage_my_winnings_item">
                                    <span>Unclaimed</span>
                                    <span className="count">{winnings?.length === 2 ? _U.addComma(winnings[1].CLAIMCNT) : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Logout className="logout">
                    <button type="button">Sign Out</button>
                </Logout> */}
            </Container>
        </Layout>
    );
};

export { default as Charge } from "./charge";
export { default as Withdraw } from "./withdraw";
export { default as Wallet } from "./wallet";
/**
 * Styled-Components
 */
const Container = styled.div`
    &.nwrap {
        margin-top: 0px;
    }
`;
