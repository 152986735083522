import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import useIam from "@hook/useIam";
import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import Link, { ToBack, Back } from "@component/UI/Link";
import Dialog from "@component/UI/Dialog";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _DX from "@util/dx";
import _CK from "@util/cookie";
import _U from "@util/utilities";

export default () => {
    const [step, setStep] = useState(0);

    const handleOnAgree = () => {
        setStep(1);
    };

    return (
        <Layout noFooter header={<Header />}>
            {step === 0 && <Agreement onAgree={handleOnAgree} />}
            {step === 1 && <Account />}
        </Layout>
    );
};

const Agreement = ({ onAgree }) => {
    const [agr1, setAgr1] = useState(false);
    const [agr2, setAgr2] = useState(false);
    const [content, setContent] = useState("");

    const loadData = () => {
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "Terms",
                type_group: "Statement",
                currPage: 1,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { games } = data;
                if (games?.length > 0) {
                    setContent(games[0].TERMSCONTENTS);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="nwrap">
            <div className="content">
                <h4 className="title tc-main">PCSO STATEMENT</h4>
                <div className="form login">
                    <div className="terms" dangerouslySetInnerHTML={{ __html: content }} />
                    <div className="form-item mt-50">
                        <div className="form-field form-checkbox">
                            <label id="feildChek01" className="form-label">
                                <input name="feildChek01" type="checkbox" id="feildChek01" checked={agr1} onClick={() => setAgr1(!agr1)} />
                                <span>
                                    I agree that I have read and understood the PCSO Prutas Panalo Data Privacy Statement and the General Terms and Conditions governing the use of PCSO Prutas Panalo.
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-field form-checkbox">
                            <label id="feildChek02" className="form-label">
                                <input name="feildChek02" type="checkbox" id="feildChek02" checked={agr2} onClick={() => setAgr2(!agr2)} />
                                <span>I am interested in receiving promotional offers email or SMS (optional).</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn-group mt-30">
                    <Back className="btn btn-gray">
                        <button type="button">Disagree</button>
                    </Back>
                    <button type="button" className="btn btn-primary" disabled={!agr1} onClick={onAgree}>
                        I agree
                    </button>
                </div>
            </div>
        </div>
    );
};

let _timer = null;

const Account = () => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(_DEF.accountType[0].value);
    const [data, setData] = useState({});
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);
    const [isOTPSended, setIsOTPSended] = useState(false);
    const [isOTPVerified, setIsOTPVerified] = useState(false);
    const [isAgree, setIsAgree] = useState(false);

    const isValidForm = () => {
        if (tab === "EMAIL") {
            if (!data.email) return false;
            if (!_U.isEmail(data.email)) return false;
        }

        if (!isOTPVerified) return false;
        if (!data.mobile) return false;
        // if (!data.otp) throw false;
        if (!data.pin) return false;
        if (!_U.isPin(data.pin)) return false;
        if (data.pin !== data.pinConfirm) return false;
        if (!isAgree) return false;
        return true;
    };

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case "mobile":
                value = value.replace(/\D/g, "").slice(0, 11);
                break;
            case "otp":
                value = value.replace(/\D/g, "").slice(0, 6);
                break;
            case "pin":
            case "pinConfirm":
                value = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 12);
                break;
        }

        setData({ ...data, [name]: value });
    };

    const handleSend = () => {
        if (!data.mobile) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "SENDOTP",
                TelNum: data.mobile,
            },
        })
            .then((res) => {
                setIsOTPSended(true);
                _timer = setTimeout(() => {
                    if (!isOTPVerified) {
                        setIsOTPSended(false);
                    }
                }, 180000);
            })
            .catch((e) => _DX.alert(e));
    };

    const handleVerify = () => {
        if (!data.otp) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "CHKOTP",
                TelNum: data.mobile,
                OtpNum: data.otp,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                if (_timer) {
                    clearTimeout(_timer);
                    _timer = null;
                }
                setIsOTPVerified(true);
                Dialog({ title: "Notice", text: "OTP verification was successful.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    const handleJoin = () => {
        const { email, mobile, pin } = data;
        const params = { email, phoneNo: mobile, password: pin };

        _DX.post({ path: "/api/insertPlayerInfo.do", data: params })
            .then((res) => res.data)
            .then((data) => {
                _CK.setAuth(data);
                navigate("/app/join/information", { replace: true });
            })
            .catch((e) => Dialog({ title: "Error", text: e.message, button: "Confirm" }));
    };

    return (
        <div className="nwrap t-tab">
            {/* <div className="tab">
                {_DEF.accountType.map((item, i) => (
                    <button key={i} type="button" className={cx({ active: tab === item.value })} onClick={() => setTab(item.value)}>
                        {item.label}
                    </button>
                ))}
            </div> */}
            <div className="content">
                <div className="form login">
                    {tab === "EMAIL" && (
                        <div className="form-item">
                            <div className={cx({ "form-field": true, error: data.email && !_U.isEmail(data.email) })}>
                                <input name="email" type="email" id="email" placeholder="Email Address" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                                <label id="email" className="form-label">
                                    Email Address
                                </label>
                            </div>
                            {data.email && !_U.isEmail(data.email) && <p className="msg">Please enter a valid email address.</p>}
                        </div>
                    )}
                    <div className="form-item">
                        <div className="form-field">
                            <input name="mobile" type="text" id="mobile" value={data.mobile || ""} placeholder="Phone No" onChange={handleOnChange} disabled={isOTPSended} />
                            <label id="mobile" className="form-label">
                                Phone No
                            </label>
                            <button type="button" className="btn-send" onClick={handleSend} disabled={isOTPSended}>
                                Send
                            </button>
                        </div>
                    </div>
                    {isOTPSended && (
                        <div className="form-item">
                            <div className="form-field">
                                <input name="otp" type="number" id="OTP" placeholder="OTP" value={data.otp || ""} onChange={handleOnChange} disabled={isOTPVerified} />
                                <label id="OTP" className="form-label">
                                    OTP
                                </label>
                                <button type="button" className="btn-send" onClick={handleVerify} disabled={isOTPVerified}>
                                    Verify
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="form-item">
                        <div className={cx({ "form-field": true, error: data.pin && !_U.isPin(data.pin) })}>
                            <input name="pin" type={isShowPassword ? "text" : "password"} id="pin" placeholder="PIN" onChange={handleOnChange} />
                            <label id="pin" className="form-label">
                                PIN
                            </label>
                            <div className="password-info">
                                <button type="button" className={cx({ "btn-show": true, hide: !isShowPassword })} onClick={() => setIsShowPassword(!isShowPassword)} style={{ display: "block" }} />
                            </div>
                        </div>
                        {data.pin && !_U.isPin(data.pin) && (
                            <p className="msg">
                                You may use PIN to login in the future <br />
                                Please enter 6 - 12 alphanumeric without special character
                            </p>
                        )}
                    </div>
                    <div className="form-item">
                        <div className={cx({ "form-field": true, error: data.pinConfirm && data.pin !== data.pinConfirm })}>
                            <input name="pinConfirm" type={isShowPasswordConfirm ? "text" : "password"} id="pin-confirm" placeholder="Confirm PIN" onChange={handleOnChange} />
                            <label id="pin-confirm" className="form-label">
                                Confirm PIN
                            </label>
                            <div className="password-info">
                                <button
                                    type="button"
                                    className={cx({ "btn-show": true, hide: !isShowPasswordConfirm })}
                                    onClick={() => setIsShowPasswordConfirm(!isShowPasswordConfirm)}
                                    style={{ display: "block" }}
                                />
                            </div>
                        </div>
                        {data.pinConfirm && data.pin !== data.pinConfirm && (
                            <p className="msg">
                                The PIN and PIN confirmation do not match. <br />
                                Please check the PIN.
                            </p>
                        )}
                    </div>
                    <div className="form-item">
                        <div className="form-field form-checkbox">
                            <label id="terms" className="form-label">
                                <input name="terms" type="checkbox" id="terms" checked={isAgree} onClick={() => setIsAgree(!isAgree)} />
                                <span>
                                    By login or sign up, you are confirming that you are above 18 years old, and agreed to our
                                    <Link href="/app/terms" className="btn-text udline">
                                        <a>TERMS AND CONDITIONS</a>
                                    </Link>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn-group mt-30">
                    <button type="button" className="btn btn-primary" onClick={handleJoin} disabled={!isValidForm()}>
                        Register
                    </button>
                </div>
            </div>
        </div>
    );
};

const FormItem = ({ name, type, label, value, onChange, button, onClick }) => {
    return (
        <div className="form-item">
            <div className="form-field">
                <input name={name} type={type || "text"} value={value[name] || ""} onChange={(e) => onChange && onChange({ ...value, [e.target.name]: e.target.value })} placeholder={label} />
                {button && (
                    <button onClick={onClick} className={button.className}>
                        {button.text}
                    </button>
                )}
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const Information = () => {
    const navigate = useNavigate();

    const [defGenders, setDefGenders] = useState([]);
    const [defOccupations, setDefOccupations] = useState([]);
    const [defMonthlyFixedIncomes, setDefMonthlyFixedIncomes] = useState([]);

    const [data, setData] = useState({});
    const [isMore, setIsMore] = useState(false);
    const [isReview, setIsReview] = useState(false);

    useIam({
        onSuccess: (iamData) => {
            if (!iamData) return;
            const { EMAIL: email, PHONENO: mobile } = iamData;
            setData({ ...data, email: email || null, mobile: mobile?.replace(/-/gi, "") });
        },
    });

    const isValidForm = () => {
        if (!data.firstName) return false;
        if (!data.lastName) return false;
        if (!data.gender) return false;
        if (!data.birthday) return false;
        if (!data.email) return false;
        if (!_U.isEmail(data.email)) return false;
        return true;
    };

    const handleFormClicked = (e) => {
        switch (e.target.name) {
            case "fullName":
                Dialog({ title: "Set Full Name", body: <InfoDlgBody.Name data={data} /> }).then((content) => {
                    if (!content) return;
                    const { firstName, middleName, lastName, suffix } = content;
                    let fullName = firstName;
                    if (middleName) fullName += " " + middleName;
                    fullName += " " + lastName;
                    if (suffix) fullName += " " + suffix;
                    const names = {
                        firstName,
                        middleName: middleName || null,
                        lastName,
                        suffix: suffix || null,
                    };
                    setData({ ...data, ...names, fullName });
                });
                break;
            case "address":
                Dialog({ title: "My Address", body: <InfoDlgBody.Address data={data} /> }).then((content) => {
                    if (!content) return;
                    const { address, zipcode, city } = content;
                    const addr = {
                        address,
                        zipcode,
                        city,
                    };
                    setData({ ...data, ...addr });
                });
                break;
        }
    };

    const handleOnSubmit = () => {
        const params = {
            email: data.email || null,
            firstName: data.firstName || null,
            middleName: data.middleName || null,
            lastName: data.lastName || null,
            suffix: data.suffix || null,
            genderCode: data.gender,
            birthDate: data.birthday ? moment(data.birthday, "YYYY-MM-DD").format("MM-DD-YYYY") : null,
            zipCode: data.zipcode || null,
            address1: data.address || null,
            cityCode: data.city || null,
            avgSalary: data.monthlyFixedIncome || null,
            jobCode: data.occupation || null,
            tinNo: data.tax || null,
        };

        Dialog({ title: "Notice", text: "To Proceed 'KYC'", button: "OK" }).then(() => {
            _DX.post({ path: "/api/updatePlayerInfo.do", data: params })
                .then((res) => {
                    if (res.error !== 0) {
                        throw { message: res.message };
                    }
                    Dialog({ title: "Notice", text: "Welcome to Prutas Panalo.", button: "OK" }).then(() => {
                        if (_CK.isDevice()) {
                            window.location.href = `${_CFG.app.schema}logined?auth=${encodeURIComponent(_CK.getAuth())}`;
                        } else {
                            navigate("/app", { replace: true });
                        }
                    });
                })
                .catch((e) => Dialog({ title: "Error", text: e.message, button: "Confirm" }));
        });
    };

    useEffect(() => {
        _DX.load("AP01").then((data) => setDefGenders(data));
        _DX.load("AP06").then((data) => setDefOccupations(data));
        _DX.load("AP07").then((data) => setDefMonthlyFixedIncomes(data));
    }, []);

    return (
        <Layout noFooter header={<Header hasBack={false} hasProfile={false} />}>
            <InformationContainer className="nwrap">
                {isReview ? (
                    <div className="content">
                        <p>Please complete the following information to proceed. Ensure the details provided is correct, changes will not be allow after submission.</p>
                        <hr />
                        <p className="ft-r-16">Review your information</p>
                        <div className="bl-cont mt-15">
                            <table className="info_table">
                                <colgroup>
                                    <col width="40%" />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{data.fullName}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>{_DEF.getDefinedLabel(_DEF.gender, data.gender)}</td>
                                    </tr>
                                    <tr>
                                        <td>Birthday</td>
                                        <td>{moment(data.birthday).format("MM.DD.YYYY")}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{data.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td>{data.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>My Address</td>
                                        <td>{data.address || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Occupation</td>
                                        <td>{data.occupation ? _DEF.getDefinedLabel(_DEF.occupation, data.occupation) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax Identification Number</td>
                                        <td>{data.tax || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Monthly Fixed Income</td>
                                        <td>{data.monthlyFixedIncome ? _DEF.getDefinedLabel(_DEF.monthlyFixedIncome, data.monthlyFixedIncome) : "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="btn-group mt-30">
                            <button type="button" className="btn btn-gray" onClick={() => setIsReview(false)}>
                                Back
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleOnSubmit}>
                                Okay
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="content">
                        <div className="form login">
                            <div className="form-item">
                                <div className="form-field">
                                    <span className="tc-main">
                                        Please complete the following information to proceed. Ensure the details provided is correct, changes will not be allow after submission.
                                    </span>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-field">
                                    <input name="fullName" type="text" id="fullName" placeholder="Full Name" readOnly onClick={handleFormClicked} defaultValue={data?.fullName} />
                                    <label id="fullName" className="form-label">
                                        Full Name
                                    </label>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-field">
                                    <select name="gender" id="gender" value={data.gender} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}>
                                        <option value="">Not Set</option>
                                        {defGenders.map((item, i) => (
                                            <option key={i} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label id="gender" className="form-label">
                                        Gender
                                    </label>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-field">
                                    <input
                                        name="birthday"
                                        type="date"
                                        id="birthday"
                                        placeholder="Birthday"
                                        max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        value={data.birthday || ""}
                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                    />
                                    <label id="birthday" className="form-label">
                                        Birthday
                                    </label>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className={cx({ "form-field": true, error: data.email && !_U.isEmail(data.email) })}>
                                    <input name="email" type="email" id="email" placeholder="Email" value={data.email || ""} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                                    <label id="email" className="form-label">
                                        Email
                                    </label>
                                </div>
                                {data.email && !_U.isEmail(data.email) && <p className="msg">Please enter a valid email address.</p>}
                            </div>
                            <div className="form-item">
                                <div className="form-field">
                                    <input name="mobile" type="text" id="mobile" placeholder="Phone" defaultValue={data.mobile || ""} readOnly />
                                    <label id="mobile" className="form-label">
                                        Phone
                                    </label>
                                </div>
                            </div>

                            <div className="form-option">
                                <p className="ft-r-14">Other Information (Optional)</p>
                                <div className="btn-group mt-15">
                                    <button type="button" className={cx({ btn: true, "btn-secondary": true, "btn-arr_more": true, on: isMore, off: !isMore })} onClick={() => setIsMore(!isMore)}>
                                        optional
                                    </button>
                                </div>

                                <div className={cx({ "option-cont": true, show: isMore })}>
                                    <div className="form-item">
                                        <div className="form-field">
                                            <input name="address" type="text" id="address" placeholder="Address" readOnly onClick={handleFormClicked} defaultValue={data?.address} />
                                            <label id="address" className="form-label">
                                                Address
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-item">
                                        <div className="form-field">
                                            <select name="occupation" id="occupation" value={data.occupation} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}>
                                                <option value="">Not Set</option>
                                                {defOccupations.map((item, i) => (
                                                    <option key={i} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <label id="gender" className="form-label">
                                                Occupation
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-item">
                                        <div className="form-field">
                                            <input
                                                name="tax"
                                                type="text"
                                                id="tax"
                                                placeholder="Tax Identification Number"
                                                value={data.tax || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^0-9-]/g, "").slice(0, 20) })}
                                            />
                                            <label id="tax" className="form-label">
                                                Tax Identification Number
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-item">
                                        <div className="form-field">
                                            <select
                                                name="monthlyFixedIncome"
                                                id="monthlyFixedIncome"
                                                value={data.monthlyFixedIncome}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">Select Income</option>
                                                {defMonthlyFixedIncomes.map((item, i) => (
                                                    <option key={i} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                            <label id="gender" className="form-label">
                                                Monthly Fixed Income
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-group mt-30">
                            <button type="button" className="btn btn-primary" disabled={!isValidForm()} onClick={() => setIsReview(true)}>
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </InformationContainer>
        </Layout>
    );
};

const InformationContainer = styled.div`
    .btn-arr_more {
        &.on {
            :after {
                transform: rotate(270deg);
            }
        }
        &.off {
            :after {
                transform: rotate(90deg);
            }
        }
    }
`;

const InfoDlgBody = {};
InfoDlgBody.Name = ({ data: dataInProps, resolve, reject }) => {
    const [data, setData] = useState(dataInProps || {});

    const isValidForm = () => {
        if (!data.firstName) return false;
        if (!data.lastName) return false;
        return true;
    };

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case "firstName":
            case "middleName":
            case "lastName":
                value = value.replace(/[^a-zA-Z]/g, "").slice(0, 32);
                break;
            case "suffix":
                value = value.replace(/[^a-zA-Z.]/g, "").slice(0, 32);
                break;
        }
        setData({ ...data, [name]: value });
    };

    const handleOnSubmit = () => {
        resolve?.(data);
    };

    return (
        <DlgContainer className="form">
            <div className="form-item">
                <div className={cx({ "form-field": true, error: !data.firstName })}>
                    <input name="firstName" type="text" id="firstName" placeholder="First Name" value={data.firstName || ""} onChange={handleOnChange} />
                    <label id="firstName" className="form-label">
                        First Name
                    </label>
                </div>
                {!data.firstName && <p className="msg">First name is required.</p>}
            </div>
            <div className="form-item">
                <div className="form-field">
                    <input name="middleName" type="text" id="middleName" placeholder="Middle Name" value={data.middleName || ""} onChange={handleOnChange} />
                    <label id="middleName" className="form-label">
                        Middle Name
                    </label>
                </div>
            </div>
            <div className="form-item">
                <div className={cx({ "form-field": true, error: !data.lastName })}>
                    <input name="lastName" type="text" id="lastName" placeholder="Last Name" value={data.lastName || ""} onChange={handleOnChange} />
                    <label id="lastName" className="form-label">
                        Last Name
                    </label>
                </div>
                {!data.lastName && <p className="msg">Last name is required.</p>}
            </div>
            <div className="form-item">
                <div className="form-field">
                    <input name="suffix" type="text" id="suffix" placeholder="Suffix" value={data.suffix || ""} onChange={handleOnChange} />
                    <label id="suffix" className="form-label">
                        Suffix
                    </label>
                </div>
            </div>
            <div className="footer">
                <button className="form-modal-button btn btn-primary" disabled={!isValidForm()} onClick={handleOnSubmit}>
                    Submit
                </button>
            </div>
        </DlgContainer>
    );
};

InfoDlgBody.Address = ({ data: dataInProps, resolve, reject }) => {
    const [data, setData] = useState(dataInProps || {});

    const isValidForm = () => {
        if (!data.address) return false;
        if (!data.zipcode) return false;
        if (!data.city) return false;
        return true;
    };

    const handleOnSubmit = () => {
        resolve?.(data);
    };

    return (
        <DlgContainer className="form">
            <div className="form-item">
                <div className={cx({ "form-field": true })}>
                    <input
                        name="address"
                        type="text"
                        id="address"
                        placeholder="Address"
                        value={data.address || ""}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^a-zA-Z0-9 \-\(\)&,]/g, "").slice(0, 100) })}
                    />
                    <label id="address" className="form-label">
                        Address
                    </label>
                </div>
            </div>
            <div className="form-item">
                <div className="form-field">
                    <input
                        name="zipcode"
                        type="text"
                        id="zipcode"
                        placeholder="Zip Code"
                        value={data.zipcode || ""}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^0-9]/g, "").slice(0, 4) })}
                    />
                    <label id="zipcode" className="form-label">
                        Zip Code
                    </label>
                </div>
            </div>
            <div className="form-item">
                <div className="form-field">
                    <input
                        name="city"
                        type="text"
                        id="city"
                        placeholder="City"
                        value={data.city || ""}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^a-zA-Z0-9 \-\(\)&]/g, "").slice(0, 50) })}
                    />
                    <label id="city" className="form-label">
                        City
                    </label>
                </div>
            </div>
            <div className="footer">
                <button className="form-modal-button btn btn-primary" disabled={!isValidForm()} onClick={handleOnSubmit}>
                    Submit
                </button>
            </div>
        </DlgContainer>
    );
};

const DlgContainer = styled.div`
    position: relative;
    width: 100%;
    margin: 15px 0 0;
    .footer {
        margin: 20px 0 0;
        button {
            width: 100%;
        }
    }
`;
