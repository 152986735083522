import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import qs from "query-string";

import Layout from "@layout/App";

import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import Game, { Results } from "./Game";
import useIam from "@hook/useIam";
import _CK from "@util/cookie";

const _TABS = [
    { label: "Play Games", value: "GAME" },
    { label: "Draw Results", value: "RESULT" },
];
export default () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { search } = location;
    const { iam } = useContext(AppContext);
    const [tab, setTab] = useState(_TABS[0].value);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const q = qs.parse(search);
        setTab(q.mode ? q.mode.toUpperCase() : _TABS[0].value);
    }, [search]);

    useIam({
        onSuccess: (data) => {
            setIsLoaded(true);
            if (!data) return;
            if (!data.FIRSTNAME) {
                navigate("/app/join/information", { replace: true });
            }
        },
    });

    useEffect(() => {
        if (isLoaded && !_CK.isLogin()) {
            navigate("/app/login", { replace: true });
        }
    }, [iam, isLoaded]);

    if (!_CK.isLogin()) {
        return <Loading dark />;
    }

    return (
        <Layout>
            <Container className="nwrap t-tab">
                <div className="tab">
                    {_TABS.map((item, i) => (
                        <button key={i} type="button" className={cx({ active: tab === item.value })} onClick={(e) => setTab(item.value)}>
                            {item.label}
                        </button>
                    ))}
                </div>
                {tab === "GAME" && <Game />}
                {tab === "RESULT" && <Results />}
            </Container>
        </Layout>
    );
};

export { default as AppAboutPage } from "./about";
export { default as AppNoticePage, View as AppNoticeViewPage } from "./notice";
export { default as AppFaqPage } from "./faq";
export { default as AppLoginPage } from "./login";
export { default as AppJoinPage, Information as AppJoinInformationPage } from "./join";
export { default as AppForgotPage } from "./forgot";
export { default as AppMyPage, Charge as AppMyChargePage, Withdraw as AppMyWithdrawPage, Wallet as AppMyWalletPage } from "./my";
export { default as AppTermsPage } from "./terms";

/**
 * Styled-Components
 */
const Container = styled.div``;
