import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import Countdown from "react-countdown";
import sprintf from "sprintf";

import _API from "@util/api";
import _U from "@util/utilities";
import _DX from "@util/dx";

export default () => {
    const [today, setToday] = useState([]);
    const [results, setResults] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState(null);
    const [currentDraw, setCurrerntDraw] = useState(null);

    const loadData = () => {
        _DX.post({
            path: "/api/searchDrawResultsRaffleList.do",
        })
            .then((res) => res.data)
            .then((data) => setToday(data))
            .catch((e) => _DX.alert(e));

        _DX.post({
            path: "/api/searchDrawRoundStatusComboList.do",
        })
            .then((res) => res.data)
            .then((data) => {
                setResults(data);
                if (data?.length > 0) {
                    const draw = data[0];
                    setSelectedDraw(draw.ROUND);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    const loadRoundDetail = () => {
        if (!selectedDraw) {
            setCurrerntDraw(null);
            return;
        }

        _DX.post({
            path: "/api/searchDrawResultsInfoDetail.do",
            data: { round: selectedDraw },
        })
            .then((res) => res.data)
            .then((data) => setCurrerntDraw(data))
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (selectedDraw) {
            loadRoundDetail();
        }
    }, [selectedDraw]);

    return (
        <Container className="content">
            <h4 className="title">Today's Winning Numbers</h4>
            <div className="winner_banner_bg banner">
                <h5 className="today txt-decorate">
                    <div className="bg">{moment().format("ll")}</div>
                    <div className="fg">{moment().format("ll")}</div>
                </h5>
                <div className="winner_banner_number">
                    {today?.map((item, i) => (
                        <div key={i} className="draw-combi">
                            <div className="txt-decorate">
                                <span className="bg draw-round">{item.TIMESDAY} Draw</span>
                                <span className="fg draw-round">{item.TIMESDAY} Draw</span>
                            </div>
                            {item.ISRAFFLE === "Y" ? (
                                <Fragment>
                                    <img src={`/assets/fruits/${item.RAFFLEFRUIT1.toLowerCase()}-sm.png`} />
                                    <img src={`/assets/fruits/${item.RAFFLEFRUIT2.toLowerCase()}-sm.png`} />
                                    <img src={`/assets/fruits/${item.RAFFLEFRUIT3.toLowerCase()}-sm.png`} />
                                    <input type="text" className="check-input" value={item.RAFFLENUMBER1} readOnly />
                                    <input type="text" className="check-input" value={item.RAFFLENUMBER2} readOnly />
                                    <input type="text" className="check-input" value={item.RAFFLENUMBER3} readOnly />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <input type="text" className="check-input" value="-" readOnly />
                                    <input type="text" className="check-input" value="-" readOnly />
                                    <input type="text" className="check-input" value="-" readOnly />
                                    <input type="text" className="check-input" value="-" readOnly />
                                    <input type="text" className="check-input" value="-" readOnly />
                                    <input type="text" className="check-input" value="-" readOnly />
                                </Fragment>
                            )}
                        </div>
                    ))}
                </div>
                <Comming />
            </div>
            <h4 className="title">Draw Results</h4>
            <div className="draw_results">
                <div className="form-select">
                    <select name="date" value={selectedDraw || ""} onChange={(e) => setSelectedDraw(e.target.value)}>
                        {results?.map((item, i) => (
                            <option key={i} value={item.ROUND}>
                                {item.ROUND} Draw ({moment(item.PLAYSDATETIME, "YYYYMMDD HH:mm:ss").format("ll HH:mm")})
                            </option>
                        ))}
                    </select>
                </div>
                {currentDraw && (
                    <div className="draw-combi">
                        <img src={`/assets/fruits/${currentDraw.RAFFLEFRUIT1.toLowerCase()}-sm.png`} />
                        <img src={`/assets/fruits/${currentDraw.RAFFLEFRUIT2.toLowerCase()}-sm.png`} />
                        <img src={`/assets/fruits/${currentDraw.RAFFLEFRUIT3.toLowerCase()}-sm.png`} />
                        <input type="text" className="check-input" value={currentDraw.RAFFLENUMBER1} readOnly />
                        <input type="text" className="check-input" value={currentDraw.RAFFLENUMBER2} readOnly />
                        <input type="text" className="check-input" value={currentDraw.RAFFLENUMBER3} readOnly />
                    </div>
                )}
            </div>

            {currentDraw && (
                <Fragment>
                    <div className="draw_results_table">
                        <table>
                            <tbody>
                                <tr className="game mega">
                                    <td>MEGA</td>
                                    <td colSpan="2">
                                        {/* <p className="prize">Gross Prize Amount</p>
                                        <p className="point">₱ {_U.addComma(currentDraw.megaTotalPrize)}</p> */}
                                    </td>
                                </tr>
                                <tr className="case">
                                    <td>Tier</td>
                                    <td>Prize</td>
                                    <td>Winners</td>
                                </tr>
                                <tr>
                                    <td className="">Special</td>
                                    <td>₱ {_U.addComma(currentDraw.MEGASPPRIZE)}</td>
                                    <td>{_U.addComma(currentDraw.MEGASPWINNERS)}</td>
                                </tr>
                                <tr>
                                    <td className="">1st</td>
                                    <td>₱ {_U.addComma(currentDraw.MEGA1STPRIZE)}</td>
                                    <td>{_U.addComma(currentDraw.MEGA1STWINNERS)}</td>
                                </tr>
                                <tr>
                                    <td className="">2nd</td>
                                    <td>₱ {_U.addComma(currentDraw.MEGA2NDPRIZE)}</td>
                                    <td>{_U.addComma(currentDraw.MEGA2NDWINNERS)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="draw_results_table">
                        <table>
                            <tbody>
                                <tr className="game base">
                                    <td>BASE</td>
                                    <td colSpan="2">
                                        {/* <p className="prize">Gross Prize Amount</p>
                                        <p className="point">₱ {_U.addComma(currentDraw.baseTotalPrize)}</p> */}
                                    </td>
                                </tr>
                                <tr className="case">
                                    <td>Tier</td>
                                    <td>Prize</td>
                                    <td>Winners</td>
                                </tr>
                                <tr>
                                    <td className="">1st</td>
                                    <td>₱ {_U.addComma(currentDraw.BASE1STPRIZE)}</td>
                                    <td>{_U.addComma(currentDraw.BASE1STWINNERS)}</td>
                                </tr>
                                <tr>
                                    <td className="">2nd</td>
                                    <td>₱ {_U.addComma(currentDraw.BASE2NDPRIZE)}</td>
                                    <td>{_U.addComma(currentDraw.BASE2NDWINNERS)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            )}
        </Container>
    );
};

const Comming = () => {
    const [data, setData] = useState(null);
    const loadData = () => {
        _DX.post({ path: "/api/searchDrawRoundInfoBuyList.do" })
            .then((res) => res.data)
            .then((data) => {
                if (!data || data.length === 0) return;
                const date = data[0];
                if (!date.time || date.time.length === 0) return;
                const time = date.time[0];

                const countdown = {
                    raffleDate: `${date.date} ${time.times}:00`,
                    round: time.round,
                };
                setData(countdown);
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!data) return null;

    return (
        <div className="winner_open">
            <Countdown
                date={moment(data.raffleDate).toDate()}
                renderer={({ days, hours, minutes, seconds }) => (
                    <div className="countdown">
                        <b className="draw-round">{data.round} Draw </b>&nbsp;
                        {days > 0 && (
                            <Fragment>
                                <span>{days}</span>Day &nbsp;
                            </Fragment>
                        )}
                        {(hours > 0 || minutes > 0 || seconds > 0) && (
                            <Fragment>
                                <span>{sprintf("%02d", hours)}</span>:<span>{sprintf("%02d", minutes)}</span>:<span>{sprintf("%02d", seconds)}</span>
                            </Fragment>
                        )}
                    </div>
                )}
            />
        </div>
    );
};

const Container = styled.div``;
