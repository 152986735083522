import Cookies from "universal-cookie";
import url from "url";
import moment from "moment";

const cm = new Cookies();

const getCookie = (key) => {
    return cm.get(key);
};

const setCookie = (key, value, options) => {
    cm.set(key, value, { path: "/", sameSite: "lax", ...options });
};

const setCookies = (cookies, url) => {
    const keys = Object.keys(cookies);
    for (let i in keys) {
        const k = keys[i];
        const v = cookies[k];
        cm.set(k, v, { path: "/", sameSite: "lax" });
    }
};

const getCookies = (keys) => {
    const cookies = {};
    for (let i in keys) {
        const key = keys[i];
        cookies[key] = getCookie(key);
    }
    return cookies;
};

const getAll = () => {
    return cm.getAll();
};

const getAuth = () => {
    return cm.get("authorization");
};

const setAuth = (auth) => {
    cm.set("authorization", auth, { path: "/", sameSite: "lax" });
};

const clearAuth = () => {
    cm.set("authorization", "", { path: "/", sameSite: "lax" });
};

const isLogin = () => {
    if (!getAuth()) {
        return false;
    }
    return true;
};

const isDevice = () => {
    const d = getCookie("DEVICE");
    return d === "ios" || d === "android";
};

const isAndroid = () => {
    const d = getCookie("DEVICE");
    return d === "android";
};

const isIos = () => {
    const d = getCookie("DEVICE");
    return d === "ios";
};

const setToken = (token) => {
    setCookie("TOKEN", token);
};
const getToken = () => {
    return getCookie("TOKEN");
};

const setDevice = (device) => {
    setCookie("DEVICE", device);
};
const getDevice = () => {
    return getCookie("DEVICE") || "WEB";
};

const setIamDate = () => {
    setCookie("IAM-DATE", moment().format("YYYYMMDDHHmmss"));
};

const getIamDate = () => {
    return getCookie("IAM-DATE");
};

const setCFCookies = (data) => {
    const { cfSign, cfUrl } = data;
    const { hostname: host } = url.parse(cfUrl);
    const names = host.split(".");
    let hostname = `.${host}`;
    if (names.length > 2) {
        hostname = `.${names[names.length - 2]}.${names[names.length - 1]}`;
    }
    const keys = Object.keys(cfSign);
    for (let i in keys) {
        const k = keys[i];
        const v = cfSign[k];
        cm.set(k, v, { path: "/", sameSite: "lax", domain: hostname });
    }
};

export default {
    set: setCookie,
    get: getCookie,
    getAll,
    setCookies,
    getCookies,
    getAuth,
    setAuth,
    clearAuth,
    isLogin,
    isDevice,
    isAndroid,
    isIos,
    setToken,
    getToken,
    setDevice,
    getDevice,
    setIamDate,
    getIamDate,
    setCFCookies,
};
