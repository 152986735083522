import React, { useEffect, useState } from "react";
import Layout, { ASISContainer } from "@layout/App";

import _DX from "@util/dx";

export default () => {
    const [content, setContent] = useState("");

    const loadData = () => {
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "Terms",
                type_group: "About",
                currPage: 1,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { games } = data;
                if (games?.length > 0) {
                    setContent(games[0].TERMSCONTENTS);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Layout>
            <ASISContainer className="nwrap">
                <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            </ASISContainer>
        </Layout>
    );
};
