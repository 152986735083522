import { useEffect, useContext, useState } from "react";
import moment from "moment";

import { AppContext } from "@component/AppProvider";

import _CK from "@util/cookie";
import _DX from "@util/dx";

const useIam = (options) => {
    const { onSuccess, onError } = options || {};
    const { iam, saveIam } = useContext(AppContext);
    const [iamDate, setIamDate] = useState(null);

    const loadIam = () => {
        if (!_CK.isLogin()) return onSuccess && onSuccess(null);

        _DX.post({ path: "/api/searchPlayerInfo.do" })
            .then((res) => res.data)
            .then((data) => {
                if (!data) {
                    saveIam(null);
                    _CK.clearAuth();
                    throw { message: "You do not have the permission" };
                }
                saveIam(data);
                setIamDate(moment().format("YYYYMMDDHHmmss"));
                onSuccess && onSuccess(data);
            })
            .catch((e) => onError && onError(e));
    };

    useEffect(() => {
        loadIam();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!iamDate) {
                loadIam();
                return;
            }
            const ckIamDate = _CK.getIamDate();
            if (ckIamDate) {
                const ckm = moment(ckIamDate, "YYYYMMDDHHmmss");
                const imm = moment(iamDate, "YYYYMMDDHHmmss");
                if (imm.isBefore(ckm)) {
                    loadIam();
                }
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [iam]);
};

export default useIam;
