import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import Layout, { ASISContainer } from "@layout/App";

import _DX from "@util/dx";

export default () => {
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pages, setPages] = useState([]);

    const loadList = (page) => {
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "FAQ",
                currPage: page,
                keyword,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { currPage, games: list, totPage } = data;
                setCurrentPage(currPage);
                setTotalPages(totPage);
                setList(list);

                const pages = [];
                for (let i = 1; i <= totPage; i++) {
                    pages.push(i);
                }
                setPages(pages);
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadList(1);
    }, []);

    return (
        <Layout>
            <ASISContainer className="nwrap">
                <div className="content">
                    <h4 className="title">FAQ</h4>
                    <div className="search-input">
                        <input type="text" placeholder="Please enter a search term." value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <button type="button" className="search" onClick={() => loadList(1)}></button>
                    </div>
                    <div className="bl-cont faq-list">
                        {list?.map((item, i) => (
                            <FAQItem key={i} title={item.QTITLE} content={item.ACONTENTS} />
                        ))}
                    </div>
                </div>
                <ul className="pagination">
                    {/* <li className="prev btn-arr">
                            <a href={void 0}></a>
                        </li> */}

                    {pages.map((page, i) => (
                        <li key={i} className={cx({ active: currentPage === page })}>
                            <a href={void 0} onClick={() => loadList(page)}>
                                {page}
                            </a>
                        </li>
                    ))}
                    {/* <li className="next btn-arr">
                            <a href={void 0}></a>
                        </li> */}
                </ul>
            </ASISContainer>
        </Layout>
    );
};

const FAQItem = ({ title, content }) => {
    const [isShow, setIsShow] = useState(false);

    return (
        <Container isShow={isShow}>
            <dt className={`subject ${isShow ? "on" : ""}`} onClick={() => setIsShow(!isShow)}>
                <p>{title}</p>
            </dt>
            <dd className="answer" dangerouslySetInnerHTML={{ __html: content }} />
        </Container>
    );
};

/**
 * Styled-Components
 */
const Container = styled.dl`
    .answer {
        padding: ${({ isShow }) => (isShow ? "20px 10px 40px" : "0px 10px")};
        height: ${({ isShow }) => (isShow ? "auto" : "0px")};
        opacity: ${({ isShow }) => (isShow ? 1 : 0)};
        /* display: ${({ isShow }) => (isShow ? "block" : "none")}; */
        transition: all 0.5s;
        overflow: hidden;
    }
`;
