import React, { useState, useContext } from "react";
import styled from "styled-components";

export const FormItem = ({ name, type, label, value, onChange, button }) => {
    return (
        <div className="form-item">
            <div className="form-field">
                <input name={name} type={type || "text"} value={value[name] || ""} onChange={(e) => onChange && onChange({ ...value, [e.target.name]: e.target.value })} placeholder={label} />
                {button && <button>{button}</button>}
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const FormItemForAmount = ({ name, type, label, value, onChange, button }) => {
    const handleOnChange = (e) => {
        let { name, value: eValue } = e.target;
        try {
            if (!eValue) {
                eValue = "0";
            }
            eValue = `${parseInt(eValue)}`;
        } catch (e) {
            eValue = "0";
        }
        onChange?.({ ...value, [name]: eValue });
    };

    const handleOnKeydown = (e) => {
        if (e.key === ".") {
            e.preventDefault();
        }
    };

    return (
        <div className="form-item">
            <div className="form-field">
                <input name={name} type={type || "text"} value={value[name] || ""} onChange={handleOnChange} placeholder={label} onKeyDown={handleOnKeydown} />
                {button && <button>{button}</button>}
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const FormItemForWithdrawPassword = ({ name, type, label, value, onChange, button }) => {
    const handleOnChange = (e) => {
        let { name, value: eValue } = e.target;
        eValue = eValue.replace(/[^a-zA-Z0-9]/g, "").slice(0, 12);
        onChange?.({ ...value, [name]: eValue });
    };

    return (
        <div className="form-item">
            <div className="form-field">
                <input name={name} type={type || "text"} value={value[name] || ""} onChange={handleOnChange} placeholder={label} />
                {button && <button>{button}</button>}
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const FormItemForAccount = ({ name, type, label, value, onChange, button }) => {
    const handleOnChange = (e) => {
        let { name, value: eValue } = e.target;
        eValue = eValue.replace(/[^0-9]/g, "").slice(0, 20);
        onChange?.({ ...value, [name]: eValue });
    };

    return (
        <div className="form-item">
            <div className="form-field">
                <input name={name} type={type || "text"} value={value[name] || ""} onChange={handleOnChange} placeholder={label} />
                {button && <button>{button}</button>}
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const FormSelectItem = ({ name, items, label, value, onChange }) => {
    const handleOnChange = (e) => {
        onChange && onChange({ ...value, [e.target.name]: e.target.value });
    };
    return (
        <div className="form-item">
            <div className="form-field">
                <select name={name} value={value[name]} onChange={handleOnChange}>
                    {items?.map((item, i) => (
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
                <label className="form-label">{label}</label>
            </div>
        </div>
    );
};

export const FormRadioItem = ({ name, items, label, value, onChange }) => {
    const handleOnChange = (e) => {
        onChange && onChange({ ...value, [e.target.name]: e.target.value });
    };

    return (
        <div className="form-item">
            <label className="form-label">{label}</label>
            <div className="form-radio-field">
                {items?.map((item, i) => (
                    <label key={i}>
                        <input type="radio" name={name} value={item.value} checked={value[name] === item.value} onChange={handleOnChange} />
                        {item.label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default styled.div``;
