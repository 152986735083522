import React, { useState, useContext, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import Layout from "@layout/App";
import Link, { ToLink, ToBack } from "@component/UI/Link";
import { BackHeader as Header } from "@layout/App/Header";
import { AppContext } from "@component/AppProvider";
import Form, { FormItem } from "@component/UI/Form";
import useBalance from "@hook/useBalance";

import _U from "@util/utilities";
import _CK from "@util/cookie";
import _DX from "@util/dx";

export default () => {
    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap">
                <History />
            </Container>
        </Layout>
    );
};

const _DATE_TYPES = [
    { label: "Today", value: "TODAY" },
    { label: "1 week", value: "1WEEK" },
    { label: "1 month", value: "1MONTH" },
    { label: "3 months", value: "3MONTH" },
];
const History = () => {
    const navigate = useNavigate();
    const { iam } = useContext(AppContext);

    const [balance, setBalance] = useState(0);
    const [data, setData] = useState({});
    const [dateType, setDateType] = useState(_DATE_TYPES[0].value);
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handleOnDateTypeChange = (type) => {
        setDateType(type);
        switch (type) {
            case "TODAY":
                setData({ ...data, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1WEEK":
                setData({ ...data, startDate: moment().subtract(7, "d").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1MONTH":
                setData({ ...data, startDate: moment().subtract(1, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "3MONTH":
                setData({ ...data, startDate: moment().subtract(3, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
        }
    };

    const loadList = (page) => {
        if (!_CK.isLogin()) return;
        if (!data.startDate || !data.endDate) return;

        setCurrentPage(page);
        setTotalPages(0);

        const params = {
            sDate: moment(data.startDate, "YYYY-MM-DD").format("YYYYMMDD"),
            fDate: moment(data.endDate, "YYYY-MM-DD").format("YYYYMMDD"),
            currPage: page,
        };

        _DX.post({ path: "/api/searchPlayerWallet.do", data: params })
            .then((res) => res.data)
            .then((data) => {
                const { currPage, games: receivedList, totPage } = data;
                setCurrentPage(currPage);
                setTotalPages(totPage);
                if (currPage > 1) {
                    setList([...list, ...receivedList]);
                } else {
                    setList(receivedList);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    useBalance((balance) => setBalance(balance));

    useEffect(() => {
        setData({
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
        });
    }, []);

    useEffect(() => {
        loadList(1);
    }, [data]);

    return (
        <Fragment>
            <div className="content wallet">
                <h4 className="title">My Wallet</h4>
                <div className="account">
                    <h5 className="name">{`${iam?.FIRSTNAME}${iam?.LASTNAME ? `, ${iam.LASTNAME}` : ""}`}</h5>
                    <div className="point">
                        {_U.addComma(balance)}
                        <span className="peso">₱</span>
                    </div>
                    <div className="actions">
                        <Link href="/app/my/charge" className={"btn-arr_sm"}>
                            <button type="button">
                                Charge
                                <img src="/assets/images/arr_white_sm.png" />
                            </button>
                        </Link>
                        <Link href="/app/my/charge" className={"btn-arr_sm"}>
                            <button type="button">
                                Withdraw
                                <img src="/assets/images/arr_white_sm.png" />
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="dateSortWrap mt-20">
                    <Form className="form-box">
                        <div className="form-item">
                            <div className="form-field-dual">
                                <input type="date" name="startDate" value={data.startDate || ""} onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })} />
                                <span>~</span>
                                <input type="date" name="endDate" value={data.endDate || ""} onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })} />
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="buttons">
                                {_DATE_TYPES.map((item, i) => (
                                    <button type="button" key={i} onClick={handleOnDateTypeChange.bind(this, item.value)} className={cx({ active: dateType === item.value })}>
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="history-list">
                    {list.map((item, i) => (
                        <div key={i} className="item">
                            <p className="h-date">{moment(item.DWDATETIME).format("lll")}</p>
                            <div className="description">
                                <p>{item.DWDTLCLASSNAME}</p>
                                <span
                                    className={cx({
                                        amount: true,
                                        minus: item.DWCLASSCODE === "WIT",
                                        plus: item.DWCLASSCODE === "DEP",
                                    })}
                                >
                                    {item.DWCLASSCODE === "DEP" ? "+" : "-"} ₱ {_U.addComma(item.DWAMOUNT)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                {currentPage < totalPages && (
                    <div className="btn-group mt-30">
                        <button className="btn btn-secondary" onClick={() => loadList(currentPage + 1)}>
                            More
                        </button>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div``;
