import React from "react";
import styled from "styled-components";

export default () => {
    return (
        <Container>
            <div className="container">
                <h1 className="title">Prutas Panalo</h1>
                <p className="description">A distinctive game amalgamating the best features of lotto and digit games.</p>
                <div className="download-buttons">
                    <a href="/assets/apk/prutaspanalo.apk">
                        <img src="/assets/images2/google_down.png" alt="Download on Google Play" />
                    </a>
                    <a href="/app">
                        <img src="/assets/images2/app_down.png" alt="Download on App Store" />
                    </a>
                </div>
                <p className="game-description">
                    Built on familiar game mechanics of traditional three-digit numbers games, "Prutas Panalo" offers 1st and 2nd tier prizes, providing better odds of winning in addition to the
                    pari-mutuel Jackpot. Players opting for "Prutas Panalo BASE" and "Prutas Panalo MEGA" variants, along with picking three (3) fruits, are eligible for the Jackpot, potentially
                    carrying over from previous draws.
                </p>
                <img src="/assets/images2/game_title.png" alt="game image" className="game-image" />
                <footer className="copyright">© 2024 Prutas Panalo. All rights reserved.</footer>
            </div>
        </Container>
    );
};

const Container = styled.div`
    /* 기본 스타일  */
    .container {
        max-width: 1200px;
        margin: auto;
        padding: 20px;
    }

    .title,
    .description,
    .game-description {
        text-align: center;
        margin-bottom: 20px;
    }

    /* 다운로드 버튼 스타일 */
    .download-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
    }

    .download-buttons img {
        width: 150px; /* 이미지 크기 조정 */
        cursor: pointer;
    }

    .game-image {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 20px auto;
    }

    /* 반응형 디자인 */
    @media (max-width: 768px) {
        .download-buttons {
            flex-direction: column;
            align-items: center;
        }

        .download-buttons img {
            margin-bottom: 10px;
        }
    }

    /* 카피라잇 스타일 */
    footer {
        text-align: center;
        margin-top: 40px;
        color: #666;
    }
`;
