import React from "react";
import ReactDOM from "react-dom";
import nl2br from "react-nl2br";
import styled from "styled-components";

import { Theme, ThemeProvider } from "@component/GlobalStyles";

const _CONTAINER_ID = "DIALOG-CONTAINER";
let _CONTAINER = null;

export default (props) => {
    if (!_CONTAINER) {
        _CONTAINER = document.getElementById(_CONTAINER_ID);
    }
    if (!_CONTAINER) {
        _CONTAINER = document.createElement("div");
        _CONTAINER.id = _CONTAINER_ID;
        document.body.appendChild(_CONTAINER);
    }

    return new Promise((resolve, reject) => {
        try {
            ReactDOM.render(<Dialog {...props} resolve={resolve} reject={reject} />, _CONTAINER);
        } catch (e) {
            reject(e);
        }
    });
};

const Dialog = (props) => {
    const { resolve, reject } = props;
    const { closeOnClickOutside } = props;
    const { title, text, html, body, button, buttons, width, padding, backgroundColor, closeBtn } = props;

    const dismiss = () => {
        document.body.removeChild(_CONTAINER);
        _CONTAINER = null;
    };

    const handleClose = () => {
        dismiss();
        resolve && resolve();
    };

    const handleContainerClicked = () => {
        return;
        /*
        if (closeOnClickOutside === false) {
            return;
        }
        dismiss();
        resolve && resolve();
        */
    };

    const handleConfirmClick = () => {
        dismiss();
        resolve && resolve(true);
    };
    const handleButtonClick = (index) => {
        dismiss();
        if (index === button.length - 1) {
            resolve && resolve(true);
        } else {
            resolve && resolve(index);
        }
    };
    const handleButtonAllClick = (index) => {
        dismiss();
        resolve && resolve(index);
    };

    const handleOnResolve = (args) => {
        dismiss();
        resolve(args);
    };

    const handleOnReject = (args) => {
        dismiss();
        reject(args);
    };

    return (
        <ThemeProvider theme={Theme}>
            <Container onClick={handleContainerClicked}>
                <Wrapper onClick={(e) => e.stopPropagation()} width={width} padding={padding} backgroundColor={backgroundColor}>
                    {/* <Close onClick={handleClose}>
                        <img src="/icons/icn_close01.svg" alt="닫기" width="22" height="22" />
                    </Close> */}

                    {title && <Header>{title}</Header>}
                    <Body backgroundColor={backgroundColor}>
                        {text && <Text>{nl2br(text)}</Text>}
                        {html && <Text dangerouslySetInnerHTML={{ __html: html }} />}
                        {body && React.cloneElement(body, { resolve: handleOnResolve, reject: handleOnReject })}
                    </Body>
                    {(typeof button === "boolean" || typeof button === "string") && (
                        <Footer>
                            <div className="confirm">
                                <button type="button" onClick={handleConfirmClick}>
                                    {typeof button === "boolean" ? "Confirm" : button}
                                </button>
                            </div>
                        </Footer>
                    )}
                    {typeof button === "object" && (
                        <Footer>
                            <div className="choose">
                                {button.map((b, i) => (
                                    <button key={i} type="button" onClick={handleButtonClick.bind(this, i)}>
                                        {b}
                                    </button>
                                ))}
                            </div>
                        </Footer>
                    )}
                    {typeof buttons === "object" && (
                        <Footer>
                            <div className="choose-all">
                                {buttons.map((b, i) => (
                                    <button key={i} type="button" onClick={handleButtonAllClick.bind(this, i)}>
                                        {b}
                                    </button>
                                ))}
                            </div>
                        </Footer>
                    )}
                </Wrapper>
            </Container>
        </ThemeProvider>
    );
};

/**
 * Styled
 */

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9998;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    position: relative;
    margin: 15px;
    padding: ${({ padding }) => (padding ? padding : "20px 15px 20px")};
    background: ${({ backgroundColor }) => backgroundColor || `#ffffff`};
    width: 100%;
    border-radius: 5px;

    @media screen and (min-width: ${(props) => props.theme.responsive.width.mobile}px) {
        width: ${({ width }) => width || 400}px;
    }
`;

const Header = styled.h3`
    margin: 0;
    padding: 0 0 20px;
    font-weight: ${(props) => props.theme.font.bold};
    font-size: 18px;
    text-align: center;
    color: ${(props) => props.theme.color.title};
    border-bottom: ${(props) => props.theme.color.line} solid 1px;

    @media only screen and (max-width: ${(props) => props.theme.responsive.width.mobile}px) {
        /* text-align: center;
		font-family: ${(props) => props.theme.font.light};
		font-size: 26px;
		line-height: 37px;
		color: #777777;
		padding-bottom: 0;
		margin-bottom: 60px; */
        /* border-bottom: 0; */
    }
`;

const Body = styled.div`
    position: relative;
    padding: 0px;
    background: ${({ backgroundColor }) => backgroundColor || `#ffffff`};
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(180px - 50px - 35px - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox

    ::-webkit-scrollbar {
        display: none; // Chrome, Safari, Opera
    }
    */
`;

export const Footer = styled.div`
    margin: 15px 0 0;

    .confirm {
        margin: 0;
        text-align: right;
        button {
            min-width: 200px;
            height: 50px;
            border: ${(props) => props.theme.color.main} solid 1px;
            background: ${(props) => props.theme.color.main};
            color: #ffffff;
            font-weight: ${(props) => props.theme.font.medium};
            font-size: 18px;
            border-radius: 5px;

            @media screen and (max-width: 480px) {
                width: 100%;
            }
        }
    }
    .choose {
        margin: 0;
        display: flex;
        gap: 10px;
        button {
            flex: 1;
            height: 50px;
            border: 1px solid #eeeeee;
            background: #eeeeee;
            color: #333333;
            font-weight: ${(props) => props.theme.font.medium};
            font-size: 18px;
            border-radius: 5px;
        }
        button:first-child {
            /* border-top-left-radius: 5px;
            border-bottom-left-radius: 5px; */
        }
        button:last-child {
            border: ${(props) => props.theme.color.main} solid 1px;
            background: ${(props) => props.theme.color.main};
            /* border-top-right-radius: 5px;
            border-bottom-right-radius: 5px; */
            color: #ffffff;
        }
        button:disabled {
            border: #b7b7b7 solid 1px;
            background-color: #b7b7b7;
        }
    }
    .choose-all {
        margin: 0;
        display: flex;
        gap: 0.533rem;
        button {
            flex: 1;
            height: 50px;
            border: ${(props) => props.theme.color.main} solid 1px;
            background: #ffffff;
            color: ${(props) => props.theme.color.main};
            font-weight: ${(props) => props.theme.font.medium};
            font-size: 18px;
            border-radius: 5px;
        }
    }
`;

const Text = styled.div`
    padding: 15px 0;
    max-height: 500px;
    overflow: auto;
    font-size: 1rem;
    font-weight: 500;
    color: #2a2a2a;
`;
