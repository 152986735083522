import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _CK from "@util/cookie";
import _DX from "@util/dx";

export default () => {
    const [content, setContent] = useState("");

    const loadData = () => {
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "Terms",
                type_group: "Terms",
                currPage: 1,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { games } = data;
                if (games?.length > 0) {
                    setContent(games[0].TERMSCONTENTS);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap">
                <div className="content termsOfPlay" dangerouslySetInnerHTML={{ __html: content }} />
            </Container>
        </Layout>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div``;
