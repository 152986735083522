import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import useIam from "@hook/useIam";
import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import Link, { ToBack, Back } from "@component/UI/Link";
import Dialog from "@component/UI/Dialog";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _DX from "@util/dx";
import _CK from "@util/cookie";
import _U from "@util/utilities";

export default () => {
    return (
        <Layout noFooter header={<Header />}>
            <ResetPassword />
        </Layout>
    );
};

const ResetPassword = () => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(_DEF.accountType[0].value);
    const [data, setData] = useState({});
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isOTPSended, setIsOTPSended] = useState(false);
    const [isOTPVerified, setIsOTPVerified] = useState(false);
    const [isAgree, setIsAgree] = useState(false);

    const isValidForm = () => {
        if (!isOTPVerified) return false;
        if (!data.mobile) return false;
        // if (!data.otp) throw false;
        if (!data.pin) return false;
        if (!_U.isPin(data.pin)) return false;
        if (data.pin !== data.pinConfirm) return false;
        if (!isAgree) return false;
        return true;
    };

    const handleSend = () => {
        if (!data.mobile) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "SENDOTP",
                TelNum: data.mobile,
            },
        })
            .then((res) => {
                setIsOTPSended(true);
            })
            .catch((e) => _DX.alert(e));
    };

    const handleVerify = () => {
        if (!data.otp) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "CHKOTP",
                TelNum: data.mobile,
                OtpNum: data.otp,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setIsOTPVerified(true);
                Dialog({ title: "Notice", text: "OTP verification was successful.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    const handleChange = () => {
        const { mobile, otp, pin } = data;
        const params = {
            SvcID: "OTP",
            phoneNum: mobile,
            otpNum: otp,
            chgPw: pin,
        };

        _DX.post({ path: "/api/updatePhoneOtpChkPw.do", data: params })
            .then((res) => res.data)
            .then((data) => {
                if (data === "UPDATE") {
                    Dialog({ title: "Notice", text: "The PIN number update has been completed.", button: "Confirm" }).then(() => {
                        navigate("/app/login", { replace: true });
                    });
                    return;
                }
                throw data;
            })
            .catch((e) => Dialog({ title: "Error", text: e.message, button: "Confirm" }));
    };

    return (
        <div className="nwrap t-tab">
            <div className="content">
                <div className="form login">
                    <div className="form-item">
                        <div className="form-field">
                            <input
                                name="mobile"
                                type="text"
                                id="mobile"
                                value={data.mobile || ""}
                                placeholder="Phone No"
                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                disabled={isOTPSended}
                            />
                            <label id="mobile" className="form-label">
                                Phone No
                            </label>
                            <button type="button" className="btn-send" onClick={handleSend}>
                                send
                            </button>
                        </div>
                    </div>
                    {isOTPSended && (
                        <div className="form-item">
                            <div className="form-field">
                                <input
                                    name="otp"
                                    type="number"
                                    id="OTP"
                                    placeholder="OTP"
                                    value={data.otp || ""}
                                    onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                    disabled={isOTPVerified}
                                />
                                <label id="OTP" className="form-label">
                                    OTP
                                </label>
                                <button type="button" className="btn-send" onClick={handleVerify}>
                                    Verify
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="form-item">
                        <div className={cx({ "form-field": true, error: data.pin && !_U.isPin(data.pin) })}>
                            <input name="pin" type={isShowPassword ? "text" : "password"} id="pin" placeholder="PIN" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                            <label id="pin" className="form-label">
                                PIN
                            </label>
                            <div className="password-info">
                                <button type="button" className={cx({ "btn-show": true, hide: !isShowPassword })} onClick={() => setIsShowPassword(!isShowPassword)} style={{ display: "block" }} />
                            </div>
                        </div>
                        {data.pin && !_U.isPin(data.pin) && (
                            <p className="msg">
                                You may use PIN to login in the future <br />
                                Please enter 6 - 12 alphanumeric without special character
                            </p>
                        )}
                    </div>
                    <div className="form-item">
                        <div className={cx({ "form-field": true, error: data.pinConfirm && data.pin !== data.pinConfirm })}>
                            <input
                                name="pinConfirm"
                                type={isShowPassword ? "text" : "password"}
                                id="pin-confirm"
                                placeholder="Confirm PIN"
                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                            />
                            <label id="pin-confirm" className="form-label">
                                Confirm PIN
                            </label>
                            <div className="password-info">
                                <button type="button" className={cx({ "btn-show": true, hide: !isShowPassword })} onClick={() => setIsShowPassword(!isShowPassword)} style={{ display: "block" }} />
                            </div>
                        </div>
                        {data.pinConfirm && data.pin !== data.pinConfirm && (
                            <p className="msg">
                                The PIN and PIN confirmation do not match. <br />
                                Please check the PIN.
                            </p>
                        )}
                    </div>
                    <div className="form-item">
                        <div className="form-field form-checkbox">
                            <label id="terms" className="form-label">
                                <input name="terms" type="checkbox" id="terms" defaultChecked={isAgree} onClick={() => setIsAgree(!isAgree)} />
                                <span>
                                    By login or sign up, you are confirming that you are above 18 years old, and agreed to our
                                    <Link href="/app/terms" className="btn-text udline">
                                        <a>TERMS AND CONDITIONS</a>
                                    </Link>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn-group mt-30">
                    <button type="button" className="btn btn-primary" onClick={handleChange} disabled={!isValidForm()}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};
