import React, { useState, useContext, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";
import Countdown from "react-countdown";
import sprintf from "sprintf";
import CountUp from "react-countup";

import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import _U from "@util/utilities";
import Link from "@component/UI/Link";
import _DX from "@util/dx";

import useBalance from "@hook/useBalance";

import Game from "@component/UI/Game";

export default () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { iam } = useContext(AppContext);

    const [jackpot, setJackpot] = useState(0);
    const [balance, setBalance] = useState(0);
    const [games, setGames] = useState([]);
    const [round, setRound] = useState(null);

    const loadGames = () => {
        _DX.post({ path: "/api/searchDrawRoundInfoBuyList.do" })
            .then((res) => res.data)
            .then((data) => setGames(data?.filter((o) => !!o.date)))
            .catch((e) => _DX.alert(e));
    };

    const loadJackpod = () => {
        _DX.post({ path: "/api/searchExpectJackpot.do" })
            .then((res) => res.data)
            .then((data) => {
                const { jackpotAmt } = data;
                setJackpot(jackpotAmt);
            })
            .catch((e) => _DX.alert(e));
    };

    const handleOnGameSelected = (round, date, time) => {
        setRound(round);
    };

    useBalance((balance) => {
        setBalance(balance);
    });

    useEffect(() => {
        loadGames();
        loadJackpod();

        const timer = setInterval(loadJackpod, 5000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Container className="content">
            {jackpot > 0 && (
                <Fragment>
                    <h4 className="title">Lucky Jackpot</h4>
                    <div className="banner">
                        <h5 className="today txt-decorate">
                            <div className="bg">{moment().format("ll")}</div>
                            <div className="fg">{moment().format("ll")}</div>
                        </h5>
                        <div className="accumulated txt-decorate">
                            <div className="bg">
                                ₱ <CountUp end={Math.max(jackpot, 100000)} />
                            </div>
                            <div className="fg">
                                ₱ <CountUp end={Math.max(jackpot, 100000)} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}

            <h4 className="title">Play Games</h4>
            {!_CK.isLogin() ? (
                <div className="loginAccount">
                    <Link href={`/app/login?url=${encodeURIComponent(location.pathname)}`} className={"btn-arr_sm"}>
                        <button type="button">
                            Sign in
                            <img src="/assets/images/arr_white_sm.png" />
                        </button>
                    </Link>
                </div>
            ) : (
                <div className="account">
                    <h5 className="name">{`${iam?.FIRSTNAME}${iam?.LASTNAME ? `, ${iam.LASTNAME}` : ""}`}</h5>
                    <div className="point">
                        <span className="peso">₱</span>
                        {_U.addComma(balance)}
                    </div>
                    <div className="actions">
                        <Link href="/app/my/charge" className={"btn-arr_sm"}>
                            <button type="button">
                                Charge
                                <img src="/assets/images/arr_white_sm.png" />
                            </button>
                        </Link>
                        <Link href="/app/my/withdraw" className={"btn-arr_sm"}>
                            <button type="button">
                                Withdraw
                                <img src="/assets/images/arr_white_sm.png" />
                            </button>
                        </Link>
                    </div>
                </div>
            )}
            <GameSelector games={games} onChange={handleOnGameSelected} />
            {round && <Game className="gameWrap" round={round} jackpot={jackpot} onRefresh={loadJackpod} />}
        </Container>
    );
};

const GameSelector = ({ games, onChange }) => {
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [round, setRound] = useState(null);
    const [times, setTimes] = useState([]);

    const handleDateChanged = (e) => {
        setDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        const ft = times.filter((o) => parseInt(o.round) === parseInt(e.target.value));
        if (!ft || ft.length === 0) {
            setRound(times[0].round);
            return;
        }
        const game = ft[0];
        setRound(game.round);
        setTime(game.times);
        onChange && onChange(game.round, date, game.time);
    };

    useEffect(() => {
        if (games && games.length > 0) {
            const date = games[0].date;
            setDate(date);
        }
    }, [games]);

    useEffect(() => {
        if (date && games && games.length > 0) {
            const current = games?.filter((o) => o.date === date);
            if (current && current.length > 0) {
                const d = current[0];
                const game = d.time[0];
                setTime(game.times);
                setRound(game.round);
                setTimes(d.time);
                onChange && onChange(game.round, date, game.time);
            }
        }
    }, [date]);

    return (
        <div className="gameSelector">
            <div className="date form-select">
                <select value={date || ""} onChange={handleDateChanged}>
                    {games?.map((item, i) => (
                        <option key={i} value={item.date}>
                            {moment(item.date).format("ll")}
                        </option>
                    ))}
                </select>
                <select value={round || ""} onChange={handleTimeChange}>
                    {times.map((item, i) => (
                        <option key={i} value={item.round}>
                            {item.times}
                        </option>
                    ))}
                </select>
            </div>
            {date && time && (
                <Fragment>
                    <Countdown
                        date={moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").toDate()}
                        renderer={({ days, hours, minutes, seconds }) => (
                            <div className="countdown">
                                <b className="draw-round">{round} Draw</b>
                                {days > 0 && (
                                    <Fragment>
                                        <span>{days}</span>Day &nbsp;
                                    </Fragment>
                                )}
                                {(hours > 0 || minutes > 0 || seconds > 0) && (
                                    <Fragment>
                                        <span>{sprintf("%02d", hours)}</span>:<span>{sprintf("%02d", minutes)}</span>:<span>{sprintf("%02d", seconds)}</span>
                                    </Fragment>
                                )}
                            </div>
                        )}
                    />
                    <div className="draw-date">Date of Draw : {moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").format("ll HH:mm")}</div>
                </Fragment>
            )}
        </div>
    );
};

export { default as Results } from "./results";
export { default as Receipt } from "./receipt";

/**
 * Styled-Components
 */
const Container = styled.div``;
