import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AppContext } from "@component/AppProvider";
import _CFG from "@config/defaults";
import _CK from "@util/cookie";
import _U from "@util/utilities";

const Link = ({ children, href, target, className }) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (_CK.isDevice()) {
            window.location.href = `${_CFG.app.schema}link?url=${encodeURIComponent(href)}&target=${target?.toUpperCase() === "_BLANK" ? "OUT" : "IN"}`;
            return;
        }
        if (target?.toUpperCase() === "_BLANK" || target?.toUpperCase() === "WEB_OUT") {
            window.open(href);
            return;
        }
        navigate(href);
    };

    return React.cloneElement(children, { target, className, onClick: handleOnClick });
};

export default Link;

export const Home = ({ children, className }) => {
    const navigate = useNavigate();

    const handleOnClick = (e) => {
        e.preventDefault();
        if (_CK.isDevice()) {
            window.location.href = `${_CFG.app.schema}home`;
            return;
        }
        navigate("/app");
    };

    return React.cloneElement(children, { href: _CK.isDevice() ? void 0 : "/app", className, onClick: handleOnClick });
};

export const Back = ({ children, className }) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (_CK.isDevice()) {
            window.location.href = `${_CFG.app.schema}back`;
            return;
        }
        navigate(-1);
    };

    return React.cloneElement(children, { href: void 0, className, onClick: handleOnClick });
};

export const Logout = ({ children, className }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { saveIam } = useContext(AppContext);

    const handleOnClick = () => {
        saveIam(null);
        _CK.clearAuth();

        if (_CK.isDevice()) {
            window.location.href = `${_CFG.app.schema}logout`;
            return;
        }

        if (location.pathname === "/") {
            document.location.reload();
        } else {
            navigate("/app", { replace: true });
        }
    };

    return React.cloneElement(children, { className, onClick: handleOnClick });
};

export const ToLink = (navigate, href, target) => {
    if (_CK.isDevice()) {
        window.location.href = `${_CFG.app.schema}link?url=${encodeURIComponent(href)}&target=${target?.toUpperCase() === "_BLANK" || target === "OUT" ? "OUT" : "IN"}`;
        return;
    }
    if (target?.toUpperCase() === "_BLANK" || target === "OUT") {
        window.open(href);
        return;
    }
    navigate(href);
};

export const ToBack = (navigate) => {
    if (_CK.isDevice()) {
        window.location.href = `${_CFG.app.schema}back`;
        return;
    }
    navigate(-1);
};
