import React from "react";
import { useContext } from "react";
import styled from "styled-components";

import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import _CFG from "@config/defaults";
import Link, { Home, Back } from "@component/UI/Link";

const Header = (props) => {
    const { saveIam, iam } = useContext(AppContext);

    const handleLogout = (e) => {
        e.preventDefault();

        saveIam(null);
        _CK.clearAuth();

        document.location.href = "/";
    };

    return (
        <Container>
            <nav>
                <div className="menu">
                    <a href={void 0} id="btn-menu">
                        <img src="/assets/images/user_menu.png" alt="menu" title="menu" />
                    </a>
                </div>
                <div className="logo">
                    <Home title="PrutasPanalo.com">
                        <a>
                            <img src="/assets/images/logo_300.png" alt="logo" title="logo" />
                        </a>
                    </Home>
                </div>
                <div className="profile ">
                    <Link href={iam ? "/app/my" : "/app/login"}>
                        <a>
                            <img src={iam ? "/assets/images/user_profile.png" : "/assets/images/profile.png"} alt="profile" title="profile" />
                        </a>
                    </Link>
                </div>
            </nav>
        </Container>
    );
};

export default Header;

export const BackHeader = ({ hasProfile = true, hasBack = true }) => {
    const { iam } = useContext(AppContext);
    return (
        <Container>
            <nav>
                <div className="menu">
                    {hasBack && (
                        <Back>
                            <a>
                                <img src="/assets/images/btn_back.png" alt="menu" title="menu" />
                            </a>
                        </Back>
                    )}
                </div>
                <div className="logo">
                    <Home title="PrutasPanalo.com">
                        <a>
                            <img src="/assets/images/logo_300.png" alt="logo" title="logo" />
                        </a>
                    </Home>
                </div>
                <div className="profile">
                    {hasProfile && (
                        <Link href={iam ? "/app/my" : "/app/login"}>
                            <a>
                                <img src={iam ? "/assets/images/user_profile.png" : "/assets/images/profile.png"} alt="profile" title="profile" />
                            </a>
                        </Link>
                    )}
                </div>
            </nav>
        </Container>
    );
};

/**
 * Styled-Components
 */
const Container = styled.header`
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 58px;
    background: #ffffff;
    border-radius: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
    z-index: 1000;

    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);

    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    nav {
        display: flex;
        height: inherit;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        .menu {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            a {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                img {
                    width: inherit;
                }
            }
        }
        .logo {
            width: 100%;
            a {
                display: block;
                width: 44px;
                height: 44px;
                margin: 0 auto;
                img {
                    width: inherit;
                }
            }
        }
        .profile {
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                width: inherit;
                height: inherit;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: inherit;
                }
            }
        }
    }
`;
