import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";
import QRCode from "react-qr-code";

import { AppContext } from "@component/AppProvider";
import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import Form from "@component/UI/Form";
import Link, { ToBack, Back } from "@component/UI/Link";
import Loading from "@component/UI/Loading";
import Dialog from "@component/UI/Dialog";

import _U from "@util/utilities";
import _CK from "@util/cookie";
import _D from "@config/defines";
import _DX from "@util/dx";

const _DATE_TYPES = [
    { label: "Today", value: "TODAY" },
    { label: "1 week", value: "1WEEK" },
    { label: "1 month", value: "1MONTH" },
    { label: "3 month", value: "3MONTH" },
];
export default () => {
    const [data, setData] = useState({});
    const [dateType, setDateType] = useState(_DATE_TYPES[0].value);

    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const loadList = (page) => {
        if (!_CK.isLogin()) return;
        if (!data.startDate || !data.endDate) return;

        setCurrentPage(page);
        setTotalPages(0);

        const params = {
            ...data,
            sDate: moment(data.startDate, "YYYY-MM-DD").format("YYYYMMDD"),
            fDate: moment(data.endDate, "YYYY-MM-DD").format("YYYYMMDD"),
            currPage: page,
        };

        _DX.post({ path: "/api/searchMyPlayList.do", data: params })
            .then((res) => res.data)
            .then((data) => {
                const { currPage, games: receivedList, totPage } = data;
                setCurrentPage(currPage);
                setTotalPages(totPage);
                if (currPage > 1) {
                    setList([...list, ...receivedList]);
                } else {
                    setList(receivedList);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    const handleOnDateTypeChange = (type) => {
        setDateType(type);
        switch (type) {
            case "TODAY":
                setData({ ...data, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1WEEK":
                setData({ ...data, startDate: moment().subtract(7, "d").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1MONTH":
                setData({ ...data, startDate: moment().subtract(1, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "3MONTH":
                setData({ ...data, startDate: moment().subtract(3, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
        }
    };

    const getWinningType = (item) => {
        if (item.isWinnings === "Y") return 2;
        if (item.isRaffle === "Y" && item.isWinnings !== "Y") return 1;
        return 0;
    };

    useEffect(() => {
        setData({
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
        });
    }, []);

    useEffect(() => {
        loadList(1);
    }, [data]);

    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap">
                <div className="content">
                    <h4 className="title">My Plays</h4>
                    <div className="dateSortWrap">
                        <Form className="form-box">
                            <div className="form-item">
                                <div className="form-field-dual">
                                    <input
                                        type="date"
                                        name="startDate"
                                        value={data.startDate || ""}
                                        min={moment().subtract(3, "month").format("YYYY-MM-DD")}
                                        max={moment().format("YYYY-MM-DD")}
                                        onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })}
                                    />
                                    <span>~</span>
                                    <input
                                        type="date"
                                        name="endDate"
                                        value={data.endDate || ""}
                                        min={moment().subtract(3, "month").format("YYYY-MM-DD")}
                                        max={moment().format("YYYY-MM-DD")}
                                        onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })}
                                    />
                                </div>
                            </div>
                            {/* <div className="form-item">
                                <div className="buttons">
                                    {_DATE_TYPES.map((item, i) => (
                                        <button type="button" key={i} onClick={handleOnDateTypeChange.bind(this, item.value)} className={cx({ active: dateType === item.value })}>
                                            {item.label}
                                        </button>
                                    ))}
                                </div>
                            </div> */}
                        </Form>
                    </div>
                    <div className="my_game_sort_box">
                        <div className="form-item">
                            <div className="form-field form-radio">
                                <label>
                                    <input type="radio" name="sort" onClick={() => setData({ ...data, wins: "", unclaimed: "", pending: "" })} defaultChecked={!data?.wins && !data?.unclaimed} />
                                    <span>ALL</span>
                                </label>
                            </div>
                            <div className="form-field form-radio">
                                <label>
                                    <input type="radio" name="sort" onClick={() => setData({ ...data, wins: "T", unclaimed: "", pending: "" })} defaultChecked={data?.wins === "T"} />
                                    <span>Winnings</span>
                                </label>
                            </div>
                            <div className="form-field form-radio">
                                <label>
                                    <input type="radio" name="sort" onClick={() => setData({ ...data, wins: "", unclaimed: "T", pending: "" })} defaultChecked={data?.unclaimed === "T"} />
                                    <span>Unclaimed</span>
                                </label>
                            </div>
                            <div className="form-field form-radio">
                                <label>
                                    <input type="radio" name="sort" onClick={() => setData({ ...data, wins: "", unclaimed: "", pending: "T" })} defaultChecked={data?.pending === "T"} />
                                    <span>Pending</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="bl-cont">
                        <ul id="mypage_my_play">
                            {list?.map((item, i) => (
                                <Link
                                    key={i}
                                    href={`/app/my/games/${item.PLAYS_ID}`}
                                    className={cx({ mypage_my_plays_li: true, win: item.COUNTWINNINGS > 0, mega: item.SVCNAME === "MEGA", base: item.SVCNAME === "BASE" })}
                                >
                                    <li>
                                        <div className="top">
                                            <div className="mypage_my_plays_date">{moment(item.REGDATE).format("ll HH:mm")}</div>
                                            <div className="mypage_my_plays_game">{_U.addComma(item.DRAWCOUNT)} Draw</div>
                                            <div className="mypage_my_plays_type">
                                                <span className={item.SVCNAME.toLowerCase()}>{item.SVCNAME}</span>
                                            </div>
                                            <div className="mypage_my_plays_result">
                                                {item.ISRAFFLE !== "Y" ? (
                                                    <p className="badge pending">Pending</p>
                                                ) : (
                                                    <p
                                                        className={cx({
                                                            badge: true,
                                                            win: item.COUNTWINNINGS > 0,
                                                            no_prize: item.ISRAFFLE === "Y" && item.COUNTWINNINGS === 0,
                                                        })}
                                                    >
                                                        {item.COUNTWINNINGS > 0 ? "Win" : "No Prize"}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="mypage_my_plays_bets">
                                                No. of Bets : {item.DRAWCOUNT} / ₱ {item.TOTALAMOUNT}
                                            </div>
                                            <div className="mypage_my_plays_ticket">Ticket No. #### #### #### #### #### #### #### ####</div>
                                        </div>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    {currentPage < totalPages && (
                        <div className="btn-group mt-30">
                            <button className="btn btn-secondary" onClick={() => loadList(currentPage + 1)}>
                                More
                            </button>
                        </div>
                    )}
                </div>
            </Container>
        </Layout>
    );
};

const Container = styled.div``;

export const Ticket = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { amounts } = useContext(AppContext);

    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [isPaid, setIsPaid] = useState(false);

    const loadData = () => {
        if (!params || !params.groupId) return;

        _DX.post({ path: "/api/searchMyPlayDetailList.do", data: [{ playsId: params.groupId }] })
            .then((res) => res.data)
            .then((data) => {
                setData(data.games);
                setIsLoaded(true);
            })
            .catch((e) => _DX.alert(e));
    };

    const getWinForm = (winType, fruit1) => {
        if (!fruit1) {
            return (
                <div className="games_bar_result_box">
                    <div className="badge pending">Pending</div>
                </div>
            );
        }
        switch (winType) {
            case "J":
            case "Y":
                return (
                    <div className="games_bar_result_box">
                        <div className="badge red">SP</div>
                    </div>
                );
            case "F":
                return (
                    <div className="games_bar_result_box">
                        <div className="badge red">1st</div>
                    </div>
                );
            case "S":
                return (
                    <div className="games_bar_result_box">
                        <div className="badge red">2nd</div>
                    </div>
                );
            default:
                return (
                    <div className="games_bar_result_box">
                        <div className="badge no_prize">No Prize</div>
                    </div>
                );
        }
    };

    const handleClaimPrize = (ticket) => {
        Dialog({ title: "Notice", text: "KYC verified.", button: "OK" }).then(() => {
            _DX.post({
                path: "/api/insertPlayerGameClaim.do",
                data: {
                    drawRound: ticket.ROUND,
                    playsId: ticket.PLAYS_ID,
                    playsOrd: ticket.PLAYS_ORD,
                },
            })
                .then((res) => {
                    Dialog({ title: "Notice", text: `Deposit ${_U.addComma(ticket.WINNINGS)} pesos`, button: "OK" }).then(() => {
                        loadData();
                    });
                })
                .catch((e) => _DX.alert(e));
        });
    };

    const handleOnAddFavorite = (game) => {
        if (!game.FRUIT1 || !game.FRUIT2 || !game.FRUIT3) return;
        if (game.TYPE === "MEGA") {
            if (!game.NUMBER1 || !game.NUMBER2 || !game.NUMBER3) return;
        }

        Dialog({ text: "Do you want to add it to your favorites?", button: ["Cancel", "Confirm"] }).then((isConfirm) => {
            if (isConfirm) {
                addFavorite({
                    type: game.TYPE,
                    fruit1: game.FRUIT1,
                    fruit2: game.FRUIT2,
                    fruit3: game.FRUIT3,
                    number1: game.NUMBER1,
                    number2: game.NUMBER2,
                    number3: game.NUMBER3,
                });
            }
        });
    };

    const addFavorite = (data) => {
        _DX.post({
            path: "/api/insertFavoritesList.do",
            data: {
                games: { ...data, type: data.type === "MEGA" ? "M" : "A" },
            },
        })
            .then((res) => {
                Dialog({ text: "Added to favorites.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, [params]);

    useEffect(() => {
        if (data?.length > 0) {
            const winningCount = data.filter((o) => ["J", "F", "S", "Y"].includes(o.ISWINNINGS)).length;
            const paidCount = data.filter((o) => o.ISCLAIM === "Y").length;
            if (winningCount > 0 && winningCount === paidCount) {
                setIsPaid(true);
            }
        }
    }, [data]);

    return (
        <Layout noFooter header={<Header />}>
            <TicketContainer className="nwrap">
                <div className="content">
                    {isLoaded && (
                        <Fragment>
                            <Recepit data={data} isPaid={isPaid} />
                            <div className="selected_combibox">
                                <div className="tit">Selected Combinations</div>

                                <div className="play_games_bar">
                                    {data.map((item, i) => (
                                        <div key={i} className="games_bar">
                                            <div id="games_bar_a" className="draw-combi">
                                                <span>{String.fromCharCode(65 + i)}</span>
                                                <img
                                                    id="a_img_4"
                                                    src={`/assets/fruits/${item.FRUIT1.toLowerCase()}-sm.png`}
                                                    className={cx({ active: item.FRUIT1 === item.RAFFLEFRUIT1, fail: item.FRUIT1 !== item.RAFFLEFRUIT1 })}
                                                />
                                                <img
                                                    id="a_img_5"
                                                    src={`/assets/fruits/${item.FRUIT2.toLowerCase()}-sm.png`}
                                                    className={cx({ active: item.FRUIT2 === item.RAFFLEFRUIT2, fail: item.FRUIT2 !== item.RAFFLEFRUIT2 })}
                                                />
                                                <img
                                                    id="a_img_6"
                                                    src={`/assets/fruits/${item.FRUIT3.toLowerCase()}-sm.png`}
                                                    className={cx({ active: item.FRUIT3 === item.RAFFLEFRUIT3, fail: item.FRUIT3 !== item.RAFFLEFRUIT3 })}
                                                />
                                                {item.TYPE === "MEGA" && (
                                                    <Fragment>
                                                        <input
                                                            type="text"
                                                            id="a_1"
                                                            className={cx({ "check-input": true, active: item.NUMBER1 === item.RAFFLENUMBER1, fail: item.NUMBER1 !== item.RAFFLENUMBER1 })}
                                                            name="a_1"
                                                            value={item.NUMBER1}
                                                            readOnly
                                                        />
                                                        <input
                                                            type="text"
                                                            id="a_2"
                                                            className={cx({ "check-input": true, active: item.NUMBER2 === item.RAFFLENUMBER2, fail: item.NUMBER2 !== item.RAFFLENUMBER2 })}
                                                            name="a_2"
                                                            value={item.NUMBER2}
                                                            readOnly
                                                        />
                                                        <input
                                                            type="text"
                                                            id="a_3"
                                                            className={cx({ "check-input": true, active: item.NUMBER3 === item.RAFFLENUMBER3, fail: item.NUMBER3 !== item.RAFFLENUMBER3 })}
                                                            name="a_3"
                                                            value={item.NUMBER3}
                                                            readOnly
                                                        />
                                                    </Fragment>
                                                )}

                                                {!item.RAFFLEFRUIT1 ? (
                                                    <div className="games_bar_result_box">
                                                        <div className="badge pending">Pending</div>
                                                    </div>
                                                ) : (
                                                    getWinForm(item.ISWINNINGS, item.FRUIT1)
                                                )}
                                                <button type="button" className="btn-fav" aria-label="favorite modal open" onClick={handleOnAddFavorite.bind(this, item)} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {data
                                ?.filter((o) => o.ISWINNINGS === "J" && o.WINNINGS > amounts?.pcso)
                                .map((item, i) => (
                                    <div className="draw-info-box" key={i}>
                                        <h4>Winnings : Special Prize</h4>
                                        <p className="prize_detail">Valid until : {moment(item?.RAFFLEDATE).add(1, "y").format("ll")}</p>
                                        <p className="prize_detail">Amount : ₱ {_U.addComma(item?.WINNINGS)}</p>
                                        <ul className="info-list dot">
                                            <li>
                                                You are advised to visit a nearby PCSO <br />
                                                branch to claim the Special Prize.
                                            </li>
                                            <li>
                                                Before your visit, please check to see if <br />
                                                your ID isproperly registered at <br />
                                                Prutas Panalo Service.
                                            </li>
                                        </ul>
                                    </div>
                                ))}

                            {data
                                ?.filter((o) => o.ISWINNINGS === "J" && o.WINNINGS <= amounts?.pcso)
                                .map((item, i) => (
                                    <div className="draw-info-box" key={i}>
                                        <h4>Winnings : Special Prize</h4>
                                        <p className="prize_detail">Valid until : {moment(item?.RAFFLEDATE).add(1, "y").format("ll")}</p>
                                        <p className="prize_detail">Amount : ₱ {_U.addComma(item?.WINNINGS)}</p>
                                        <p className="info dot">
                                            The special prize will be paid as your <br />
                                            Prepaid Balance when claimed.
                                        </p>
                                        <div className="btn-group mt-20">
                                            <button className={cx("btn", "btn-primary", { active: !isPaid })} onClick={handleClaimPrize.bind(this, item)} disabled={item.ISCLAIM === "Y"}>
                                                Claim Prize
                                            </button>
                                        </div>
                                    </div>
                                ))}

                            {data
                                ?.filter((o) => ["F", "S"].includes(o.ISWINNINGS))
                                .map((item, i) => (
                                    <div className="draw-info-box" key={i}>
                                        <h4>Winnings : {item.ISWINNINGS === "F" ? "1st" : "2nd"} Prize</h4>
                                        <p className="prize_detail">Valid until : {moment(item?.RAFFLEDATE).add(1, "y").format("ll")}</p>
                                        <p className="prize_detail">
                                            Amount : <span className="prize_amount">₱ {_U.addComma(item.WINNINGS)}</span>
                                        </p>
                                        <p className="info dot">
                                            The 1st and 2nd prizes will be paid as your <br />
                                            Prepaid Balance when claimed.
                                        </p>
                                        <div className="btn-group mt-20">
                                            <button className={cx("btn", "btn-primary", { active: !isPaid })} onClick={handleClaimPrize.bind(this, item)} disabled={item.ISCLAIM === "Y"}>
                                                Claim Prize
                                            </button>
                                        </div>
                                    </div>
                                ))}

                            <div className="btn-group mt-30">
                                <Back className="btn btn-secondary">
                                    <button>Close</button>
                                </Back>
                            </div>
                        </Fragment>
                    )}
                </div>
            </TicketContainer>
            {(!isLoaded || !amounts) && <Loading dark />}
        </Layout>
    );
};

const TicketContainer = styled.div``;

const Recepit = ({ data, isPaid }) => {
    const def = data[0];

    return (
        <div className="ticket">
            {/* <div className="ticket-subTxt">
                {_U.addComma(def.round)} (Draw Date : {moment(def.raffleDate).format("ll HH:mm")})
            </div> */}
            <div className="draw-info-box">
                <div className="top">
                    <h4 className="ticket-tit">
                        Prutas Panalo {def.TYPE} {def.ROUND}
                    </h4>
                    <div className="qrcode">
                        <QRCode size={44} style={{ height: "44px", width: "44px" }} value={def?.QRCODE || "https://prutaspanalo.com"} viewBox={`0 0 256 256`} />
                    </div>
                </div>
                <div className={`game-list ${data.some((item) => item.TYPE === "MEGA") ? "mega" : ""}`}>
                    {data.map((item, i) => (
                        <div key={i} className="item">
                            <span className="no">{String.fromCharCode(65 + i)}</span> :&nbsp;
                            <img src={`/assets/fruits/${item.FRUIT1.toLowerCase()}-sm.png`} alt={item.FRUIT1} className="fruit" />
                            <img src={`/assets/fruits/${item.FRUIT2.toLowerCase()}-sm.png`} alt={item.FRUIT2} className="fruit" />
                            <img src={`/assets/fruits/${item.FRUIT3.toLowerCase()}-sm.png`} alt={item.FRUIT3} className="fruit" />
                            {item.TYPE === "MEGA" && (
                                <Fragment>
                                    <span className="number">{item.NUMBER1}</span>
                                    <span className="number">{item.NUMBER2}</span>
                                    <span className="number">{item.NUMBER3}</span>
                                </Fragment>
                            )}
                            <span className="lp">LP</span>
                        </div>
                    ))}
                </div>
                <div className="info">
                    <div className="lside">{moment(def.REGDATE).format("ll HH:mm")}</div>
                    <div className="rside">Price : ₱ {(def.TYPE === "MEGA" ? 20 : 10) * data.length}</div>
                </div>
                {/* <div className="info">0000 0012 4325 1234 5678 5093 4568 7950</div> */}
                <div className="info">
                    <div className="lside">G8UCD51DE52VHEVS</div>
                    {/* <div className="rside">TID: 1112-8989-12</div> */}
                </div>
                <div className="info">
                    <div className="lside">
                        {def.ROUND} Draw {moment(def.RAFFLEDATE).format("ll HH:mm")}
                    </div>
                    <div className="rside">Payment due date {moment(def?.REGDATE).add(1, "y").format("ll")}</div>
                </div>
                {isPaid && (
                    <div className="paid">
                        <img src="/assets/images/paid_icon.png" />
                    </div>
                )}
            </div>
        </div>
    );
};
