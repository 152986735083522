import axios from "axios";
import { isIOS, isAndroid } from "react-device-detect";
import _CK from "@util/cookie";

import Dialog from "@component/UI/Dialog";

const api = {
    baseUrl: "https://pgs.tobesoftcns.com",
    port: 9443,
};
api.getUrl = (path) => {
    return `${api.baseUrl}:${api.port}${path}`;
};
api.getDevice = () => {
    if (isIOS) return "IOS";
    if (isAndroid) return "ANDROID";
    return "PC";
};

api.get = (request) => {
    request.method = "GET";
    return api.send(request);
};
api.post = (request) => {
    request.method = "POST";
    return api.send(request);
};
api.put = (request) => {
    request.method = "PUT";
    return api.send(request);
};
api.delete = (request) => {
    request.method = "DELETE";
    return api.send(request);
};
api.send = (request) => {
    const { path, data } = request;
    let { method } = request;
    if (!method) method = "GET";

    const url = api.getUrl(path);
    return new Promise((resolve, reject) => {
        let auth = null;
        if (_CK.isLogin()) {
            auth = _CK.getAuth();
        }

        const req = {
            method,
            url,
            data: method.toUpperCase() !== "GET" ? data : null,
            params: method.toUpperCase() === "GET" ? data : null,
            headers: {
                authorization: auth,
            },
        };

        axios(req)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                const { data } = res;

                if (method === "POST" && data.error === -999) {
                    Dialog({ title: "Warning", text: data.message });
                    throw data;
                }
                if (parseInt(data.error) !== 0) {
                    throw data;
                }

                resolve && resolve(data);
            })
            .catch((e) => reject(e));
    });
};

api.alert = ({ message }) => {
    return new Promise((resolve, reject) => {
        Dialog({ title: "Error", text: message, button: "Done" }).then(resolve).catch(reject);
    });
};

api.load = (code) => {
    return new Promise((resolve, reject) => {
        api.post({ path: "/api/searchMobileComCode.do", data: { commGrp: code } })
            .then((res) => res.data)
            .then((data) => resolve(data))
            .catch((e) => reject(e));
    });
};
export default api;
