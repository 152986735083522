import React, { useState, useContext, useEffect, Fragment } from "react";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";

import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import { AppContext } from "@component/AppProvider";
import Form, { FormItem, FormItemForAmount, FormItemForAccount, FormSelectItem, FormItemForWithdrawPassword } from "@component/UI/Form";
import Dialog from "@component/UI/Dialog";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _U from "@util/utilities";
import _CK from "@util/cookie";
import _DX from "@util/dx";

import useBalance from "@hook/useBalance";

const _TABS = [
    { label: "Withdraw Balance", value: "WITHDRAW" },
    { label: "Withdraw History", value: "HISTORY" },
];

export default () => {
    const [tab, setTab] = useState(_TABS[0].value);

    const handleOnWithdraw = () => {
        setTab("HISTORY");
    };

    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap t-tab">
                <div className="tab">
                    {_TABS.map((item, i) => (
                        <button key={i} type="button" className={cx({ active: tab === item.value })} onClick={(e) => setTab(item.value)}>
                            {item.label}
                        </button>
                    ))}
                </div>
                {tab === "WITHDRAW" && <Withdraw onWithdraw={handleOnWithdraw} />}
                {tab === "HISTORY" && <History />}
            </Container>
        </Layout>
    );
};

const Withdraw = ({ onWithdraw }) => {
    const { iam, amounts } = useContext(AppContext);

    const [defBanks, setDefBanks] = useState([]);

    const [balance, setBalance] = useState(0);
    const [data, setData] = useState({ bankCode: "", accountNumber: "", dwAmount: 0 });

    const loadBalance = () => {
        _DX.post({ path: "/api/searchPlayerChargeBalance.do" })
            .then((res) => res.data)
            .then((data) => {
                if (!data) {
                    return;
                }
                const { BALANCEAMT: balance } = data;
                setBalance(parseInt(balance));
            })
            .catch((e) => _DX.alert(e));
    };

    const handleOnWithdraw = () => {
        if (!data.bankCode || !data.accountNumber || !data.dwAmount || !data.password) return;
        _DX.post({
            path: "/api/insertPlayerGameWith.do",
            data,
        })
            .then((res) => {
                loadBalance();
                Dialog({ title: "Notice", text: `Withdraw ${_U.addComma(data.dwAmount)} pesos`, button: "OK" }).then(() => {
                    onWithdraw?.();
                });
            })
            .catch((e) => _DX.alert(e));
    };

    useBalance((balance) => {
        setBalance(balance);
    });

    useEffect(() => {
        if (iam) {
            setData({
                ...data,
                bankCode: iam.bankName,
                accountNumber: iam.bankAccountNumber,
            });
        }
    }, [iam]);

    useEffect(() => {
        if (amounts) {
            setData({ ...data, dwAmount: amounts?.withdraw });
        }
    }, [amounts]);

    useEffect(() => {
        _DX.load("AP03").then((data) => setDefBanks(data));
    }, []);

    return (
        <div className="content">
            <h4 className="title-m">My Prepaid Balance</h4>
            <div className="pre-balance-box">₱ {_U.addComma(balance)}</div>
            <ul className="info-list dot">
                <li>If you want to, you can change your bank information or payment method for recharging the prepaid balance in My Account menu.</li>
                <li>The minimum amount that can be recharged for the prepaid balance is ## pesos.</li>
            </ul>
            <Form className="form mt-100">
                <FormSelectItem name="bankCode" items={defBanks} label="Bank" value={data} onChange={setData} />
                <FormItemForAccount name="accountNumber" label="Account" value={data} onChange={setData} />
                <FormItemForAmount name="dwAmount" type="number" label="Amount" value={data} onChange={setData} />
                <FormItemForWithdrawPassword name="password" type="password" label="PIN" value={data} onChange={setData} />
                <div className="btn-group mt-30">
                    <button type="button" className="btn btn-primary" onClick={handleOnWithdraw}>
                        Withdraw
                    </button>
                </div>
            </Form>
        </div>
    );
};

const _DATE_TYPES = [
    { label: "Today", value: "TODAY" },
    { label: "1 week", value: "1WEEK" },
    { label: "1 month", value: "1MONTH" },
    { label: "3 months", value: "3MONTH" },
];
const History = () => {
    const [data, setData] = useState({});
    const [dateType, setDateType] = useState(_DATE_TYPES[0].value);
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handleOnDateTypeChange = (type) => {
        setDateType(type);
        switch (type) {
            case "TODAY":
                setData({ ...data, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1WEEK":
                setData({ ...data, startDate: moment().subtract(7, "d").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "1MONTH":
                setData({ ...data, startDate: moment().subtract(1, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
            case "3MONTH":
                setData({ ...data, startDate: moment().subtract(3, "M").format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
                break;
        }
    };

    const loadList = (page) => {
        if (!data.startDate || !data.endDate) return;

        setCurrentPage(page);
        setTotalPages(0);

        const params = {
            sDate: moment(data.startDate, "YYYY-MM-DD").format("YYYYMMDD"),
            fDate: moment(data.endDate, "YYYY-MM-DD").format("YYYYMMDD"),
            dwClassCode: "WIT",
            currPage: page,
        };

        _DX.post({
            path: "/api/searchPlayerChargeHistory.do",
            data: params,
        })
            .then((res) => res.data)
            .then((data) => {
                const { currPage, games: receivedList, totPage } = data;
                setCurrentPage(currPage);
                setTotalPages(totPage);
                if (currPage > 1) {
                    setList([...list, ...receivedList]);
                } else {
                    setList(receivedList);
                }
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        setData({
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
        });
    }, []);

    useEffect(() => {
        loadList(1);
    }, [data]);

    return (
        <Fragment>
            <div className="content">
                <div className="dateSortWrap">
                    <Form className="form-box">
                        <div className="form-item">
                            <div className="form-field-dual">
                                <input type="date" name="startDate" value={data.startDate || ""} onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })} />
                                <span>~</span>
                                <input type="date" name="endDate" value={data.endDate || ""} onChange={(e) => setData({ ...data, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") })} />
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="buttons">
                                {_DATE_TYPES.map((item, i) => (
                                    <button type="button" key={i} onClick={handleOnDateTypeChange.bind(this, item.value)} className={cx({ active: dateType === item.value })}>
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="history-list">
                    {list.map((item, i) => (
                        <div key={i} className="item">
                            <p className="h-date">{moment(item.DWDATETIME).format("lll")}</p>
                            <div className="description">
                                <p className="memo">{item.DWCLASSNAME}</p>
                                <span
                                    className={cx({
                                        amount: true,
                                        minus: item.DWCLASSCODE === "DEP",
                                        plus: item.DWCLASSCODE === "DEP",
                                    })}
                                >
                                    {item.DWCLASSCODE === "DEP" ? "+" : "-"} ₱ {_U.addComma(item.DWAMOUNT)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                {currentPage < totalPages && (
                    <div className="btn-group mt-30">
                        <button type="button" className="btn btn-secondary btn-arr_more" onClick={() => loadList(currentPage + 1)}>
                            More
                        </button>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div``;
