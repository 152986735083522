import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";

import Layout, { ASISContainer } from "@layout/App";
import Loading from "@component/UI/Loading";

import _DX from "@util/dx";

export default () => {
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pages, setPages] = useState([]);

    const loadList = (page) => {
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "Notice",
                currPage: page,
                keyword,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                const { currPage, games: list, totPage } = data;
                setCurrentPage(currPage);
                setTotalPages(totPage);
                setList(list);

                const pages = [];
                for (let i = 1; i <= totPage; i++) {
                    pages.push(i);
                }
                setPages(pages);
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadList(1);
    }, []);

    return (
        <Layout>
            <ASISContainer className="nwrap">
                <div className="content">
                    <h4 className="title">Notice</h4>
                    <div className="search-input">
                        <input type="text" placeholder="Please enter a search term." value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <button type="button" className="search" onClick={() => loadList(1)}></button>
                    </div>
                    <ul className="notice-list">
                        {/* <li>
                            <Link to="/app/notice/view">
                                <div className="badge hot">hot</div>
                                <div className="inner">
                                    <p className="tit">Title of a Notice</p>
                                    <span className="date">2023-09-06</span>
                                </div>
                            </Link>
                        </li> */}
                        {list?.map((item, i) => (
                            <li key={i}>
                                <Link to={`/app/notice/${currentPage}/${item.NOTICEID}`}>
                                    <div className="num">{item.RN}</div>
                                    <div className="inner">
                                        <p className="tit">{item.NOTICETITLE}</p>
                                        <span className="date">{moment(item.REGDATE).format("YYYY-MM-DD")}</span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="pagination">
                        {/* <li className="prev btn-arr">
                            <a href={void 0}></a>
                        </li> */}

                        {pages.map((page, i) => (
                            <li key={i} className={cx({ active: currentPage === page })}>
                                <a href={void 0} onClick={() => loadList(page)}>
                                    {page}
                                </a>
                            </li>
                        ))}
                        {/* <li className="next btn-arr">
                            <a href={void 0}></a>
                        </li> */}
                    </ul>
                </div>
            </ASISContainer>
        </Layout>
    );
};

export const View = () => {
    const params = useParams();
    const { page, id } = params;
    const [data, setData] = useState(null);

    const loadData = () => {
        if (!id) return;
        _DX.post({
            path: "/api/Board.do",
            data: {
                type: "Notice",
                currPage: page,
                id,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setData(data.games[0]);
            })
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, [id]);

    console.log(data);

    if (!data) return <Loading dark />;

    return (
        <Layout>
            <ASISContainer className="nwrap">
                <div className="content">
                    <h4 className="title">{data.NOTICETITLE}</h4>
                    <div className="notice-view">
                        <div className="top">
                            <span className="date">{moment(data.REGDATE).format("YYYY-MM-DD")}</span>
                            <p className="manager">manager</p>
                        </div>
                        <div className="notice-cont">
                            <div className="txt" dangerouslySetInnerHTML={{ __html: data.NOTICECONTENTS }} />
                        </div>
                    </div>
                    <div className="actions btn-group mt-30">
                        <Link to="/app/notice" className="btn btn-secondary">
                            List
                        </Link>
                    </div>
                </div>
            </ASISContainer>
        </Layout>
    );
};
