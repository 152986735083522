const utils = {};

utils.isId = (id) => {
    const regExp = /^[a-z]+[a-z0-9]{5,19}$/g;
    return regExp.test(id);
};

utils.isPassword = (passwd) => {
    const regExp = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    return regExp.test(passwd);
};
utils.isPin = (pin) => {
    const regExp = /^[a-zA-Z0-9]{6,12}$/;
    return regExp.test(pin);
};

utils.isEmail = (email) => {
    if (!email) {
        return false;
    }
    const reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return reg.test(email.toLowerCase());
};

utils.isMobile = (mobile) => {
    if (!mobile) {
        return false;
    }
    const reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    return reg.test(mobile);
};

utils.encode = (o) => {
    return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
    return JSON.parse(decodeURIComponent(atob(str)));
};

utils.setSession = (code, value) => {
    window.sessionStorage.setItem(code, utils.encode(value));
};

utils.getSession = (code) => {
    if (window.sessionStorage.getItem(code)) {
        return utils.decode(window.sessionStorage.getItem(code));
    }
    return false;
};

utils.removeSession = (code) => {
    window.sessionStorage.removeItem(code);
};

utils.postMessage = (code, data) => {
    window.postMessage(JSON.stringify({ code: code, data: data }), window.location.origin);
};

utils.parseMessage = (e) => {
    return new Promise((resolve, reject) => {
        if (!e.data) {
            reject && reject();
            return;
        }
        let data = null;
        try {
            data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
        } catch (e) {
            reject && reject();
            return;
        }
        try {
            if (data) {
                if (data.source?.startsWith("react")) {
                    reject && reject();
                } else {
                    resolve && resolve(data);
                }
            }
        } catch (e) {
            reject && reject(e);
        }
    });
};

utils.shortText = (text, maxLength, tail) => {
    if (!text || !maxLength) return "";
    let shortText = [];
    for (let i = 0; i < text.length && i < maxLength; i++) {
        shortText.push(text.charAt(i));
    }
    return shortText.join("").length < text.length ? shortText.join("") + tail : shortText.join("");
};

utils.lpad = (ori, len, ad) => {
    try {
        const text = ori + "";
        if (!ad || !len || len <= 0) return "";
        if (text.length >= len) return ori;
        const addData = [];
        for (let i = 0; i < len - text.length; i++) {
            addData.push(ad);
        }
        return addData.join("") + text;
    } catch (e) {
        return "";
    }
};

utils.percentToHex = (p) => {
    const intValue = Math.round((p / 100) * 255);
    const hexValue = intValue.toString(16);
    return hexValue.padStart(2, "0").toUpperCase();
};

utils.sleep = (ms) => {
    return new Promise((r) => setTimeout(r, ms));
};

utils.addComma = (num) => {
    if (!num) {
        return 0;
    }

    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ",");
};

utils.formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

utils.getIdFromEmail = (email) => {
    const nameMatch = email.match(/^([^@]*)@/);
    return nameMatch ? nameMatch[1] : null;
};

utils.isNumeric = (num, opt) => {
    // 좌우 trim(공백제거)을 해준다.
    num = String(num).replace(/^\s+|\s+$/g, "");
    let regex = "";
    if (typeof opt == "undefined" || opt == "1") {
        // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
        regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    } else if (opt == "2") {
        // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
        regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    } else if (opt == "3") {
        // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
        regex = /^[0-9]+(\.[0-9]+)?$/g;
    } else {
        // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
        regex = /^[0-9]+$/g;
    }

    if (regex.test(num)) {
        num = num.replace(/,/g, "");
        return isNaN(num) ? false : true;
    } else {
        return false;
    }
};

utils.removeHtmlEntities = (str) => {
    const htmlEntities = /&(nbsp|amp|quot|lt|gt|hellip);/g;
    return str.replace(htmlEntities, (match, entity) => {
        switch (entity) {
            case "nbsp":
                return " ";
            case "amp":
                return "&";
            case "quot":
                return '"';
            case "lt":
                return "<";
            case "gt":
                return ">";
            case "hellip":
                return "…";
            default:
                return match;
        }
    });
};

export default utils;
