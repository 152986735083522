import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "font-awesome/css/font-awesome.css";
import "@resources/style.css";

const theme = {
    color: {
        main: "#ff8000",
        title: "#ff8000",
        line: "#EEEEEE",
    },
    responsive: {
        width: {
            mobile: 480,
            tablet: 768,
            pc: 1024,
        },
    },
    font: {
        light: "300",
        regular: "400",
        medium: "500",
        bold: "700",
        sBold: "600",
        eBold: "800",
    },
};

const GlobalStyles = createGlobalStyle`
/** reset **/
:root{
	--color-main: #ff8000;
	--color-mainA: #ffebd6;
	--color-base: #FDB207;
	--color-white: #ffffff;
	--color-yellow: #FFF8E9;

	--color-black-1: #333333;
	--color-black-2: #444444;
	
	--color-gray-1: #eeeeee;
	--color-gray-2: #cccccc;
	--color-gray-3: #F4F4F4;
	--color-gray-4: #808080;

	--color-blue: #0044FF;
	--color-red: #FF0000;

	--headerH: 58px;
	--footerH: 68px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
	font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-weight: 400 !important;
    line-height: 1.2;
    color: var(--color-black-1);
}

:focus-visible {
    //border: 1px solid var(--color-main);
    outline: 1px dashed var(--color-gray-2);
}

a {
    display: inline-block;
    text-decoration: unset;
    color: var(--color-black-1);
}

ol,
li {
    list-style: none;
    padding: unset;
    margin: unset;
}

dl,
dt,
dd {
    margin: unset;
}

button {
    padding: unset;
    border: unset;
    color: var(--color-black-1);
    background-color: unset;
}

table {
    all: unset;
    display: table;
}


/** common **/

/* font */
[class="ft-"] {
    color: var(--color-black-1);
}
.ft {
    &-b {
        &-16 {
            font-size: 16px;
            font-weight: 700;
        }
        &-20 {
            font-size: 20px;
            font-weight: 700;
        }
    }
    &-m {
        &-14 {
			line-height: 1.3;
			font-weight: 500;
            font-size: 14px;
        }
        &-16 {
			line-height: 1.3;
			font-weight: 500;
            font-size: 16px;
        }
    }
    &-r {
        &-14 {
			line-height: 1.3;
			font-weight: 400;
            font-size: 14px;
        }
        &-16 {
			line-height: 1.3;
			font-weight: 400;
            font-size: 16px;
        }
    }
}

/* color */
.tc {
    &-main {
        color: var(--color-main) !important;
    }
}

/* margin */
.mt {
    &-15 {
        margin-top: 15px !important;
    }
    &-20 {
        margin-top: 20px !important;
    }
    &-30 {
        margin-top: 30px !important;
    }
    &-50 {
        margin-top: 50px !important;
    }
    &-100 {
        margin-top: 100px !important;
    }
}

hr {
    margin: 30px 0;
    border: none;
	border-top: 1px solid var(--color-gray-1);
}

.scroll-lock {
    overflow: hidden;
}

header {
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	height: 58px;
	background: var(--color-white);
	border-radius: 0;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
	z-index: 1000;
	padding-left: constant(safe-area-inset-left);
	padding-right: constant(safe-area-inset-right);
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	display: flex;
	background: var(--color-white);
	box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.12);
	padding-bottom: constant(safe-area-inset-bottom);
	padding-left: constant(safe-area-inset-left);
	padding-right: constant(safe-area-inset-right);
	padding-bottom: env(safe-area-inset-bottom);
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

footer a {
	position: relative;
	text-decoration: none;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 11px 0;
}

footer a span {
	font-size: 12px;
	color: var(--color-black-1);
	line-height: inherit;
	margin-top: 2px;
	text-decoration: none;
}

footer a img {
	width: 25px;
	height: 25px;
}

footer a img:nth-child(1) {
	display: block;
}

footer a img:nth-child(2) {
	display: none;
}

footer a.active span {
	color: var(--color-main);
}

footer a.active img:nth-child(1) {
	display: none;
}

footer a.active img:nth-child(2) {
	display: block;
}

footer a.unread::after {
	position: absolute;
	top: 16px;
	left: calc(50% + 8px);
	content: "";
	width: 5px;
	height: 5px;
	border-radius: 5px;
	background: var(--color-red);
}

nav {
	display: flex;
	height: inherit;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

nav .menu {
	width: 50px;
	height: 50px;
	flex-shrink: 0;
}

nav .menu a {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

nav .menu a img {
	width: inherit;
}

nav .logo {
	width: 100%;
}

nav .logo a {
	display: block;
	width: 44px;
	height: 44px;
	margin: 0 auto;
}

nav .logo a img {
	width: inherit;
}

nav .profile {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

nav .profile a {
	width: inherit;
	height: inherit;
	border-radius: 50%;
	overflow: hidden;
}

nav .profile a img {
	width: inherit;
}

.nwrap {
    margin: 30px 0 0;
    &.t-tab {
        margin: 0;
    }
    .content {
        margin: 0 15px;
        h4.title {
            margin: 20px auto;
            font-size: 20px;
            font-weight: 700;
            a,
            button {
                background: url(/assets/images/arr_black_20B.png) no-repeat;
                background-position: right 60%;
                background-size: 20px;
                padding-right: 20px;
                line-height: 1.3;
                font: inherit;
            }
        }
        .title-m {
            margin: 20px auto;
            font-size: 16px;
            font-weight: 500;
        }
        .t-center {
            text-align: center;
        }
    }
}

input {
    position: relative;
    padding: unset;
    border: 0;
    &[type="text"] {
        &:focus:not(readonly) {
            outline: var(--color-main) 1px solid;
            border: 1px solid transparent;
			outline-offset: -1px;
        }
    }
    &[type="radio"],
    &[type="checkbox"] {
        display: block;
        position: absolute;
        width: 1px;
        height: 1px;
        margin-left: -1px;
        clip-path: polygon(0 0, 0 0, 0 0);
        opacity: 0;
    }
}

input[type="checkbox"] {
    & + span {
        display: inline-block;
        font-size: 12px;
        color: var(--color-black-1);
        margin-top: -2px;
        line-height: 1.3;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background: url(/assets/images/ico_checkboxD.png) no-repeat;
            background-size: contain;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100%);
            height: 100%;
            opacity: 0;
        }
    }
    &:checked {
        & + span {
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                background: url(/assets/images/ico_checkboxC.png) no-repeat;
                background-size: contain;
            }
        }
    }
    &:disabled {
        & + *::before {
        }
    }
    &:focus + span:after {
        opacity: 1;
    }
}

input[type="radio"] {
    & + span {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background: url(/assets/images/ico_radioD.png) no-repeat;
            background-size: contain;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100%);
            height: 100%;
            opacity: 0;
        }
    }
    &:checked {
        & + span {
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
                background: url(/assets/images/ico_radioC.png) no-repeat;
                background-size: contain;
            }
        }
    }
    &:disabled {
        & + *::before {
        }
    }
    &:focus + span:after {
        opacity: 1;
    }
}

input[type="date"],
input[type="datetime-local"] {
    border: none;
    position: relative;
    width: 100%;
    padding: 10px 30px 10px 10px;
    background: url(/assets/images/btn_calendar.png) no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-black-1);
    &::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        color: transparent;
        cursor: pointer;
    }
    &:valid::before {
        display: none;
    }
    &::-webkit-date-and-time-value {
        text-align: left;
    }
}

.form {
    & + & {
        margin-top: 30px;
    }
    &.login {
        margin-top: 30px;
    }
    .info {
        font-size: 13px;
        font-weight: 400;
        color: var(--color-black-1);
        line-height: 1.4;
    }
    &-option {
        margin-top: 30px;
        .option-cont {
            opacity: 0;
            height: 0;
            visibility: hidden;
			margin-top: 20px;
        }
		&.show {
			.btn-arr_more{
				&::after{
					transform: rotate(-90deg);
				}
			}
            .option-cont {
                opacity: 1;
                visibility: visible;
                height: auto;
                transition: all 0.2s;
            }
        }
    }
}

.form-checkbox,
.form-radio {
    position: relative;
    display: flex;
    gap: 0 20px;
    align-items: center;
    label {
        position: relative !important;
        top: unset !important;
        left: unset !important;
        pointer-events: auto !important;
        flex: 1 0;
        padding-left: 30px;
    }
}

.form-select {
    display: flex;
    min-width: 113px;
    height: 40px;
    gap: 5px;
    select {
        flex: 1;
        height: inherit;
        border: 1px solid var(--color-gray-1);
        border-radius: 0px;
        font-size: 12px;
        font-weight: 500;
        color: var(--color-black-2);
        padding: 7px 7px 7px 10px;
        background-color: var(--color-white);
        border-radius: 5px;
    }
}

.form-item {
    .form-label,
    label {
        position: absolute;
        top: 16px;
        left: 20px;
        font-size: 16px;
        color: var(--color-gray-2);
        z-index: 10;
        transition: all 0.1s ease-out;
        font-weight: 400;
        pointer-events: none;
    }
    .form-field {
        position: relative;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        input {
            flex: 1;
            border: 0;
            padding: 22px 18px 8px;
			border: 1px solid var(--color-gray-2);
            font-weight: 400;
            font-size: 16px;
            color: var(--color-black-1);
            border-radius: 5px;
            &:focus {
                outline: 1px solid var(--color-main);
                border: 1px solid transparent;
				outline-offset: -1px;
                & ~ label {
                    font-size: 12px;
                    font-weight: 500;
                    transform: translate3d(0, -8px, 0);
                    color: var(--color-main);
                }
                &[readonly] {
                    outline: 1px solid transparent;
					color: var(--color-black-2);
					border: 1px solid var(--color-gray-2);
                    & ~ label {
                        color: var(--color-gray-2);
                    }
                }
            }
            &:not(:placeholder-shown) ~ label {
                font-size: 12px;
                font-weight: 500;
                transform: translate3d(0, -8px, 0);
            }
            &::placeholder {
                color: transparent;
            }
            &[type="password"] {
                & ~ .password-info {
                    .btn-show {
                        display: none;
                        &.hide {
                            display: block;
                        }
                    }
                }
            }
            & ~ .password-info {
                .btn-show {
                    display: block;
                    &.hide {
                        display: none;
                    }
                }
            }
        }
        select {
            flex: 1;
            border: 0;
            padding: 22px 18px 8px;
			border: 1px solid var(--color-gray-2);
            font-weight: 400;
            font-size: 16px;
			color: var(--color-black-1);
            max-width: 100%;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: url(/assets/images/arr_black_40.png) no-repeat;
            background-position: calc(100% - 5px) center;
            background-size: 40px;
            border-radius: 5px;
            &:focus {
                outline: 1px solid var(--color-main);
                border: 1px solid transparent;
				outline-offset: -1px;
                & ~ label {
                    color: var(--color-main);
                }
            }
            & ~ label {
                font-size: 12px;
                font-weight: 500;
                transform: translate3d(0, -8px, 0);
            }
        }
        & ~ .msg {
            display: none;
            margin-top: 5px;
        }
        .password-info {
            position: absolute;
            right: 10px;
            transform: translateY(-50%);
            top: 50%;
            .btn-show {
                width: 45px;
                padding-top: 100%;
                background: url(/assets/images/icon_password.png) no-repeat;
                background-size: contain;
                background-position: center;
                &.hide {
                    background-image: url(/assets/images/icon_password_off.png);
                }
            }
        }
        &.error {
            & ~ .msg {
                display: block;
                width: 100%;
                color: var(--color-red);
                font-size: 10px;
                padding-left: 5px;
            }
        }
        &.sucess {
            input {
                background: url(/assets/images/ico_success.png) no-repeat;
                background-size: 40px;
                background-position: calc(100% - 10px) center;
            }
        }
        .btn-send {
            position: absolute;
            right: 5px;
            min-width: 70px;
            padding: 11px 15px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            background-color: var(--color-main);
            color: var(--color-white);
            &:disabled {
                background-color: var(--color-gray-1);
                color: var(--color-black-1);
            }
        }
        .btn-next {
            position: absolute;
            right: 5px;
            min-width: 70px;
            padding: 11px 15px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            background-color: var(--color-main);
            color: var(--color-white);
            &:disabled {
				opacity: 0.5;
			}
        }
    }
    .form-radio-field {
        margin-top: 10px;
        label {
            margin: 0 10px;
            font-size: 16px;
            font-weight: 500;
			color: var(--color-black-1);
            :first-of-type {
                margin-left: 0px;
            }
        }
    }
    & + .form-item,
    & + .form-select {
        margin-top: 15px;
    }
}

/* button */
.btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    > * {
		flex: 1;
        /*flex: 1 0 auto;*/
    }
    &.actions {
        display: flex;
        align-items: center;
        button {
            flex: 1 0 calc((100% / 2) - 10px);
        }
    }
    &.w100 {
        flex-wrap: wrap;
        [class*="btn-"] {
            width: 100%;
            flex: auto;
        }
    }
    a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

.btn {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    min-height: 50px;
    border-radius: 5px;
    text-transform: capitalize;
    z-index: 10;
    a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    &-sm {
        min-height: 30px;
        padding: 12px 15px;
        font-size: 12px;
        font-weight: 400;
        color: var(--color-black-2);
        background: var(--color-white);
        border: 1px solid var(--color-gray-2);
        border-radius: 5px;
    }
    &-primary {
        background-color: var(--color-main);
        color: var(--color-white);
        border: none;
        &:disabled {
            opacity: 0.5;
        }
    }
    &-secondary {
        border: 1px solid var(--color-main);
        background-color: var(--color-white);
        color: var(--color-main);
    }
    &-gray {
        background-color: var(--color-gray-1);
        color: var(--color-black-1);
    }
    &-bline {
        background-color: var(--color-white);
        color: var(--color-gray-4);
        border: 1px solid var(--color-gray-4);
    }
    &-arr {
		&_more {
			position: relative;
			&::after{
				content: '';
				width: 20px;
				height: 20px;
				background: url(/assets/images/arr_orange_20.png) no-repeat;
				background-size: contain;
				background-position: center;
				transform: rotate(90deg);
			}
            /*img {
                width: 20px;
                height: 20px;
                transform: rotate(90deg);
            }*/
        }
        &_sm {
            img {
                width: 15px;
                height: 15px;
            }
        }
    }
    &-charge_sm {
        background-image: url(/assets/images/arr_white_sm.png);
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: calc(100% - 5px) center;
        padding: 8px 20px 8px 10px;
        background-color: var(--color-main);
        color: var(--color-white);
        min-height: 0;
        font-size: 12px;
        text-transform: capitalize;
    }
    &-text {
        color: var(--color-main);
        &.udline {
            position: relative;
            z-index: 15;
            text-decoration: underline;
        }
    }
}

[class*="btn-arr"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* tab */
.tab {
    margin: 15px 0 0;
    display: flex;
    & button {
        position: relative;
        flex: 1;
        padding: 0 0 12px;
        font-weight: 500;
        font-size: 16px;
        background-color: var(--color-white);
        border: none;
        border-bottom: 1px solid var(--color-gray-1);
        color: var(--color-gray-2);
        &.active {
            padding: 0 0 12px;
            color: var(--color-main);
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 80%;
                border-bottom: 2px solid var(--color-main);
                transform: translateX(-50%);
            }
        }
    }
}

/* select */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/assets/images/arr_black_40.png) no-repeat;
    background-size: 24px;
    background-position: right center;
    padding-right: 40px !important;
    width: 100%;
    &::-ms-expand {
        display: none;
    }
}


/* modal, gameModal, formModal (game, register, login) */
.modaless-overlay{
	position: fixed;
  	top: 0;
  	bottom: 0;
  	left: 0;
  	right: 0;
  	text-align: center;
  	font-size: 0;
  	overflow-y: auto;
  	background-color: rgba(0, 0, 0, 0.4);
  	z-index: 10000;
  	pointer-events: none;
  	opacity: 0;
  	transition: opacity 0.3s;
	&:before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}
	&--show-modal {
  		opacity: 1;
  		pointer-events: auto;
		.modeless.formMdodal {
			opacity: 1;
			pointer-events: auto;
			box-sizing: border-box;
			animation: showSweetAlert 0.3s;
			will-change: transform;
		}
  	}
}
.modeless {
	.content{
		margin: 30px 15px 0;
	}
	.title {
        font-weight: 700;
        font-size: 20px;
		color: var(--color-main);
        .point {
            margin-left: 15px;
			color: var(--color-main);
        }
    }
    .btn-group {
        position: fixed;
        padding: 15px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2001;
        border: none;
        background-color: var(--color-white);
        display: flex;
        gap: 10px;
        box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.12);
        button {
            flex: 1;
            padding: 15px 0;
            border: var(--color-main) solid 1px;
            font-size: 18px;
            font-weight: 500;
            &.done {
                color: var(--color-white);
                background: var(--color-main);
            }
        }
    }
    &.gameModal {
		position: fixed;
            padding: 0 0 80px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            overflow-x: hidden;
            overflow-y: auto;
            webkit-overflow-scrolling: touch;
            background-color: #ffffff;
            top: 0;
            transition: all 0.5s;
        .title {
            padding: 15px 0;
            color: var(--color-main);
            text-align: center;
			background-color: var(--color-white);
			border-bottom: 1px solid var(--color-gray-1);
        }
        .picks {
            position: relative;
            display: flex;
            /*border-bottom: 1px solid var(--color-gray-1);*/
			min-height: calc(100% + 80px);
            border-bottom: none;
            .pick-group {
                flex: 1;
                border-right: 1px solid var(--color-gray-1);
                :last-of-type {
                    border-right: none;
                }
                .title {
                    position: sticky;
                    top: 0px;
                    z-index: 2001;
                    margin: 0;
                    padding: 18px 0;
                    font-size: 18px;
                    font-weight: bold;
                    color: var(--color-main);
                    background-color: var(--color-white);
                    text-align: center;
                    border-bottom: none;
           			box-shadow: 0 -1px 0px 0px var(--color-gray-1);
                }

                .numbers {
                    display: flex;
                    height: 100%;
                    .cols {
                        flex: 1;
                        text-align: center;
                    	border-right: 1px solid var(--color-gray-1);
                        :last-of-type {
                            border-right: none;
                        }
                        &.even {
                            background-color: var(--color-yellow);
                        }
                        .title {
                            position: sticky;
							top: 56px;
                            padding: 10px 0;
							border-bottom: 1px solid var(--color-gray-1);
                            font-size: 12px;
                            font-weight: 400;
                            z-index: 2001;
                        }
                        button {
                            position: relative;
                            /*margin: 20px auto 44px;*/
							margin: 20px auto;
                            width: 40px;
                            height: 40px;
                            display: block;
                            background-color: var(--color-white);
                            border: 1px solid var(--color-gray-1);
                            color: var(--color-gray-2);
                            font-size: 14px;
                            font-weight: 400;
                            border-radius: 100%;
                            &.active {
                                color: var(--color-white);
                                background-color: var(--color-main);
                                border: 1px solid transparent;
								font-weight: 500;
                            }
							& + button:not(:has(img)){
								margin-top: 90px;
							}
                        }
                    }
                }

                .fruits {
                    .cols {
                        button {
                            width: 50px;
                            height: 50px;
                            margin: 15px auto;
                            border: 0;
                            background-color: transparent;
                            img {
								width: inherit;
								height: inherit;
                                object-fit: contain;
                                opacity: 0.2;
                            }
                            /*:first-of-type {
                                margin-top: 14px;
                            }*/
                            &.active {
                                background-color: transparent;
                                img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    & + .btn-group {
        position: fixed;
        padding: 15px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2001;
        border: none;
        background-color: rgb(255, 255, 255);
        display: flex;
        gap: 10px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px -3px 10px;
    }
	&.formMdodal {
		width: 478px;
		opacity: 0;
		pointer-events: none;
		background-color: var(--color-white);
		text-align: center;
		border-radius: 5px;
		position: static;
		margin: 20px auto;
		display: inline-block;
		vertical-align: middle;
		transform: scale(1);
		transform-origin: 50% 50%;
		z-index: 10001;
		transition: transform 0.3s, opacity 0.2s;
		font-family: "Pretendard", "Noto Sans KR", "IBM Plex Sans", sans-serif;
		padding: 0 15px 20px;
		.form-modal-header {
			position: relative;
			a {
				position: absolute;
				left: 0px;
				top: 10px;
				width: 45px;
				height: 45px;
				background: url(../../public/assets/images/btn_back.png) no-repeat;
				background-size: contain;
			}
			.title {
				color: var(--color-main);
				padding: 20px;
			}
		}
		.form-modal-icon{
			>div {
				margin: 0 auto;
			}
			.logo {
				width: 58px;
			}
			.img-msg {
				width: 164px;
				margin-top: 15px;
			}
			img {
				width: inherit;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
		.form-modal-body {
			border-top: 2px solid var(--color-gray-1);
			padding-top: 15px;
			>div {
				margin: 0 auto;
			}
			.img-msg {
				width: 164px;
			}
			img {
				width: inherit;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
		.form-modal-footer {
			display: flex;
			padding-top: 13px;
			margin-top: 13px;
			border-radius: inherit;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.form-modal-title {
			font-size: 14px;
		}

		.form-modal-text {
			color: var(--color-black-1);
			font-size: 14px;
			font-weight: 500;
			text-align: center;
		}

		.form-modal-button-container {
			display: flex;
			position: relative;
			width: 100%;
			margin: 5px;
			gap: 5px;
			.form-modal-button {
				width: inherit;
				height: 50px;
				font-size: 16px;
				font-weight: 500;
				padding: 10px;
				border-radius: 5px;
			}
			.form-modal-button{
				&--confirm {
					background-color: var(--color-main);
					color: var(--color-white);
				&:hover {
						background-color: var(--color-main);
					}	
				}
			}
		}
	}
	&.fav {
		.title {
			background-color: var(--color-yellow);
		}
		.picks {
			.pick-group {
				.favPick {
					display: flex;
					justify-content: space-evenly;
					align-items: center;
					padding: 15px 0;
					& + .favPick {
						padding: 15px 0;
						border-top: 1px solid var(--color-gray-1);
					}
					img {
						width: 40px;
						height: 40px;
						background-color: transparent;
						img {
							width: inherit;
							height: inherit;
							object-fit: contain;
							opacity: 0.2;
						}
						&.active {
							background-color: transparent;
							img {
								opacity: 1;
							}
						}
					}
					.check-input{
						width: 30px;
						height: 30px;
					}
				}
			}
		}
		.btn-pd-group{
			display: flex;
			gap: 0 5px;
			.btn-pick,
			.btn-del {
				display: block;
				width: 44px;
				height: fit-content;
				min-height: 25px;
				font-size: 12px;
				border-radius: 13px;
				font-weight: 500;
				background-color: var(--color-gray-3);
				text-transform: capitalize;
			}
		}
	}
	&.hasfooter{
		margin-bottom: calc(var(--footerH) + 40px) !important;
		.btn-group:not(.fixed_btn){
			position: static;
			z-index: unset;
			background-color: transparent;
			box-shadow: unset;
			padding: unset;
			gap: unset;
		}
	}
}

/* box type (space between) */
.tcont-item {
    width: 100%;
    display: flex;
    background-color: var(--color-white);
    min-height: 56px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin: 5px 0;
    .amount {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-black-1);
        &.jackpot {
            color: var(--color-main);
        }
    }
    .case {
        flex: 1 0;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-black-2);
        text-transform: capitalize;
        .fyi {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: var(--color-gray-4);
        }
        b {
			display: inline-block;
			min-height: 20px;
            font-weight: 500;
            padding-right: 10px;
        }
    }
    .btn-group {
        justify-content: flex-end;
        button,
        .btn {
            flex: 0 auto;
        }
    }
}

/* round check input (Game) */
.check-input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /*padding: 10px;*/
    text-align: center;
    color: var(--color-white);
    background: var(--color-main);
	font-weight: 400;
	cursor: auto;
    &.active {
        color: var(--color-white);
		background: var(--color-main);
		font-weight: 500;
    }
    &.fail {
        color: var(--color-white);
        background: var(--color-gray-4);
    }
    &:focus {
        outline: none;
    }
}

/* box type (selected game buttons)  */
.draw-combi {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
	padding: 0px 15px;
    .draw-round {
        font-size: 14px;
    }
    img {
        width: 35px;
        height: 35px;
    }
}

.draw-info-box {
    padding: 20px;
    margin-top: 30px;
    background-color: var(--color-yellow);
    border-radius: 5px;
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    h4 {
        font-size: 18px;
        font-weight: 700;
        color: var(--color-black-1);
    }
    .prize_detail {
        width: 100%;
        font-size: 16px;
        color: var(--color-main);
        font-weight: 500;
        margin-top: 10px;
    }
}

/* badege */
.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-weight: 500;
    font-size: 12px;
    width: 64px;
    min-height: 27px;
    border-radius: 14px;
    text-align: center;
	/*&.yellow {
        background-color: var(--color-base);
		}*/
	&.win {
		background-color: var(--color-red);
	}
    &.no_prize {
        background-color: var(--color-black-2);
        border-radius: 5px;
    }
    &.pending {
        border: 1px solid var(--color-black-2);
        background-color: var(--color-white);
        color: var(--color-black-2);
        border-radius: 5px;
    }
    &.red {
        background-color: var(--color-red);
    }
}

/* copyright text */
.copyRight {
    margin: 100px 0 0;
    width: 100%;
    font-size: 10px;
    color: var(--color-gray-4);
    text-align: center;
    line-height: 1.2;
}

/* pagination */
.pagination {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
    gap: 0 5px;
    li {
        a {
            width: 20px;
            height: 20px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: -1px;
        }
        &.active {
            a {
                border-radius: 50%;
                background-color: var(--color-main);
                color: var(--color-white);
            }
        }
        &.btn-arr {
            a {
                background: url(/assets/images/arr_black_20.png) no-repeat;
                background-size: 20px;
                background-position: center;
            }
            &.prev {
                a {
                    transform: rotate(-180deg);
                }
            }
            &.next {
                transform: none;
            }
        }
    }
}

/* date sort */
.dateSortWrap {
    width: calc(100% + 30px);
    margin: 0 -15px 0;
    background-color: var(--color-gray-3);
	padding: 20px 15px;
    .form-select {
        select {
            color: var(--color-black-1);
            font-size: 14px;
        }
    }
}

.form-box {
    .form-field-dual {
        display: flex;
        background: var(--color-white);
        border-radius: 5px;
        align-items: center;
        input {
            background-color: var(--color-white);
        }
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .buttons {
        display: flex;
        gap: 5px;
        position: relative;
        margin-top: 20px;
        button {
            flex: 1;
            padding: 13px 5px;
            font-size: 14px;
            font-weight: 400;
            background: var(--color-white);
            text-align: center;
            color: var(--color-gray-2);
            width: 100%;
            border: 1px solid var(--color-gray-1);
            border-radius: 5px;
			line-height: 1;
            &.active {
				font-weight: 500;
                color: var(--color-main);
                border: var(--color-main) solid 1px;
            }
        }
    }
}

/* box bold border */
.bl-cont {
    border-top: 2px solid var(--color-black-2);
    border-bottom: 2px solid var(--color-black-2);
}

/* info text type */
.info-list {
    margin-top: 30px;
    &.dot {
        li {
            position: relative;
            padding-left: 10px;
            line-height: 1.5;
            &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 6px;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: var(--color-black-2);
            }
            & + li {
                margin-top: 10px;
            }
        }
    }
}

.info {
    position: relative;
    margin-top: 8px;
    color: var(--color-black-2);
    font-size: 12px;
    &.dot {
        padding-left: 10px;
        &:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 6px;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: var(--color-black-2);
        }
    }
}

/* favorite modal call btn */
.btn-fav{
	width: 25px;
	height: 25px;
	background: url(/assets/images/ico_fav.png) no-repeat;
	background-size: contain;
	background-position: center;
}

/* animation */
@keyframes showSweetAlert {
	0% {
		transform: scale(1);
	}

	1% {
		transform: scale(0.5);
	}

	45% {
		transform: scale(1.05);
	}

	80% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(1);
	}
}

/* media query */
@media (max-width: 500px) {
	.swal-modal {
		width: calc(100% - 20px);
	}

	.modeless.formMdodal {
		width: calc(100% - 20px);
	}
}


/** styles **/

/* account */
.account {
    padding: 15px 15px 0;
    background: var(--color-main);
    color: var(--color-white);
    border-radius: 5px;
    .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        color: inherit;
    }
    .point {
        padding: 15px;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        border-bottom: var(--color-white) solid 1px;
        line-height: 1.3;
        .peso {
            padding-right: 5px;
            font-size: 20px;
            font-weight: 500;
            color: var(--color-white);
        }
        img {
            margin: 0px 0 0 7px;
            vertical-align: bottom;
        }
    }
    .actions {
        display: flex;
        button {
            padding: 15px 0 15px;
            flex: 1;
            border: none;
            background-color: transparent;
            color: var(--color-white);
            font-size: 16px;
        }
    }
}
/* play Games : tab content */
.types {
    display: flex;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    button {
        flex: 1;
        padding: 12px 0;
		color: var(--color-gray-2);
        font-size: 16px;
        font-weight: 500;
        background-color: var(--color-gray-3);
        &.active {
            background: var(--color-main);
            color: var(--color-white);
            font-weight: 700;
            border: none;
        }
    }
    button.base {
        &.active {
			background-color: var(--color-base);
        }
    }
    &-cont {
        background-color: var(--color-gray-3);
    }
}

.gameWrap {
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 8px rgba(49, 28, 6, 0.15);
    .game-inner {
        background-color: var(--color-white);
        .item {
			min-height: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 15px;
            background-color: var(--color-white);
            border-bottom: 1px solid var(--color-gray-3);
            align-items: center;
            padding: 10px 0;
            margin: 0px 10px;
            .number {
                flex: 0 0 6%;
                color: var(--color-black-1);
                button {
                    background-color: transparent;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--color-black-1);
                }
            }
            .picker {
                flex: 1;
                display: flex;
                justify-content: space-around;
                align-items: center;
                button {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    border: none;
                    color: var(--color-main);
                    background: var(--color-mainA);
                    &.active {
                        background-color: var(--color-main);
                        color: var(--color-white);
						font-weight: 500;
                    }
                }
                button.fruit {
                    img {
                        width: inherit;
                        height: inherit;
                        object-fit: contain;
                    }
                    &.active {
						width: 35px;
						height: 35px;
                        background-color: transparent;
                    }
                }
            }
            .lp {
                width: 100%;
                flex: 0 0 44px;
                button {
                    width: inherit;
                    padding: 7px 8px;
                    font-size: 12px;
                    font-weight: 500;
                    background-color: var(--color-gray-3);
                    color: var(--color-black-2);
                    border-radius: 15px;
                }
            }
        }
    }
}

.gameSelector {
	margin-top: 30px;
	.countdown {
		margin: 20px 0 10px;
		text-align: center;
		span {
			font-size: 18px;
			font-weight: 700;
			color: var(--color-main);
		}
		.draw-round {
			font-size: 16px;
			font-weight: 500;
			color: var(--color-black-1);
			padding-right: 15px;
		}
	}
	.draw-date {
		text-align: center;
		color: var(--color-gray-4);
	}
}

.loginAccount {
	button {
		width: 100%;
		height: 130px;
		color: #fff;
		font-weight: bold;
		font-size: 18px;
		background: #f08300;
		border: none;
		border-radius: 10px;
	}
}

/* banner */
.banner {
    width: calc(100% + 30px);
    height: 100%;
    padding: 35px 15px;
    margin: 0px -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    min-height: 180px;
    background: url("/assets/images/main_jackpot_bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    gap: 10px;
    .today {
        font-size: clamp(12px, 18vw, 25px);
        font-weight: 700;
        color: var(--color-main);
        text-align: center;
    }

    /*.accumulated {
        margin: 0;
        padding: 14px 0;
        width: 90%;
        background: var(--color-white);
        font-weight: bold;
        color: #ff6103;
        font-size: 39px;
        text-align: center;
    }*/
    .accumulated {
        font-size: clamp(20px, 18vw, 40px);
    }
}

/* draw result banner */
.winner_banner {
	&_bg {
		&.banner {
			background: url("/assets/images/main_result_bg.png") no-repeat;
			background-size: 100% 100%;
			background-position: center;
			padding: 40px 15px;
			gap: 15px;
		}
		.winner_open {
			width: 100%;
			background: var(--color-main);
			color: #fff;
			text-align: center;
			font-size: 16px;
			padding: 10px 15px;
			border-radius: 5px;
			font-weight: 500;
		}
	}
	&_number {
		position: relative;
		width: 100%;
		border-radius: 5px;
		overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			min-height: 60px;
			height: 100%;
			width: inherit;
			background: #ffffff 0% 0% no-repeat padding-box;
			mix-blend-mode: soft-light;
		}
		.txt-decorate {
			> * {
				letter-spacing: -0.2px;
			}
			.bg {
				transform: translateY(1px);
			}
		}
		.draw-combi {
			padding: 10px 15px;
			> * {
				z-index: 10;
			}
		}
		.check-input {
			box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
		}
	}
}

/* banner : text effect */
.txt-decorate {
    display: grid;
    grid-template-areas: "overlap";
    place-content: center;
    text-align: center;
    /*padding: 14px 0;*/
    > * {
        display: block;
        background-clip: text;
        -webkit-background-clip: text;
        color: #363833;
        font-weight: 900;
        /*font-size: 40px;*/
        grid-area: overlap;
        letter-spacing: 1px;
    }
    .bg {
        background-image: linear-gradient(to bottom, #530c00 0%, #e89500 5%, #530c00 12%);
        color: transparent;
        filter: drop-shadow(3px 8px 10px #530c00);
        transform: scaleY(1.05) translateX(2px) translateY(2px);
        transform-origin: top;
        -webkit-text-stroke: 1px transparent;
    }
    .fg {
        background-image: linear-gradient(to bottom, #ffee37 0%, #ffface 10%, #ffd500 40%, #e89500 54%, #ffee37 73%);
        /* color: #1e2127; */
        transform: scale(1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

/* recipt: ticket */
.receipt{
	.title{
		color: var(--color-main);
		text-align: center;
	}
}

.ticket {
    position: relative;
	margin-top: 30px;
    .ticket-tit {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
		color: var(--color-main);
		&.mega{
			color: var(--color-main);
		}
		&.base{
			color: var(--color-base);
		}
	}
    .game-list {
        margin: 20px 0;
        display: grid;
        grid-row-gap: 10px;
        grid-column-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        &.mega {
            grid-template-columns: repeat(1, 1fr);
			.item{
				gap: 0 10px;
			}
        }
        .item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0 4px;
            font-size: 14px;
            font-weight: 500;
            color: var(--color-black-1);
            line-height: 15px;
            .number {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--color-main);
                color: var(--color-white);
                text-align: center;
                line-height: 20px;
                font-size: 10px;
            }
			.no{
				display: inline-flex;
				width: 20px;
				min-height: 20px;
				line-height: 20px;
				text-align: center;
				word-break: break-all;
				justify-content: center;
			}
            .fruit {
                width: 20px;
                height: 20px;
            }
			.lp{
				padding: 0 5px;
			}
        }
    }

    .info {
        display: flex;
        font-size: 12px;
        color: var(--color-black-2);
        > * {
            flex: 1;
        }
        .rside {
            text-align: right;
        }
    }
    .paid {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ticket-number {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
		color: var(--color-black-2);
    }
    .ticket-subTxt {
        margin: 20px 0 0;
        font-size: 12px;
        font-weight: 400;
		color: var(--color-gray-4);
    }
    .draw-info-box {
        margin-top: 10px;
        min-height: 115px;
		&.WMark{
			position: relative;
			background-image: url(/assets/images/logo_600.png);
			background-size: 180px;
			background-position: center;
			background-repeat: no-repeat;
			z-index: -1;
			&::after{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba(255,248,233,0.85);
				z-index: -1;
			}
		}
    }
}

/* games : selected combinations */
.selected_combibox {
    width: calc(100% + 30px);
    margin: 0px -15px;
    margin-top: 40px;
    padding: 8px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-white);
    .tit {
        padding: 10px;
        background-color: var(--color-black-2);
    }
    & + .draw-info-box {
        margin-top: 0;
    }
}

.play_games_bar {
    margin-bottom: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
	border-bottom: 2px solid var(--color-black-2);
    span {
        color: var(--color-black-2);
    }
}

.games_bar {
    border-top: 1px solid var(--color-gray-1);
    .draw-combi {
		padding: 10px 15px;
    }
    img {
        width: 35px;
        height: 35px;
        &.fail {
            opacity: 0.5;
        }
    }
}

/* my */
.mypage {
    &_profile_box {
        display: flex;
        align-items: center;
        padding: 25px 40px 20px 0px;
        background: url(/assets/images/btn_set.png) no-repeat;
        background-size: 40px 40px;
        background-position: right center;
        border-bottom: 1px solid var(--color-gray-1);
        gap: 0 15px;
    }
    &_img {
        float: none;
        width: auto;
        flex-shrink: 0;
        .profile_btn {
            width: 17px;
            margin: 0 0 0 -19px;
        }
    }

    &_text {
        flex: 1 0 auto;
        margin-top: 0;
    }
    &_name {
        font-size: 16px;
        font-weight: 700;
        color: var(--color-black-1);
        line-height: 1.2;
    }
    &_email {
        font-size: 14px;
        color: var(--color-gray-4);
        line-height: 1.2;
    }
}

.mypage_my_plays {
    &_date {
        width: 25%;
        text-align: left;
        flex-shrink: 0;
        color: var(--color-black-2);
        font-size: 14px;
        font-weight: 500;
    }
    &_li {
        padding: 10px 5px;
        .top {
            > * {
                font-weight: 500;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .bottom {
            margin-top: 15px;
        }
        &.win {
            background-color: var(--color-yellow);
        }
        & + li {
            border-top: 1px solid var(--color-gray-1);
        }
        &.mega {
            .mypage_my_plays {
                &_game,
                &_type {
                    color: var(--color-main);
                }
            }
        }
        &.base {
            .mypage_my_plays {
                &_game,
                &_type {
                    color: var(--color-base);
                }
            }
        }
    }
    &_bets {
       	color: var(--color-black-2);
        font-weight: normal;
        font-size: 12px;
    }
    &_ticket {
       	color: var(--color-black-2);
        font-weight: normal;
        font-size: 10px;
    }
    &_more {
		width: 62px;
		padding: 7px 0;
		text-align: center;
		margin: 30px auto;
		font-size: 12px;
		font-weight: normal;
		color: var(--color-black-2);
		border: 1px solid var(--color-black-2);
    }
}

/* my : Winnings */
.mypage_my_winnings {
    &_type {
        display: flex;
        border-radius: 5px;
        border: 1px solid var(--color-gray-1);
        overflow: hidden;
        min-height: 80px;
        .type {
            width: 87px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: inherit;
            flex-shrink: 0;
            font-weight: 700;
            color: var(--color-white);
        }
        &.mega {
            .type {
                background-color: var(--color-main);
            }
        }
        &.base {
            .type {
                background-color: var(--color-base);
            }
        }
        & + .mypage_my_winnings_type {
            margin-top: 15px;
        }
    }
    &_box {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 auto;
        color: var(--color-gray-4);
        font-size: 14px;
        padding: 0 15px;
        .count {
            font-weight: 500;
            color: var(--color-black-2);
            text-decoration: underline;
        }
        & + .mypage_my_winnings_item {
            border-top: 1px solid var(--color-gray-1);
        }
    }
}

/* my : Plays Game */
.my_game_sort_box {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 500;
	color: var(--color-black-1);
    .form-item {
        display: flex;
        align-items: center;
        gap: 20px;
        label {
            font-size: 14px;
            color: var(--color-black-1);
        }
    }
}

/* notice */
.search-input {
    position: relative;
    input {
        width: 100%;
        border: 1px solid var(--color-gray-2);
        border-radius: 5px;
        padding: 15px 40px 15px 15px;
        font-size: 14px;
        &::placeholder {
            color: var(--color-gray-4);
        }
    }
    button {
        width: 40px;
        height: 40px;
        background: url(/assets/images/ico_search.png) no-repeat;
        background-position: calc(100% - 10px) center;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    & + .notice-list {
        margin-top: 20px;
    }
    & + .faq-list {
        margin-top: 20px;
    }
}

.notice-list {
    border-top: 2px solid var(--color-black-2);
    border-bottom: 2px solid var(--color-black-2);
	a {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 10px 15px;
        padding: 18px 15px;
		background-color: var(--color-white);
		&:has(.badge.hot){
			background-color: var(--color-yellow);
		}
    }
    .badge.hot {
        min-width: 30px;
        min-height: auto;
		width: auto;
		background-color: var(--color-main);
        padding: 3px;
        flex-shrink: 0;
        border-radius: 3px;
        font-size: 12px;
        color: var(--color-white);
		text-transform: capitalize;
    }
    .tit {
        font-size: 14px;
        color: var(--color-black-1);
        font-weight: 500;
    }
    .date {
        display: block;
        margin-top: 5px;
        color: var(--color-gray-4);
        font-size: 12px;
    }
    .num {
        min-width: 30px;
    }
    li + li {
        border-top: 1px solid var(--color-gray-2);
    }
}

.notice-view {
    border-top: 2px solid var(--color-black-2);
    border-bottom: 2px solid var(--color-black-2);
    .top {
        padding: 15px 0;
        display: flex;
        gap: 0 30px;
        border-bottom: 1px solid var(--color-gray-2);
        > * {
            font-weight: 500;
            font-size: 12px;
            color: var(--color-black-1);
        }
    }
    .notice-cont {
        padding: 20px 0;
        .txt {
            font-size: 14px;
            color: var(--color-black-2);
            line-height: 1.6;
        }
    }
}

/* charge, withdraw */
.pre-balance-box {
    width: 100%;
    background-color: var(--color-yellow);
    border-radius: 5px;
    padding: 12px;
    font-size: 18px;
    color: var(--color-main);
    font-weight: 700;
    text-align: center;
}

/* charge, withdraw : history list */
.history-list {
    .item {
        padding: 15px 5px;
		border-bottom: 1px solid var(--color-gray-1);
        .h-date {
			color: var(--color-black-2);
            font-weight: 500;
            font-size: 14px;
        }
        .description {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            color: var(--color-black-2);
            margin-top: 10px;
            gap: 5px;
            .amount {
                color: var(--color-main);
                font-weight: 500;
                font-size: 14px;
                flex-shrink: 0;
                &.minus {
					color: var(--color-red)
                }
                &.plus {
					color: var(--color-blue);
                }
            }
			.memo{
				color: var(--color-gray-4);
				font-size: 12px;
				font-weight: 400;
			}
        }
    }
}

/* faq */
.faq-list {
    .subject {
        padding: 22px 15px;
        background: url(/assets/images/arr_black_40.png) no-repeat;
        background-position: calc(100% - 10px) center;
        background-size: 40px;
        > p {
            font-weight: 500;
            font-size: 14px;
        }
        &.on {
            background-color: var(--color-yellow);
            background-image: url(/assets/images/arr_orange_40.png);
            color: var(--color-main);
            > p {
                color: var(--color-main);
            }
        }
    }
    .answer {
        line-height: 1.5;
    }
    dl {
        & + dl {
            border-top: 1px solid var(--color-gray-2);
        }
    }
}

/* my : information (asis) */
.member_change_btn {
    margin: 0px 0 15px;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
	color: var(--color-gray-4);
}

.info_table {
    width: 100%;
    border-collapse: collapse;
    border-color: var(--color-gray-4);
    tr {
		border-bottom: 1px solid var(--color-gray-1);
        min-height: 50px;
    }
    td {
        &:first-of-type {
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            color: var(--color-black-1);
            background: var(--color-yellow);
            padding: 18px 10px;
        }
        &:not(:nth-of-type(1)) {
            padding: 14px 0px 14px 15px;
            font-size: 14px;
            font-weight: 500;
            & + & {
                text-align: right;
            }
        }
    }
}

/* my : information (tobe) */
.ninfo-list {
    dl {
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-black-1);
        & + dl {
            border-top: 1px solid var(--color-gray-1);
        }
    }
    dt {
        flex: 1 0 30%;
    }
    dd {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1 1 50%;
        input {
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            color: var(--color-black-1);
            text-align: right;
            &:focus {
                outline: none;
                border: none;
            }
        }
        & > *:not(button, div) {
            padding-right: 20px;
			font-size: 14px;
        }
        &:has(input + button) {
            input {
                padding-right: 0px;
            }
        }
    }
	.call-modal-box{
		display: flex;
	}
    .call-modal {
		height: 20px;
        padding-right: 20px;
        background: url(/assets/images/arr_orange_20.png) no-repeat;
        background-size: 20px;
        background-position: calc(100% + 5px) center;
        color: var(--color-main);
        font-weight: 500;
		font-size: 14px;
        &.set {
            color: var(--color-black-1);
            background: url(/assets/images/arr_black_20.png) no-repeat;
            background-size: 20px;
            background-position: calc(100% + 5px) center;
        }
        &:disabled {
            opacity: 0.5;
            color: var(--color-black-1);
            background-image: url(/assets/images/arr_black_20.png);
        }
    }
}

.result-item {
	//margin: 20px 0;
    .title {
		display: block;
        margin: 20px 0;
        font-size: 18px;
        font-weight: 700;
    }
    .box {
        margin-top: 15px;
        border: 1px solid var(--color-gray-1);
        border-radius: 5px;
        .top {
			padding: 20px 0;
            background-color: var(--color-yellow);
			>*{
				padding: 0 15px;
			}
            .draw-combi {
                margin-top: 15px;
            }
        }
        .tcont-item {
            .case {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
	.draw-combi{
		img{
			width: 40px;
			height: 40px;
		}
	}
	.check-input{
		width: 35px;
		height: 35px;
	}
}

/* draw result : tab content (asis) */
.draw_results {
	.form-select {
		height: 50px;
		select {
			color: #444444;
			font-size: 14px;
			border-radius: 5px 5px 0 0;
		}
	}
	.form-box {
		margin: 30px 0;
	}
	.draw-combi {
		background-color: #f7f7f7;
		border: 1px solid #e5e5e5;
		border-radius: 0px 0px 5px 5px;
		margin-top: -1px;
		padding: 15px;
	}
	/* draw_results_table */
	&_table {
		margin-top: 15px;
		border-radius: 5px;
		border: 1px solid #e5e5e5;
		overflow: hidden;
		table {
			width: 100%;
			background: var(--color-white);
			text-align: center;
		}
		td {
			font-size: 16px;
			font-weight: 400;
			padding: 10px 5px;
			color: var(--color-black-2);
			border-top: 1px solid #e5e5e5;
			border-left: none;
			border-right: 1px solid #e5e5e5;
			&:last-of-type {
				border-right: none;
			}
		}
		.game {
			td {
				padding: 12px 5px;
				font-weight: 700;
				border-top: none;
				&:nth-child(2) {
					padding-right: 15px;
				}
				.prize {
					text-align: right;
					font-size: 12px;
					font-weight: 500;
					color: var(--color-black-2);
				}
				.point {
					text-align: right;
					font-size: 16px;
					font-weight: 700;
				}
			}
			&.mega {
				td {
					&:first-of-type {
						background-color: var(--color-main);
						color: #ffffff;
					}
				}
				.point {
					color: var(--color-main);
				}
				& + .case {
					td {
						background-color: var(--color-mainA);
						font-weight: 500;
						color: #ff8000;
					}
				}
			}
			&.base {
				td {
					&:first-of-type {
						background-color: #fdb207;
						color: #ffffff;
					}
				}
				.point {
					color: #fdb207;
				}
				& + .case {
					td {
						font-weight: 500;
						background-color: #fff7e5;
						color: #fdb207;
					}
				}
			}
		}
	}
}

/* draw result : tab content (tobe) */
.ndraw_results {
    select {
        border-radius: 5px 5px 0 0;
    }
    .form-box {
        margin: 30px 0;
    }
    /* draw_results_table */
    &_table {
        margin-top: 15px;
        border-radius: 5px;
		border: 1px solid var(--color-gray-1);
        overflow: hidden;
        table {
            width: 100%;
            background: var(--color-white);
            text-align: center;
        }
        td {
            font-size: 16px;
            font-weight: 400;
            padding: 10px 5px;
            color: var(--color-black-2);
			border-top: 1px solid var(--color-gray-1);
            border-left: none;
			border-right: 1px solid var(--color-gray-1);
            &:last-of-type {
                border-right: none;
            }
        }
        .game {
            td {
                padding: 12px 5px;
                font-weight: 700;
                border-top: none;
                &:nth-child(2) {
                    padding-right: 15px;
                }
                .prize {
                    text-align: right;
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--color-black-2);
                }
                .point {
                    text-align: right;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            &.mega {
                td {
                    &:first-of-type {
                        background-color: var(--color-main);
                        color: var(--color-white);
                    }
                }
                .point {
                    color: var(--color-main);
                }
                & + .case {
                    td {
                        background-color: var(--color-mainA);
                        font-weight: 500;
						color: var(--color-main);
                    }
                }
            }
            &.base {
                td {
                    &:first-of-type {
                        background-color: var(--color-base);
                        color: var(--color-white);
                    }
                }
                .point {
                    color: var(--color-base);
                }
                & + .case {
                    td {
                        font-weight: 500;
                        background-color: #fff7e5;
						color: var(--color-base);
                    }
                }
            }
        }
    }
}
.draw-result-details{
	.hr-12{
		display: block;
		height: 12px;
		background-color: rgba(204, 204, 204, 0.14);
		margin: 30px -24px;
		border: 0;
	}
	.draw-combi{
		padding: 10px 15px;
		border: 1px solid var(--color-gray-3);
		border-radius: 5px;
	}
}

/* login (asis)*/
.autologin {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 20px 5px 30px;
}

/* terms */
.terms, .termsOfPlay {
	&:not(.termsOfPlay){
		max-height: 450px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	p:not(.sub-title, .title, .fm-14, b) {
		font-size: 14px;
		color: #333333;
		line-height: 1.5;
	}
	b {
		display: block;
		margin: 5px 0;
		font-weight: 500;
	}
	.sub-title {
		font-size: 16px;
		font-weight: 700;
		line-height: 1.3;
		margin: 30px 0 10px;
	}
	table {
		width: 100%;
		border: 1px solid #808080;
		th,
		td {
			padding: 5px;
			border-top: 1px solid #808080;
			& + td {
				border-left: 1px solid #808080;
			}
		}
		thead {
			td {
				border-top: none;
			}
		}
	}
	.content{
		margin: 30px 15px 0;
	}
	.title{
		margin: 20px 0 10px;
	}
}

/* charge balance : tab content */
.pg-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    justify-items: center;
    gap: 10px 0;
    margin: 30px 20px 30px;
    .pg-btn {
		width: 60px;
		height: 60px;
        border: 1px solid var(--color-gray-2);
        border-radius: 5px;
        img {
            width: 100%;
        }
        &.payNet {
            &.active {
                & ~ .payNow {
                    display: block;
                }
            }
        }
        &.payNow {
            display: none;
        }
        &.disabled,
        &:disabled {
            img {
                filter: grayscale(1);
                opacity: 0.5;
            }
            .pg-txt {
                color: var(--color-gray-4);
            }
        }
        &:focus,
        &.active {
            /*border: 1px solid var(--color-main);*/
            .pg-txt {
                /*color: var(--color-main);*/
            }
        }
    }
	.pg-txt {
		color: var(--color-black-1);
		font-weight: 500;
		font-size: 12px;
		text-transform: capitalize;
		&:disabled {
			.pg-txt {
				color: var(--color-gray-4);
			}
		}
    }
}

/*****************************************************************************************/
/*****************************************************************************************/
/*****************************************************************************************/

`;
export default GlobalStyles;
export const Theme = theme;
export const ThemeProvider = Provider;
