import { useEffect, useContext, useState } from "react";
import moment from "moment";

import { AppContext } from "@component/AppProvider";

import _CK from "@util/cookie";
import _DX from "@util/dx";

const useAmounts = () => {
    const { amounts, saveAmounts } = useContext(AppContext);

    const loadAmounts = () => {
        if (amounts) return;

        _DX.post({ path: "/api/searchGameConfigBaseAmt.do" })
            .then((res) => res.data)
            .then((data) => {
                const { BASEUNITPRICE: base, MEGAUNITPRICE: mega, PCSOSTDAMT: pcso, DEPAMTBASE: charge, WITAMTBASE: withdraw } = data;
                const amounts = {
                    price: {
                        base,
                        mega,
                    },
                    pcso,
                    charge,
                    withdraw,
                };
                saveAmounts(amounts);
            })
            .catch((e) => console.log(e));
    };

    useEffect(loadAmounts, []);
};

export default useAmounts;
