import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import _CK from "@util/cookie";

import useIam from "@hook/useIam";
import useAmounts from "@hook/useAmounts";

import Loading from "@component/UI/Loading";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";

const Layout = ({ children, breadcrumb, noFooter, header }) => {
    const location = useLocation();
    const { pathname } = location;
    const [loaded, setLoaded] = useState(false);

    const checkLogin = () => {
        switch (pathname) {
            case "/app/login":
            case "/app/join":
            case "/app/join/information":
            case "/app/forgot":
            case "/app/terms":
                break;
            default:
                if (!_CK.isLogin()) {
                    document.location.href = "/app/login";
                }

                break;
        }
    };

    useIam({
        onSuccess: (iam) => {
            setLoaded(true);
        },
        onError: (e) => {
            _CK.clearAuth();
            document.location.href = "/";
        },
    });

    useAmounts();

    useEffect(() => {
        checkLogin();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pathname]);

    return (
        <Fragment>
            {header ? header : <Header />}
            <Container className={noFooter ? "" : "hasfooter"}>{children}</Container>
            {!noFooter && <Footer />}

            <Menu />
            {!loaded && <Loading dark />}
        </Fragment>
    );
};

export default Layout;

/**
 * Styled-Components
 */
const Container = styled.div`
    padding-bottom: 10px;
    margin: 58px 0 20px;
    overflow: hidden;
    &.hasfooter {
        margin-bottom: calc(var(--footerH) + 20px);
    }
`;
export const ASISContainer = styled.div`
    /*about*/
    .about_img {
        /*margin: 30px;*/
        text-align: center;
        > img {
            width: 100%;
            max-width: 259px;
        }
    }
`;
