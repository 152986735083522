import React, { useState, Fragment, useEffect, useContext } from "react";
import styled from "styled-components";

import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import Link, { Logout } from "@component/UI/Link";

import useBalance from "@hook/useBalance";
import _U from "@util/utilities";

export default () => {
    const { iam } = useContext(AppContext);
    const [balance, setBalance] = useState(0);

    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        $("#btn-menu").on("click", () => {
            setIsShow(!isShow);
        });
    }, []);

    useBalance((b) => setBalance(b));

    const isLogin = _CK.isLogin();

    return (
        <Container id="menu" isShow={isShow} onClick={() => setIsShow(!isShow)}>
            <div className="modeless" id="userMenu">
                <div className="inner">
                    <div className="abs">
                        <div className="close" onClick={() => setIsShow(!isShow)}>
                            <img src="/assets/images/ico_close.png" id="menu-close" alt="close" title="close" />
                        </div>
                        {isLogin ? (
                            <div className="profile">
                                <Link href="/app/my" className="name">
                                    <a>
                                        <img src="/assets/images/user_profile_lg.png" alt="profile" />
                                        <span>{`${iam?.FIRSTNAME}${iam?.LASTNAME ? `, ${iam.LASTNAME}` : ""}`}</span>
                                    </a>
                                </Link>

                                <div className="account">
                                    <h5 className="name">My Balance</h5>
                                    <div className="point">₱ {_U.addComma(balance)}</div>
                                    <div className="actions">
                                        <Link href="/app/my/charge" className="btn btn-arr_sm">
                                            <button type="button">
                                                Charge
                                                <img src="/assets/images/arr_white_sm.png" />
                                            </button>
                                        </Link>
                                        <Link href="/app/my/withdraw" className="btn btn-arr_sm">
                                            <button type="button">
                                                Withdraw
                                                <img src="/assets/images/arr_white_sm.png" />
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <ul className="menu">
                                <li className="join">
                                    <Link href="/app/login" title="Sign in">
                                        <a>Sign in</a>
                                    </Link>
                                </li>
                                <li className="join">
                                    <Link href="/app/join" title="Create Account">
                                        <a>Create Account</a>
                                    </Link>
                                </li>
                            </ul>
                        )}

                        <ul className="menu">
                            <li>
                                <Link href="/app/about" title="About Prutas Panalo">
                                    <a>About Prutas Panalo</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/app" title="Play Games">
                                    <a>Play Games</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/app?mode=result" title="Draw Results">
                                    <a>Draw Results</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/app/notice" title="Notice">
                                    <a>Notice</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/app/faq" title="FAQ">
                                    <a>FAQ</a>
                                </Link>
                            </li>
                        </ul>
                        <Link href="https://www.pcso.gov.ph" className="pcsoLink" target="_blank">
                            <a>
                                <img className="pcso" src="/assets/images/pcso.png" alt="pcso" title="pcso" />
                            </a>
                        </Link>
                        {isLogin && (
                            <div className="logout">
                                <Logout>
                                    <a>Log out</a>
                                </Logout>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div`
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: all 0.5s;
    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};

    .modeless {
        width: 300px;
        background: #fff;
        opacity: 1;
        position: absolute;
        top: 0;
        box-shadow: 0 0 10px #000;
        overflow-x: hidden;
        height: 100%;
        overflow-y: auto;
        webkit-overflow-scrolling: touch;
        left: ${({ isShow }) => (isShow ? "0px" : "-300px")};
        transition: all 0.5s;
        .inner {
            position: relative;
            height: 100%;
            display: block;
            overflow: hidden;
            margin: 0 20px;
            .abs {
                position: absolute;
                width: 100%;
                display: block;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                width: 100%;
                -webkit-transform: translateZ(0);
                -moz-transform: translateZ(0);
                -ms-transform: translateZ(0);
                -o-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-text-size-adjust: none;
                -moz-text-size-adjust: none;
                -ms-text-size-adjust: none;
                -o-text-size-adjust: none;
                text-size-adjust: none;
                .close {
                    cursor: pointer;
                    text-align: right;
                    margin: 10px auto 0;
                    > img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    div.profile {
        .name {
            align-items: center;
            font-weight: 700;
            font-size: 20px;
            cursor: pointer;
            display: flex;
            gap: 10px;
            img {
                width: 31px;
                height: 31px;
            }
            span {
                flex: 1;
            }
        }

        .account {
            margin: 20px 0px 0px;
            .name {
                margin: 0;
                padding: 0;
                font-weight: normal;
                font-size: 17px;
            }
            .point {
                padding: 22px 0 15px;
                font-weight: bold;
                font-size: 26px;
                text-align: center;
                border-bottom: #ffffff solid 1px;
                line-height: 26px;
                img {
                    margin: 0px 0 0 7px;
                    vertical-align: bottom;
                }
            }
        }
    }

    ul.menu {
        padding: 20px 0;
        border-bottom: 1px solid #ccc;
        list-style: none;

        li {
            font-weight: 500;
            font-size: 18px;
            margin: 15px 15px;
            color: #000000;

            a {
                color: #000000;
                text-decoration: none;
                :hover {
                    color: #000000;
                }
                :focus {
                    color: #000000;
                }
            }

            &.active {
                a {
                    color: #000000;
                }
                :hover {
                    color: #000000;
                }
                :focus {
                    color: #000000;
                }
            }

            &.join {
                font-weight: bold;
                font-size: 20px;
                margin: 15px 15px;
            }
        }

        span {
            position: absolute;
            right: 20px;
            top: 15px;
        }
    }

    .pcsoLink {
        display: block;
        margin: 20px 0;
    }

    .logout {
        padding: 40px 0 0;
        border-top: 1px solid #ccc;
        color: #767676;
    }
`;
