import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import _CK from "@util/cookie";

import IndexPage from "./pages";
import AppPage, { AppAboutPage, AppNoticePage, AppNoticeViewPage, AppFaqPage } from "./pages/App";
import { AppLoginPage, AppJoinPage, AppJoinInformationPage, AppForgotPage } from "./pages/App";
import { AppMyPage, AppMyChargePage, AppMyWithdrawPage, AppMyWalletPage } from "./pages/App";
import AppMyGamesPage, { Ticket as AppMyGamesTicketPage } from "./pages/App/my/games";
import AppMyInformationPage from "./pages/App/my/information";
import { AppTermsPage } from "./pages/App";
import { Receipt as AppGameReceiptPage } from "./pages/App/Game";

export default () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/app" element={<AppPage />} />
                <Route path="/app/login" element={<AppLoginPage />} />
                <Route path="/app/join" element={<AppJoinPage />} />
                <Route path="/app/join/information" element={<AppJoinInformationPage />} />
                <Route path="/app/forgot" element={<AppForgotPage />} />
                <Route path="/app/about" element={<AppAboutPage />} />
                <Route path="/app/notice" element={<AppNoticePage />} />
                <Route path="/app/notice/:page/:id" element={<AppNoticeViewPage />} />
                <Route path="/app/faq" element={<AppFaqPage />} />
                <Route path="/app/terms" element={<AppTermsPage />} />
                <Route path="/app/my" element={<AppMyPage />} />
                <Route path="/app/my/charge" element={<AppMyChargePage />} />
                <Route path="/app/my/withdraw" element={<AppMyWithdrawPage />} />
                <Route path="/app/my/wallet" element={<AppMyWalletPage />} />
                <Route path="/app/my/information" element={<AppMyInformationPage />} />
                <Route path="/app/my/games" element={<AppMyGamesPage />} />
                <Route path="/app/my/games/:groupId" element={<AppMyGamesTicketPage />} />
                <Route path="/app/game/:ids" element={<AppGameReceiptPage />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </Router>
    );
};
