import React from "react";
import BasicLoader from "react-spinners/ScaleLoader";

import styled from "styled-components";

const getBackground = (props) => {
    if (props.background) {
        return props.background;
    }
    if (props.dark) {
        return "rgba(0, 0, 0, 0.2)";
    }
    return "#ffffff";
};

export default (props) => {
    return (
        <Wrapper {...props}>
            <Loader {...props?.spinnerOptions} />
        </Wrapper>
    );
};

export const Loading = (props) => {
    return (
        <Wrapper {...props}>
            <Loader {...props?.spinnerOptions} />
            <Title>{props.title || "Loading..."}</Title>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: ${(props) => getBackground(props)};
`;

const Loader = styled(BasicLoader).attrs((props) => {
    return {
        color: "rgba(255, 128, 0, 0.9)",
        size: props.size || 20,
    };
})``;

const Title = styled.h3`
    margin-top: 1rem;
    font-size: 1rem;
    display: block;
`;
