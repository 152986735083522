import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { default as Linker } from "@component/UI/Link";
import _CFG from "@config/defaults";

const Footer = () => {
    const location = useLocation();
    const { pathname: path } = location;

    return (
        <Container>
            <Link to="/app/about" className={cx({ active: path.startsWith("/app/about") })}>
                <img src="/assets/images/ico_about.png" />
                <img src="/assets/images/ico_aboutO.png" />
                <span>About</span>
            </Link>
            <Link to="/app/notice" className={cx({ active: path.startsWith("/app/notice") })}>
                <img src="/assets/images/ico_notice.png" />
                <img src="/assets/images/ico_noticeO.png" />
                <span>Notice</span>
            </Link>
            <Link to="/app" className={cx({ active: path === "/app" })}>
                <img src="/assets/images/ico_home.png" />
                <img src="/assets/images/ico_homeO.png" />
                <span>Home</span>
            </Link>
            <Link to="/app/faq" className={cx({ active: path.startsWith("/app/faq") })}>
                <img src="/assets/images/ico_faq.png" />
                <img src="/assets/images/ico_faqO.png" />
                <span>FAQ</span>
            </Link>
            <Linker href="https://www.pcso.gov.ph/" target="_blank">
                <a>
                    <img src="/assets/images/ico_pcso.png" />
                    <span>PCSO</span>
                </a>
            </Linker>
        </Container>
    );
};

export default Footer;

/**
 * Styled-Components
 */
const Container = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    background: #fff;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.12);

    padding-bottom: constant(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);

    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    a {
        position: relative;
        text-decoration: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 11px 0;

        span {
            font-size: 12px;
            color: #46433f;
            line-height: inherit;
            margin-top: 2px;
            text-decoration: none;
        }
        img {
            width: 25px;
            height: 25px;
            &:nth-child(1) {
                display: block;
            }
            &:nth-child(2) {
                display: none;
            }
        }

        &.active {
            span {
                color: var(--color-main);
            }
            img:nth-child(1) {
                display: none;
            }
            img:nth-child(2) {
                display: block;
            }
        }

        &.unread::after {
            position: absolute;
            top: 16px;
            left: calc(50% + 8px);
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background: #ff4d55;
        }
    }
`;
