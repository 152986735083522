import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import cx from "classnames";

import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import { ToBack } from "@component/UI/Link";
import Loading from "@component/UI/Loading";
import Dialog from "@component/UI/Dialog";
import _DX from "@util/dx";

import _U from "@util/utilities";
import _CK from "@util/cookie";
import _DEF from "@config/defines";

export default () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadData = () => {
        if (!_CK.isLogin()) return;
        setLoading(true);

        _DX.post({ path: "/api/searchPlayerInfo.do" })
            .then((res) => res.data)
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((e) => _DX.alert(e).then(() => ToBack(navigate)));
    };

    const handleOnItemClick = (category) => {
        const getTitle = () => {
            switch (category) {
                case "name":
                    return "Set My Name";
                case "phone":
                    return "Set My Phone";
                case "email":
                    return "Set My Email";
                case "pin":
                    return "Set New Pin";
                case "address":
                    return "Set My Address";
                case "occupation":
                    return "Set My Occupation";
                case "tax":
                    return "Set My Tax";
                case "income":
                    return "Set My Income";
            }
        };
        Dialog({ title: getTitle(), body: <DlgFormBody mode={category} data={data} /> }).then((c) => {
            if (!c) return;
            console.log(c);
            const params = {
                firstName: c.nameFirst || c.FIRSTNAME,
                middleName: c.nameMiddle || c.MIDDLENAME,
                lastName: c.nameLast || c.LASTNAME,
                suffix: c.suffix || null,
                phoneNo: c.mobile || c.PHONENO,
                email: c.email || c.EMAIL,
                address1: c.address || c.ADDRESS1,
                zipCode: c.zipcode || c.ZIPCODE,
                cityCode: c.city || c.CITYCODE,
                jobCode: c.occupation || c.JOBCODE,
                tinNo: c.tax || c.TINNO,
                annSalary: c.monthlyFixedIncome || c.AVGSALARYCODE,
                pinCurrent: c.pinCurrent || null,
            };

            if (c.pin) {
                params.password = c.pin;
            }

            _DX.post({ path: "/api/updatePlayerInfo.do", data: params })
                .then((res) => {
                    if (res.error !== 0) {
                        throw { message: res.message };
                    }

                    loadData();
                })
                .catch((e) => Dialog({ title: "Error", text: e.message, button: "Confirm" }));
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Layout noFooter header={<Header />}>
            <Container>
                <div className="nwrap">
                    <div className="content">
                        <h4 className="title">Player Information</h4>
                        <div className="bl-cont">
                            <div className="ninfo-list">
                                <dl>
                                    <dt>Full Name</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "name")} disabled>
                                            {data?.FIRSTNAME} {data?.MIDDLENAME} {data?.LASTNAME} {data?.SUFFIX}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>KYC Setting</dt>
                                    <dd>
                                        <button type="button" className="call-modal" disabled>
                                            In Review
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Phone</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "phone")} disabled>
                                            {data?.PHONENO}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>PIN</dt>
                                    <dd>
                                        <button type="button" className="call-modal" onClick={handleOnItemClick.bind(this, "pin")}>
                                            Modify
                                        </button>
                                    </dd>
                                </dl>
                                {/* <dl>
                                    <dt>Fund Password</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" disabled>
                                            Set
                                        </button>
                                    </dd>
                                </dl> */}
                                <dl>
                                    <dt>Gender</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" disabled>
                                            {data ? _DEF.getDefinedLabel(_DEF.gender, data?.GENDERCODE) : "-"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Birthday</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" disabled>
                                            {data?.BIRTHDATE ? moment(data.BIRTHDATE, "MMDDYYYY").format("MM/DD/YYYY") : "-"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>My Address</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "address")}>
                                            {data?.ADDRESS1 || "Not Set"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Email</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "email")}>
                                            {data?.EMAIL || "-"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Occupation</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "occupation")}>
                                            {data?.JOBCODE ? _DEF.getDefinedLabel(_DEF.occupation, data?.JOBCODE) : "Not Set"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Tax Identification Number</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "tax")}>
                                            {data?.TINNO || "Not Set"}
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Monthly Fixed Income</dt>
                                    <dd>
                                        <button type="button" className="call-modal set" onClick={handleOnItemClick.bind(this, "income")}>
                                            {data?.AVGSALARYCODE ? _DEF.getDefinedLabel(_DEF.monthlyFixedIncome, data?.AVGSALARYCODE) : "Not Set"}
                                        </button>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            {loading ? <Loading dark /> : null}
        </Layout>
    );
};

const Container = styled.div`
    .id_view_btn {
        margin: -7px 0;
        font-size: 12px;
        font-weight: normal;
        color: #747474;
        border: 1px solid #747474;
        padding: 7px 9px;
    }
`;

const DlgFormBody = ({ mode, data: dataInProps, resolve, reject }) => {
    const [defOccupations, setDefOccupations] = useState([]);
    const [defMonthlyFixedIncomes, setDefMonthlyFixedIncomes] = useState([]);

    const [data, setData] = useState({});

    const isValidForm = () => {
        switch (mode) {
            case "name":
                if (!data.nameFirst) return false;
                if (!data.nameLast) return false;
                break;
            case "phone":
                if (!data.mobile) return false;
                break;
            case "email":
                if (!data.email) return false;
                if (!_U.isEmail(data.email)) return false;
                break;
            case "pin":
                if (!data.pinCurrent) return false;
                if (!data.pin) return false;
                if (!_U.isPin(data.pin)) return false;
                if (data.pin !== data.pinConfirm) return false;
                break;
            case "address":
                if (!data.address) return false;
                if (!data.zipcode) return false;
                if (!data.city) return false;
                break;
            case "occupation":
                if (!data.occupation) return false;
                break;
            case "tax":
                if (!data.tax) return false;
                break;
            case "income":
                if (!data.monthlyFixedIncome) return false;
                break;
        }
        return true;
    };

    const handleOnSubmit = () => {
        resolve?.({ mode, ...data });
    };

    useEffect(() => {
        setData({
            nameFirst: dataInProps?.FIRSTNAME,
            nameMiddle: dataInProps?.MIDDLENAME,
            nameLast: dataInProps?.LASTNAME,
            suffix: dataInProps?.SUFFIX,
            mobile: dataInProps?.PHONENO,
            email: dataInProps?.EMAIL,
            pinCurrent: null,
            pin: null,
            pinConfirm: null,
            address: dataInProps?.ADDRESS1,
            zipcode: dataInProps?.ZIPCODE,
            city: dataInProps?.CITYCODE,
            occupation: dataInProps?.JOBCODE,
            tax: dataInProps?.TINNO,
            monthlyFixedIncome: dataInProps?.AVGSALARYCODE,
            ...data,
        });
    }, [dataInProps]);

    useEffect(() => {
        _DX.load("AP06").then((data) => setDefOccupations(data));
        _DX.load("AP07").then((data) => setDefMonthlyFixedIncomes(data));
    }, []);

    return (
        <DlgContainer className="form">
            {mode === "name" && (
                <Fragment>
                    <FormItem name="nameFirst" data={data} setData={setData} label="First Name" isError={!data.nameFirst} errMsg="First name is required." />
                    <FormItem name="nameMiddle" data={data} setData={setData} label="Middle Name" />
                    <FormItem name="nameLast" data={data} setData={setData} label="Last Name" isError={!data.nameLast} errMsg="Last name is required." />
                    <FormItem name="suffix" data={data} setData={setData} label="Suffix" />
                </Fragment>
            )}
            {mode === "phone" && <FormItem name="mobile" type="number" data={data} setData={setData} label="Phone No" isError={!data.mobile} errMsg="Phone No is required." />}
            {mode === "email" && <FormItem name="email" data={data} setData={setData} label="Email" isError={!data.email || !_U.isEmail(data.email)} errMsg="Please enter a valid email address." />}
            {mode === "pin" && (
                <Fragment>
                    <FormItem
                        type="password"
                        name="pinCurrent"
                        data={data}
                        setData={setData}
                        label="Current Pin"
                        isError={!data.pinCurrent || !_U.isPin(data.pinCurrent)}
                        errMsg="Please enter 6 - 12 alphanumeric without special character"
                    />
                    <FormItem
                        type="password"
                        name="pin"
                        data={data}
                        setData={setData}
                        label="New Pin"
                        isError={!data.pin || !_U.isPin(data.pin)}
                        errMsg="Please enter 6 - 12 alphanumeric without special character"
                    />
                    <FormItem
                        type="password"
                        name="pinConfirm"
                        data={data}
                        setData={setData}
                        label="Confirm Pin"
                        isError={data.pinConfirm && data.pin !== data.pinConfirm}
                        errMsg="The PIN and PIN confirmation do not match."
                    />
                </Fragment>
            )}
            {mode === "address" && (
                <Fragment>
                    <FormItemForAddress name="address" data={data} setData={setData} label="Address" />
                    <FormItemForZipcode name="zipcode" data={data} setData={setData} label="Zip Code" />
                    <FormItemForCity name="city" data={data} setData={setData} label="City" />
                </Fragment>
            )}
            {mode === "occupation" && (
                <div className="form-item">
                    <div className="form-field">
                        <select name="occupation" id="occupation" value={data.occupation || ""} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}>
                            <option value="">Not Set</option>
                            {defOccupations.map((item, i) => (
                                <option key={i} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <label id="occupation" className="form-label">
                            Occupation
                        </label>
                    </div>
                </div>
            )}
            {mode === "tax" && (
                <div className="form-item">
                    <div className="form-field">
                        <input
                            name="tax"
                            type="text"
                            id="tax"
                            placeholder="Tax Identification Number"
                            value={data.tax || ""}
                            onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^0-9-]/g, "").slice(0, 20) })}
                        />
                        <label id="tax" className="form-label">
                            Tax Identification Number
                        </label>
                    </div>
                </div>
            )}
            {mode === "income" && (
                <div className="form-item">
                    <div className="form-field">
                        <select name="monthlyFixedIncome" id="monthlyFixedIncome" value={data.monthlyFixedIncome} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}>
                            <option value="">Select Income</option>
                            {defMonthlyFixedIncomes.map((item, i) => (
                                <option key={i} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <label id="monthlyFixedIncome" className="form-label">
                            Monthly Fixed Income
                        </label>
                    </div>
                </div>
            )}
            <div className="footer">
                <button className="form-modal-button btn btn-primary" disabled={!isValidForm()} onClick={handleOnSubmit}>
                    Submit
                </button>
            </div>
        </DlgContainer>
    );
};

const DlgContainer = styled.div`
    /*margin: 0 2px;*/
    position: relative;
    width: 100%;
    margin-top: 10px;
    .footer {
        margin: 20px 0 0;
        button {
            width: 100%;
        }
    }
`;

const FormItem = ({ data, setData, type, label, name, isError, errMsg }) => {
    return (
        <div className="form-item">
            <div className={cx({ "form-field": true, error: isError })}>
                <input name={name} type={type || "text"} id={name} placeholder={label} value={data[name] || ""} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            </div>
            {isError && <p className="msg">{errMsg}</p>}
        </div>
    );
};

const FormItemForZipcode = ({ data, setData, type, label, name, isError, errMsg }) => {
    return (
        <div className="form-item">
            <div className={cx({ "form-field": true, error: isError })}>
                <input
                    name={name}
                    type={type || "text"}
                    id={name}
                    placeholder={label}
                    value={data[name] || ""}
                    onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^0-9]/g, "").slice(0, 4) })}
                />
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            </div>
            {isError && <p className="msg">{errMsg}</p>}
        </div>
    );
};

const FormItemForCity = ({ data, setData, type, label, name, isError, errMsg }) => {
    return (
        <div className="form-item">
            <div className={cx({ "form-field": true, error: isError })}>
                <input
                    name={name}
                    type={type || "text"}
                    id={name}
                    placeholder={label}
                    value={data[name] || ""}
                    onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^a-zA-Z0-9 \-\(\)&]/g, "").slice(0, 50) })}
                />
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            </div>
            {isError && <p className="msg">{errMsg}</p>}
        </div>
    );
};

const FormItemForAddress = ({ data, setData, type, label, name, isError, errMsg }) => {
    return (
        <div className="form-item">
            <div className={cx({ "form-field": true, error: isError })}>
                <input
                    name={name}
                    type={type || "text"}
                    id={name}
                    placeholder={label}
                    value={data[name] || ""}
                    onChange={(e) => setData({ ...data, [e.target.name]: e.target.value?.replace(/[^a-zA-Z0-9 \-\(\)&,]/g, "").slice(0, 100) })}
                />
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            </div>
            {isError && <p className="msg">{errMsg}</p>}
        </div>
    );
};
