import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import qs from "query-string";
import cx from "classnames";
import moment from "moment";

import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import Link, { ToLink } from "@component/UI/Link";
import Dialog from "@component/UI/Dialog";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _CK from "@util/cookie";
import _DX from "@util/dx";

export default () => {
    const [tab, setTab] = useState(_DEF.loginType[0].value);

    return (
        <Layout noFooter header={<Header hasProfile={false} hasBack={false} />}>
            <div className="nwrap t-tab">
                {/* <div className="tab">
                    {_DEF.loginType.map((item, i) => (
                        <button type="button" key={i} className={cx({ active: tab === item.value })} onClick={() => setTab(item.value)}>
                            {item.label}
                        </button>
                    ))}
                </div>
                <div className="content">
                    {tab === "PIN" && <LoginForm />}
                    {tab === "OTP" && <OTPForm />}
                </div> */}
                <div className="content">
                    <LoginForm />
                </div>
            </div>
        </Layout>
    );
};

const LoginForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState({});

    const isValidForm = () => {
        if (!data.mobile) return false;
        if (data.mobile?.length !== 11) return false;
        if (!data.pin) return false;
        return true;
    };

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case "mobile":
                value = value.replace(/\D/g, "").slice(0, 11);
                break;
            case "pin":
                value = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 12);
                break;
        }

        setData({ ...data, [name]: value });
    };

    const handleOnSubmit = () => {
        if (!data.mobile || !data.pin) {
            Dialog({ title: "Notice", text: "Please fill in the blanks", button: "Confirm" });
            return;
        }

        const params = {
            phoneNo: data.mobile,
            password: data.pin,
            loginType: "PIN",
            deviceClassCode: _DX.getDevice(),
        };

        _DX.post({ path: "/api/searchLoginPlayer.do", data: params })
            .then((res) => res.data)
            .then((data) => {
                _CK.setAuth(data);
                if (_CK.isDevice()) {
                    window.location.href = `${_CFG.app.schema}logined?auth=${encodeURIComponent(data)}`;
                } else {
                    const { search } = location;
                    const queries = qs.parse(search);
                    navigate(queries?.url || "/app", { replace: true });
                }
            })
            .catch((e) => Dialog({ title: "Error", text: e.message, button: "Confirm" }));
    };

    const handleOnJoin = () => {
        Dialog({ body: <JoinDlgContent />, button: ["Exit", "18 years old"] }).then((will) => {
            if (will) {
                ToLink(navigate, "/app/join");
            }
        });
    };

    const handleForgotPin = () => {
        Dialog({ title: "Forgot PIN?", body: <ForgotPinContent navigate={navigate} />, button: "Close" }).then((isOk) => {
            console.log(isOk);
        });
    };

    return (
        <Fragment>
            <div className="form login">
                <div className="form-item">
                    <div className="form-field">
                        <input type="text" name="mobile" className="input" value={data.mobile || ""} placeholder="Phone No" onChange={handleOnChange} />
                        <label id="mobile">Phone No</label>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-field">
                        <input type="password" name="pin" className="input" value={data.pin || ""} placeholder="PIN" onChange={handleOnChange} />
                        <label id="ID">PIN</label>
                    </div>
                </div>
                <div className="autologin">
                    <a href={void 0} onClick={handleOnJoin} title="Register Account" className="btn-text">
                        Register Account
                    </a>
                    <a href={void 0} onClick={handleForgotPin} className="btn-text" title="I forgot my password">
                        Forgot PIN?
                    </a>
                </div>

                <p className="info">
                    By login or sign up, you are confirming that you are above 18 years old, and agreed to our{" "}
                    <Link href="/app/terms" className="btn-text udline">
                        <a>TERMS AND CONDITIONS</a>
                    </Link>
                </p>
            </div>
            <div className="btn-group mt-30">
                <button type="button" className="btn btn-primary" disabled={!isValidForm()} onClick={handleOnSubmit}>
                    Login
                </button>
            </div>
        </Fragment>
    );
};

const OTPForm = () => {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [isSend, setIsSend] = useState(false);

    const handleSend = () => {
        if (!mobile) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "SENDOTP",
                TelNum: mobile,
            },
        })
            .then((res) => {
                setIsSend(true);
                Dialog({ title: "Notice", text: "An OTP has been sent to the mobile number you provided.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    const handleVerify = () => {
        if (!otp) return;
        _DX.post({
            path: "/api/OtpAuthApi.do",
            data: {
                SvcID: "OTP",
                ApiName: "CHKOTP",
                TelNum: mobile,
                OtpNum: otp,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setIsSend(false);
                Dialog({ title: "Notice", text: "OTP verification was successful.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    const handleOnJoin = () => {
        Dialog({ body: <JoinDlgContent />, button: ["Exit", "18+ years old"] }).then((will) => {
            if (will) {
                ToLink(navigate, "/app/join");
            }
        });
    };

    return (
        <Fragment>
            <div className="form login">
                <div className="form-item">
                    <div className="form-field">
                        <input type="number" name="mobile" className="input" value={mobile} placeholder="Phone No" onChange={(e) => setMobile(e.target.value)} />
                        <label>Phone No</label>
                        <button type="button" className="btn-send" disabled={isSend} onClick={handleSend}>
                            send
                        </button>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-field">
                        <input type="number" name="pin" className="input" value={otp} placeholder="PIN" onChange={(e) => setOtp(e.target.value)} />
                        <label>OTP</label>
                    </div>
                </div>
                <div className="autologin">
                    <a href={void 0} onClick={handleOnJoin} title="Register Account" className="btn-text">
                        Register Account
                    </a>
                </div>

                <p className="info">
                    By login or sign up, you are confirming that you are above 18 years old, and agreed to our{" "}
                    <Link href="/app/terms" className="btn-text udline">
                        <a>TERMS AND CONDITIONS</a>
                    </Link>
                </p>
            </div>
            <div className="btn-group mt-30">
                <button type="button" className="btn btn-primary" disabled={!isSend} onClick={handleVerify}>
                    Login
                </button>
            </div>
        </Fragment>
    );
};

const JoinDlgContent = () => {
    return (
        <JoinDlgViewContainer>
            <div className="logo">
                <img src="/assets/images/logo_300.png" alt="" />
            </div>
            <div className="content">
                <img src="/assets/images/img_login_info.png" alt="" />
            </div>
        </JoinDlgViewContainer>
    );
};

const JoinDlgViewContainer = styled.div`
    text-align: center;

    .logo {
        img {
            width: 58px;
            object-fit: cover;
        }
    }

    .content {
        margin-top: 15px;
        img {
            width: 164px;
            object-fit: cover;
        }
    }
`;

const ForgotPinContent = ({ navigate, resolve, reject }) => {
    const [data, setData] = useState({ mobile: "", birthday: "" });

    const canNext = () => {
        if (!data.mobile) return false;
        if (!data.birthday) return false;
        return true;
    };

    const handleCheckClicked = () => {
        if (!data.mobile) return;
        if (!data.birthday) return;

        const params = {
            phoneNum: data.mobile,
            birthDay: moment(data.birthday, "YYYY-MM-DD").format("MMDDYYYY"),
        };
        _DX.post({
            path: "/api/searchPhoneBirthChk.do",
            data: params,
        })
            .then((res) => res.data)
            .then((data) => {
                resolve?.();
                if (data === "PASS") {
                    ToLink(navigate, "/app/forgot");
                    return;
                }
                Dialog({ title: "Notice", text: "Please check your informations.", button: "OK" });
            })
            .catch((e) => {
                reject?.();
                _DX.alert(e);
            });
    };

    return (
        <ForgotPinContentContainer>
            <div className="desc">
                To change your PIN
                <br />
                Enter your Phone Number and date of birth
            </div>
            <div className="form login">
                <div className="form-item">
                    <div className="form-field">
                        <input type="number" name="mobile" className="input" value={data.mobile} placeholder="Phone No" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                        <label>Phone No</label>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-field">
                        <input
                            type="date"
                            name="birthday"
                            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                            className="input"
                            value={data.birthday}
                            placeholder="Birthday"
                            onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        />
                        <label>Birthday</label>
                        <button type="button" className="btn-send" disabled={!canNext()} onClick={handleCheckClicked}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </ForgotPinContentContainer>
    );
};

const ForgotPinContentContainer = styled.div`
    margin: 25px 0 0;
    flex: 1;
    .desc {
        text-align: center;
    }
`;
