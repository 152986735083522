import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import cx from "classnames";
import QRCode from "react-qr-code";

import Layout from "@layout/App";
import { BackHeader as Header } from "@layout/App/Header";
import { ToBack, Back } from "@component/UI/Link";
import Loading from "@component/UI/Loading";

import _CFG from "@config/defaults";
import _DEF from "@config/defines";
import _CK from "@util/cookie";
import _U from "@util/utilities";
import _DX from "@util/dx";

export default () => {
    const navigate = useNavigate();
    const params = useParams();

    const [ids, setIds] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (params?.ids) {
            const { ids } = params;
            setIds(ids?.split(","));
            setIsLoaded(true);
        }
    }, [params]);

    return (
        <Layout noFooter header={<Header />}>
            <Container className="nwrap">
                <div className="content receipt">
                    <h4 className="title">Thank you for playing!</h4>
                    {ids.map((id, i) => (
                        <Recepit key={i} id={id} isLast={ids.length === i + 1} />
                    ))}
                </div>
            </Container>
            {!isLoaded && <Loading dark />}
        </Layout>
    );
};

const Recepit = ({ id, isLast }) => {
    const [data, setData] = useState([]);

    const loadData = () => {
        if (!id) return;

        _DX.post({ path: "/api/searchMyPlayDetailList.do", data: [{ playsId: id }] })
            .then((res) => res.data)
            .then((data) => setData(data.games))
            .catch((e) => _DX.alert(e));
    };

    useEffect(() => {
        loadData();
    }, [id]);

    if (!data || data.length === 0) return null;

    const def = data[0];

    return (
        <div className="ticket">
            {/* <h4 className="ticket-number">No. of New Ticket(s) : {def.no}</h4> */}
            {/* <div className="ticket-subTxt">
                {_U.addComma(def.round)} (Draw Date : {moment(def.raffleDate).format("ll HH:mm")})
            </div> */}
            <div className="draw-info-box WMark">
                <div className="top">
                    <p
                        className={cx("ticket-tit", {
                            base: def.TYPE === "BASE",
                            mega: def.TYPE === "MEGA",
                        })}
                    >
                        Prutas Panalo {def.TYPE} {def.ROUND}
                    </p>
                    <div className="qrcode">
                        <QRCode size={44} style={{ height: "44px", width: "44px" }} value={def.QRCODE} viewBox={`0 0 256 256`} />
                    </div>
                </div>
                <div className={`game-list ${data.some((item) => item.TYPE === "MEGA") ? "mega" : ""}`}>
                    {data.map((item, i) => (
                        <div key={i} className="item">
                            <span className="no">{String.fromCharCode(65 + i)}</span> :&nbsp;
                            <img src={`/assets/fruits/${item.FRUIT1.toLowerCase()}-sm.png`} alt={item.FRUIT1} className="fruit" />
                            <img src={`/assets/fruits/${item.FRUIT2.toLowerCase()}-sm.png`} alt={item.FRUIT2} className="fruit" />
                            <img src={`/assets/fruits/${item.FRUIT3.toLowerCase()}-sm.png`} alt={item.FRUIT3} className="fruit" />
                            {item.TYPE === "MEGA" && (
                                <Fragment>
                                    <span className="number">{item.NUMBER1}</span>
                                    <span className="number">{item.NUMBER2}</span>
                                    <span className="number">{item.NUMBER3}</span>
                                </Fragment>
                            )}
                            <span className="lp">LP</span>
                        </div>
                    ))}
                </div>
                <div className="info">
                    <div className="lside">{moment(def.REGDATE).format("ll HH:mm")}</div>
                    <div className="rside">Price : ₱ {(def.TYPE === "MEGA" ? 20 : 10) * data.length}</div>
                </div>
                {/* <div className="info">0000 0012 4325 1234 5678 5093 4568 7950</div> */}
                <div className="info">
                    <div className="lside">G8UCD51DE52VHEVS</div>
                    {/* <div className="rside">TID: 1112-8989-12</div> */}
                </div>
                <div className="info">
                    <div className="lside">
                        {def.ROUND} Draw {moment(def.RAFFLEDATE).format("ll HH:mm")}
                    </div>
                    <div className="rside">Payment due date {moment(def.DUEDATE).format("ll")}</div>
                </div>
            </div>
            <hr />
            {isLast && (
                <Fragment>
                    <div className="ticket">
                        <h4 className="ticket-subTxt">Prutas Panalo (Backside)</h4>
                        <div className="draw-info-box">
                            <h4 className="ticket-tit">PCSO</h4>
                            <p className="info">
                                Other data or information that may be prescribed by PCSO
                                <br />
                                <br />※ Your ticket can not cancel or refund
                            </p>
                        </div>
                    </div>
                    <div className="actions btn-group mt-30">
                        <Back className="btn btn-primary">
                            <button>Done</button>
                        </Back>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div``;
