import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";

import { AppContext } from "@component/AppProvider";
import Link, { ToLink } from "@component/UI/Link";
import Dialog from "@component/UI/Dialog";
import Loading from "@component/UI/Loading";

import _DEF from "@config/defines";
import _CK from "@util/cookie";
import _U from "@util/utilities";
import _DX from "@util/dx";

import useBalance from "@hook/useBalance";

const _TYPES = [
    { label: "MEGA", value: "MEGA" },
    { label: "BASE", value: "BASE" },
];

export default ({ className, round, jackpot, onRefresh }) => {
    const navigate = useNavigate();
    const [balance, setBalance] = useState(0);
    const { iam, amounts } = useContext(AppContext);
    const initGames = () => {
        return [
            { number1: null, number2: null, number3: null, fruit1: null, fruit2: null, fruit3: null },
            { number1: null, number2: null, number3: null, fruit1: null, fruit2: null, fruit3: null },
            { number1: null, number2: null, number3: null, fruit1: null, fruit2: null, fruit3: null },
            { number1: null, number2: null, number3: null, fruit1: null, fruit2: null, fruit3: null },
            { number1: null, number2: null, number3: null, fruit1: null, fruit2: null, fruit3: null },
        ];
    };

    const [type, setType] = useState(_TYPES[0].value);
    const [games, setGames] = useState(initGames());
    const [draw, setDraw] = useState(1);
    const [picking, setPicking] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isSavedSelection, setIsSavedSelection] = useState(false);
    const [isBuyProc, setIsBuyProc] = useState(false);

    const getRandom = (arr) => {
        return arr[Math.floor(Math.random() * arr.length)];
    };

    const handleOnTabClick = (type) => {
        setType(type);
        setGames(initGames());
    };

    const handleOnClick = (mode, index, kind) => {
        if (!_CK.isLogin()) {
            Dialog({
                title: "Thank you for choosing Prutas Panalo",
                text: "Sign up now to use the service. \nIf you are not a registered player, please create a new account first.",
                button: ["Close", "Sign in"],
            }).then((will) => {
                if (will) {
                    ToLink(navigate, "/app/login");
                }
            });
            return;
        }
        switch (mode) {
            case "SAVE":
                setIsSavedSelection(!isSavedSelection);
                break;
            case "FAVORITE":
                setIsFavorite(!isFavorite);
                break;
            case "CLEAR":
                const ng = [...games];
                ng[index] = {
                    number1: null,
                    number2: null,
                    number3: null,
                    fruit1: null,
                    fruit2: null,
                    fruit3: null,
                };
                setGames(ng);
                break;
            case "PICKING":
                if (kind === "LP") {
                    const ng = [...games];
                    ng[index] = {
                        fruit1: getRandom(_DEF.game.fruit),
                        fruit2: getRandom(_DEF.game.fruit),
                        fruit3: getRandom(_DEF.game.fruit),
                    };
                    if (type === "MEGA") {
                        ng[index].number1 = getRandom(_DEF.game.number);
                        ng[index].number2 = getRandom(_DEF.game.number);
                        ng[index].number3 = getRandom(_DEF.game.number);
                    }
                    setGames(ng);
                } else {
                    setPicking(index);
                }
                break;
            case "CHARGE":
                ToLink(navigate, "/app/my/charge");
                break;
            case "BUY":
                {
                    const g = games.filter((o) => o.fruit1 && o.fruit2 && o.fruit3);
                    if (g.length === 0) return;

                    setIsBuyProc(true);

                    _DX.post({ path: "/api/insertPlayerGameBuy.do", data: { round, type, games: g, draw } })
                        .then((res) => {
                            if (res.error !== 0) throw { message: res.message };
                            return res.data;
                        })
                        .then((data) => {
                            const ids = data?.map((o) => o.playsId);
                            const idsText = encodeURIComponent(ids.join(","));
                            setIsBuyProc(false);
                            onRefresh?.();
                            setGames(initGames());
                            ToLink(navigate, `/app/game/${idsText}`);
                        })
                        .catch((e) => {
                            setIsBuyProc(false);
                            _DX.alert(e);
                        });
                }
                break;
        }
    };

    const handleOnPicks = (picks) => {
        const ng = [...games];
        ng[picking] = { ...picks };
        setGames(ng);
        setPicking(null);
    };

    const handleOnAddFavorite = (game) => {
        if (!game.fruit1 || !game.fruit2 || !game.fruit3) return;
        if (type === "MEGA") {
            if (!game.number1 || !game.number2 || !game.number3) return;
        }

        Dialog({ text: "Do you want to add it to your favorites?", button: ["Cancel", "Confirm"] }).then((isConfirm) => {
            if (isConfirm) {
                addFavorite({ ...game, type });
            }
        });
    };

    const addFavorite = (data) => {
        _DX.post({
            path: "/api/insertFavoritesList.do",
            data: {
                games: { ...data, type: type === "MEGA" ? "M" : "B" },
            },
        })
            .then((res) => {
                Dialog({ text: "Added to favorites.", button: "OK" });
            })
            .catch((e) => _DX.alert(e));
    };

    const handleFavoritePicked = (game) => {
        setIsFavorite(false);
        const ng = [...games];
        for (let i = 0; i < ng.length; i++) {
            const d = ng[i];
            if (!d.fruit1) {
                // d.fruit1 = game.fruit1;
                // d.fruit2 = game.fruit2;
                // d.fruit3 = game.fruit3;
                // d.number1 = game.number1;
                // d.number2 = game.number2;
                // d.number3 = game.number3;
                d.fruit1 = game.SELFRUITCD1;
                d.fruit2 = game.SELFRUITCD2;
                d.fruit3 = game.SELFRUITCD3;
                d.number1 = game.SELNUM1;
                d.number2 = game.SELNUM2;
                d.number3 = game.SELNUM3;
                break;
            }
        }
        setGames(ng);
    };

    const getTotal = () => {
        if (!amounts) return;
        const bet = amounts?.price[type.toLowerCase()];
        const gCnt = games.filter((o) => o.fruit1 && o.fruit2 && o.fruit3).length;
        const total = bet * gCnt * draw;
        return total;
    };

    useBalance((balance) => {
        setBalance(balance);
    });

    const drawSize = Array.from({ length: 21 }, (v, i) => i + 1);

    return (
        <Fragment>
            <Container className={className}>
                <div className="types">
                    {_TYPES.map((item, i) => (
                        <button key={i} type="button" onClick={handleOnTabClick.bind(this, item.value)} className={cx({ active: type === item.value, [item.value.toLowerCase()]: true })}>
                            {item.label}
                        </button>
                    ))}
                </div>
                <div className="types-cont">
                    {/* {type === "MEGA" && (
                        <div className="tcont-item">
                            <b className="case">jackpot-amount</b>
                            <span className="amount jackpot">₱ {_U.addComma(jackpot)}</span>
                        </div>
                    )} */}
                    <div className="tcont-item">
                        <b className="case">Select Combo(s)</b>
                        <div className="btn-group">
                            {/* <button type="button" className="btn btn-sm" onClick={handleOnClick.bind(this, "SAVE")}>
                                Save Selection
                            </button> */}
                            <button type="button" className="btn btn-sm" onClick={handleOnClick.bind(this, "FAVORITE")}>
                                Favorites
                            </button>
                        </div>
                    </div>
                    <div className="game-inner">
                        {games.map((item, i) => (
                            <div key={i} className="item">
                                <div className="number">
                                    <button type="button" onClick={handleOnClick.bind(this, "CLEAR", i)}>
                                        {String.fromCharCode(65 + i)}
                                    </button>
                                </div>
                                <div className="picker">
                                    <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "fruit1")} className={cx({ active: !!item.fruit1, fruit: true })}>
                                        {item.fruit1 ? <img src={`/assets/fruits/${item.fruit1.toLowerCase()}-sm.png`} alt={item.fruit1} /> : "?"}
                                    </button>
                                    <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "fruit2")} className={cx({ active: !!item.fruit2, fruit: true })}>
                                        {item.fruit2 ? <img src={`/assets/fruits/${item.fruit2.toLowerCase()}-sm.png`} alt={item.fruit2} /> : "?"}
                                    </button>
                                    <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "fruit3")} className={cx({ active: !!item.fruit3, fruit: true })}>
                                        {item.fruit3 ? <img src={`/assets/fruits/${item.fruit3.toLowerCase()}-sm.png`} alt={item.fruit3} /> : "?"}
                                    </button>

                                    {type === "MEGA" && (
                                        <Fragment>
                                            <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "number1")} className={cx({ active: !!item.number1 })}>
                                                {item.number1 || "?"}
                                            </button>
                                            <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "number2")} className={cx({ active: !!item.number2 })}>
                                                {item.number2 || "?"}
                                            </button>
                                            <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "number3")} className={cx({ active: !!item.number3 })}>
                                                {item.number3 || "?"}
                                            </button>
                                        </Fragment>
                                    )}
                                </div>
                                <div className="lp">
                                    <button type="button" onClick={handleOnClick.bind(this, "PICKING", i, "LP")}>
                                        LP
                                    </button>
                                </div>
                                <button type="button" className="btn-fav" aria-label="favorite modal open" onClick={handleOnAddFavorite.bind(this, item)} />
                            </div>
                        ))}
                    </div>
                    <div className="tcont-item">
                        <div className="case">
                            <b>Multiple Draws</b> <span className="fyi"> (Advance Plays)</span>
                        </div>
                        <div className="form-select">
                            <select value={draw} onChange={(e) => setDraw(e.target.value)}>
                                {drawSize.map((item, i) => (
                                    <option key={i} value={item}>
                                        {item}draw
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="tcont-item">
                        <div className="case">
                            <b> My Balance</b>
                            <button type="button" className="btn btn-charge_sm" onClick={handleOnClick.bind(this, "CHARGE")}>
                                Charge
                                {/*<img src="/assets/images/arr_white_sm.png" alt=""></img>*/}
                            </button>
                        </div>
                        <div className="amount">₱ {_U.addComma(balance || 0)}</div>
                    </div>
                    <div className="tcont-item">
                        <div className="case">
                            <b>Bet Amount</b> <span className="fyi">(₱{amounts?.price[type.toLowerCase()]} per bet)</span>
                        </div>
                        <div className="amount">₱ {_U.addComma(getTotal())}</div>
                    </div>
                </div>
            </Container>
            <div className="actions btn-group mt-30">
                <Link href="/app/terms" className="term btn btn-secondary">
                    <button type="button">Play Terms</button>
                </Link>
                <button type="button" className="buy btn btn-primary" onClick={handleOnClick.bind(this, "BUY")} disabled={isBuyProc}>
                    Buy
                </button>
            </div>
            <Picker isShow={picking !== null} type={type} onClose={() => setPicking(null)} onChange={handleOnPicks} />
            <Favorites isShow={isFavorite} type={type} onClose={() => setIsFavorite(false)} onPick={handleFavoritePicked} />
            <SavedSelection isShow={isSavedSelection} type={type} onClose={() => setIsSavedSelection(false)} />
            {!amounts && <Loading dark />}
        </Fragment>
    );
};

const Picker = ({ isShow: isShowInProps, type, onClose, onChange }) => {
    const [isShow, setIsShow] = useState(isShowInProps);
    const [picks, setPicks] = useState({});

    const handleOnPick = (kind, value) => {
        setPicks({ ...picks, [kind]: value ? value : null });
    };

    const handleOnPickComplete = () => {
        if (!picks.fruit1 || !picks.fruit2 || !picks.fruit3) {
            Dialog({ title: "Notice", text: "Please select all fruits", button: "OK" });
            return;
        }

        if (type === "MEGA") {
            if (!picks.number1 || !picks.number2 || !picks.number3) {
                Dialog({ title: "Notice", text: "Please select all numbers", button: "OK" });
                return;
            }
        }

        onChange && onChange(picks);
    };

    useEffect(() => {
        setIsShow(isShowInProps);
        setPicks({});
        if (isShow) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [isShowInProps, isShow]);

    return (
        <PickerContainer isShow={isShow}>
            {/*<button type="button" onClick={onClose} className="close">
                <i className="fa fa-times" />
            </button>*/}
            <div className="modeless gameModal">
                <div className="picks">
                    <div className="pick-group">
                        <h4 className="title">Fruits</h4>
                        <div className="numbers fruits">
                            <div className="cols">
                                <h5 className="title">1st</h5>
                                {_DEF.game.fruit.map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: picks.fruit1 === item })} onClick={handleOnPick.bind(this, "fruit1", item)}>
                                        <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                    </button>
                                ))}
                            </div>
                            <div className="cols even">
                                <h5 className="title">2nd</h5>
                                {_DEF.game.fruit.map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: picks.fruit2 === item })} onClick={handleOnPick.bind(this, "fruit2", item)}>
                                        <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                    </button>
                                ))}
                            </div>
                            <div className="cols">
                                <h5 className="title">3rd</h5>
                                {_DEF.game.fruit.map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: picks.fruit3 === item })} onClick={handleOnPick.bind(this, "fruit3", item)}>
                                        <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {type === "MEGA" && (
                        <div className="pick-group">
                            <h4 className="title">Numbers</h4>
                            <div className="numbers">
                                <div className="cols">
                                    <h5 className="title">1st</h5>
                                    {_DEF.game.number.map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: picks.number1 === item })} onClick={handleOnPick.bind(this, "number1", item)}>
                                            {item}
                                        </button>
                                    ))}
                                </div>
                                <div className="cols even">
                                    <h5 className="title">2nd</h5>
                                    {_DEF.game.number.map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: picks.number2 === item })} onClick={handleOnPick.bind(this, "number2", item)}>
                                            {item}
                                        </button>
                                    ))}
                                </div>
                                <div className="cols">
                                    <h5 className="title">3rd</h5>
                                    {_DEF.game.number.map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: picks.number3 === item })} onClick={handleOnPick.bind(this, "number3", item)}>
                                            {item}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="btn-group">
                <button type="button" className="cancel btn btn-secondary" onClick={onClose}>
                    Close
                </button>
                <button type="button" className="done btn btn-primary" onClick={handleOnPickComplete}>
                    Done
                </button>
            </div>
        </PickerContainer>
    );
};

const Favorites = ({ isShow: isShowInProps, type, onClose, onPick }) => {
    const [isShow, setIsShow] = useState(isShowInProps);
    const [list, setList] = useState([]);

    const loadData = () => {
        _DX.post({ path: "/api/searchFavoritesList.do", data: { type: type === "MEGA" ? "M" : "B" } })
            .then((res) => {
                const { games } = res;
                setList(games);
            })
            .catch((e) => _DX.alert(e));
    };

    const handleOnDelete = (item) => {
        Dialog({ text: "Are you sure you want to delete it?", button: ["Cancel", "Confirm"] }).then((isConfirm) => {
            if (isConfirm) {
                _DX.post({
                    path: "/api/deleteFavoritesList.do",
                    data: {
                        games: {
                            fruit1: item.SELFRUITCD1,
                            fruit2: item.SELFRUITCD2,
                            fruit3: item.SELFRUITCD3,
                            number1: item.SELNUM1,
                            number2: item.SELNUM2,
                            number3: item.SELNUM3,
                            type: type === "MEGA" ? "M" : "B",
                        },
                    },
                })
                    .then((res) => {
                        loadData();
                    })
                    .catch((e) => _DX.alert(e));
            }
        });
    };

    useEffect(() => {
        setIsShow(isShowInProps);
        if (isShowInProps) {
            loadData();
        }
        if (isShow) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [isShowInProps, isShow]);

    return (
        <SelectorContainer isShow={isShow}>
            {/*<button type="button" onClick={onClose} className="close">
                <i className="fa fa-times" />
            </button>*/}
            <div className="modeless gameModal fav">
                <h3 className="title">Favorite</h3>
                <div className="picks">
                    <div className="pick-group">
                        {list?.map((item, i) => (
                            <div key={i} className="favPick">
                                <img src={`/assets/fruits/${item.SELFRUITCD1.toLowerCase()}-xlg.png`} alt={item.fruit1} />
                                <img src={`/assets/fruits/${item.SELFRUITCD2.toLowerCase()}-xlg.png`} alt={item.fruit2} />
                                <img src={`/assets/fruits/${item.SELFRUITCD3.toLowerCase()}-xlg.png`} alt={item.fruit3} />
                                {type === "MEGA" && (
                                    <Fragment>
                                        <input type="text" className="check-input" readOnly value={item.SELNUM1} />
                                        <input type="text" className="check-input" readOnly value={item.SELNUM2} />
                                        <input type="text" className="check-input" readOnly value={item.SELNUM3} />
                                    </Fragment>
                                )}
                                <div className="btn-pd-group">
                                    <button type="button" className="btn-pick" onClick={onPick?.bind(this, item)}>
                                        pick
                                    </button>
                                    <button type="button" className="btn-del" onClick={handleOnDelete.bind(this, item)}>
                                        del
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="btn-group">
                <button type="button" className="cancel btn btn-secondary" onClick={onClose}>
                    Close
                </button>
            </div>
        </SelectorContainer>
    );
};

const SavedSelection = ({ isShow: isShowInProps, type, onClose }) => {
    const [isShow, setIsShow] = useState(isShowInProps);
    const [picks, setPicks] = useState({});

    const handleOnPick = (kind, value) => {
        setPicks({ ...picks, [kind]: value ? value : null });
    };

    const handleOnPickComplete = () => {};

    useEffect(() => {
        setIsShow(isShowInProps);
        setPicks({});
    }, [isShowInProps]);

    const game = {
        number: [
            [13, 9, 4, 3, 11],
            [7, 8, 7, 7, 4],
            [1, 3, 13, 11, 12],
        ],
        fruit: [
            ["WATERMELON", "SAPODILLA", "DURIAN", "MANGOES", "BANANA"],
            ["COCONUT", "CUSTARD-APPLE", "MANGOES", "SAPODILLA", "WATERMELON"],
            ["CUSTARD-APPLE", "CUSTARD-APPLE", "DURIAN", "MANGOES", "DURIAN"],
        ],
    };

    return (
        <SelectorContainer isShow={isShow}>
            {/*<button type="button" onClick={onClose} className="close">
                <i className="fa fa-times" />
            </button>*/}
            <div className="modeless gameModal">
                <h3 className="title">My Saved Selections</h3>
                <div className="picks">
                    {type === "MEGA" && (
                        <div className="pick-group">
                            <h4 className="title">Fruits</h4>
                            <div className="numbers fruits">
                                <div className="cols">
                                    <h5 className="title">1st</h5>
                                    {game.fruit[0].map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "fruit1", item)}>
                                            <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                        </button>
                                    ))}
                                </div>
                                <div className="cols even">
                                    <h5 className="title">2nd</h5>
                                    {game.fruit[1].map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "fruit2", item)}>
                                            <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                        </button>
                                    ))}
                                </div>
                                <div className="cols">
                                    <h5 className="title">3rd</h5>
                                    {game.fruit[2].map((item, i) => (
                                        <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "fruit3", item)}>
                                            <img src={`/assets/fruits/${item.toLowerCase()}-xlg.png`} alt={item} />
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="pick-group">
                        <h4 className="title">Numbers</h4>
                        <div className="numbers">
                            <div className="cols">
                                <h5 className="title">1st</h5>
                                {game.number[0].map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "number1", item)}>
                                        {item}
                                    </button>
                                ))}
                            </div>
                            <div className="cols even">
                                <h5 className="title">2nd</h5>
                                {game.number[1].map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "number2", item)}>
                                        {item}
                                    </button>
                                ))}
                            </div>
                            <div className="cols">
                                <h5 className="title">3rd</h5>
                                {game.number[2].map((item, i) => (
                                    <button key={i} type="button" className={cx({ active: true })} onClick={handleOnPick.bind(this, "number3", item)}>
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-group">
                <button type="button" className="cancel btn btn-secondary" onClick={onClose}>
                    Close
                </button>
                <button type="button" className="done btn btn-primary" onClick={handleOnPickComplete}>
                    Select
                </button>
            </div>
        </SelectorContainer>
    );
};

/**
 * Styled-Components
 */
const Container = styled.div``;

const SelectorContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
    transition: all 0.5s;
    .modeless {
        position: fixed;
        padding: 0 0 80px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        webkit-overflow-scrolling: touch;
        background-color: #ffffff;
        top: ${({ isShow }) => (isShow ? "0" : "100vh")};
        transition: all 0.5s;
        .picks {
            /*height: calc(100vh - 80px - 54px);*/
            min-height: calc(100% + 80px);
        }
    }
    .close {
        position: fixed;
        width: 50px;
        height: 50px;

        font-size: 32px;
        background-color: transparent;
        border: none;
        color: #ffffff;
        top: 200px;
        right: ${({ isShow }) => (isShow ? "0px" : "-50px")};
        transition: all 0.5s;
    }
`;

const PickerContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
    transition: all 0.5s;
    .modeless {
        position: fixed;
        padding: 0 0 80px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        webkit-overflow-scrolling: touch;
        background-color: #ffffff;
        top: ${({ isShow }) => (isShow ? "0" : "100vh")};
        transition: all 0.5s;
        .picks {
            min-height: calc(100% + 80px);
        }
    }

    .close {
        position: fixed;
        width: 50px;
        height: 50px;

        font-size: 32px;
        background-color: transparent;
        border: none;
        color: #ffffff;

        top: 50px;
        right: ${({ isShow }) => (isShow ? "0px" : "-50px")};
        transition: all 0.5s;
    }
`;
