import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ iam: iamInProps, amounts: amountsInProps, children }) => {
    const [iam, setIam] = useState(iamInProps);
    const [amounts, setAmounts] = useState(amountsInProps);

    const saveIam = (iam) => {
        setIam(iam);
    };

    const saveAmounts = (amounts) => {
        setAmounts(amounts);
    };

    return <AppContext.Provider value={{ iam, saveIam, amounts, saveAmounts }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
