const defineds = {};

defineds.gender = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
];

defineds.province = [{ label: "Province", value: "" }];

defineds.bank = [
    { value: "B01", label: "Please select a bank" },
    { value: "B02", label: "Asia United Bank Corporation (AUB)" },
    { value: "B03", label: "Australia and New Zealand Banking Group Limited" },
    { value: "B04", label: "Bangkok Bank Public Company Limited, Manila Branch" },
    { value: "B05", label: "Bank of America, N.A. Manila" },
    { value: "B06", label: "Bank of China, Manila Branch" },
    { value: "B07", label: "Bank of Commerce" },
    { value: "B08", label: "Bank of the Philippine Islands (BPI)" },
    { value: "B09", label: "BDO Unibank, Inc. (BDO)" },
    { value: "B10", label: "BNP Paribas, Manila Offshore Branch" },
    { value: "B11", label: "BPI Direct" },
    { value: "B12", label: "BPI Family Savings Bank" },
    { value: "B13", label: "BPI Globe Banko Inc." },
    { value: "B14", label: "Cathay United Bank Co., LTD. Manila Branch" },
    { value: "B15", label: "China Banking Corporation (Chi" },
    { value: "B16", label: "China Bank Savings, Inc." },
    { value: "B17", label: "CIMB Bank Philippines, Inc." },
    { value: "B18", label: "Citibank, N.A. - Makati Branch" },
    { value: "B19", label: "Citibank, N.A. - Manila Branch" },
    { value: "B20", label: "Citibank, N.A. - Cebu Branch" },
    { value: "B21", label: "CTBC Bank (Philippines) Corp." },
    { value: "B22", label: 'Deutsche Bank AG"' },
    { value: "B23", label: "Development Bank of the Philippines (DBP)" },
    { value: "B24", label: "East West Banking Corporation (EastWest Bank)" },
    { value: "B25", label: "Equicom Savings Bank, Inc." },
    { value: "B26", label: "First Commercial Bank, Ltd., Manila Branch" },
    { value: "B27", label: "First Consolidated Bank" },
    { value: "B28", label: "Globe Telecom, Inc. (GCash)" },
    { value: "B29", label: "The Hongkong and Shanghai Banking Corporation Ltd - Philippine Branch (HSBC Philippines)" },
    { value: "B30", label: "HSBC Savings Bank (Philippines) Inc." },
    { value: "B31", label: "Hua Nan Commercial Bank, Ltd., Manila Branch" },
    { value: "B32", label: "Industrial Bank of Korea, Manila Branch" },
    { value: "B33", label: "ING Bank N.V." },
    { value: "B34", label: "JPMorgan Chase Bank, N.A., Manila Branch" },
    { value: "B35", label: "KEB Hana Bank" },
    { value: "B36", label: "Land Bank of the Philippines" },
    { value: "B37", label: "Maybank Philippines, Inc." },
    { value: "B38", label: "Mega International Commercial Bank Co., Ltd., Manila Branch" },
    { value: "B39", label: "Metropolitan Bank and Trust Co. (Metrobank)" },
    { value: "B40", label: "Mizuho Bank, Ltd., Manila Branch" },
    { value: "B41", label: "MUFG Bank, Ltd., Manila Branch (formerly Bank of Tokyo-Mitsubishi UFJ)" },
    { value: "B42", label: "Philippine Bank of Communications (PBCOM)" },
    { value: "B43", label: "Philippine Business Bank" },
    { value: "B44", label: "Philippine National Bank (PNB)" },
    { value: "B45", label: "Philippine Savings Bank (PSBank)" },
    { value: "B46", label: "Philippine Veterans Bank" },
    { value: "B47", label: "Philtrust Bank (Philippine Trust Company)" },
    { value: "B48", label: "Rizal Commercial Banking Corporation (RCBC)" },
    { value: "B49", label: "Robinsons Bank Corporation" },
    { value: "B50", label: "Security Bank Corporation" },
    { value: "B51", label: "Shinhan Bank Manila Branch" },
    { value: "B52", label: "Standard Chartered Bank - Makati Branch" },
    { value: "B53", label: "Standard Chartered Bank - Manila Branch" },
    { value: "B54", label: "Sterling Bank of Asia Inc." },
    { value: "B55", label: "Sumitomo Mitsui Banking Corporation Manila Branch" },
    { value: "B56", label: "Tonik Digital Bank, Inc." },
    { value: "B57", label: "Taiwan Cooperative Bank Manila Offshore Banking Branch" },
    { value: "B58", label: "Union Bank of the Philippines" },
    { value: "B59", label: "United Coconut Planters Bank (UCPB)" },
    { value: "B60", label: "United Overseas Bank Limited - Manila Branch" },
    { value: "B61", label: "Yuanta Savings Bank (formerly Tong Yang Savings Bank)" },
];

defineds.game = {
    number: [1, 2, 3, 4, 5, 6, 7],
    fruit: ["CALAMANSI", "MANGOES", "ATIS", "KAHEL", "MANSANAS", "UBAS", "BUKO", "DURIAN", "CHERRY", "PAKWAN", "PINYA", "SAGING", "PAPAYA"],
};

defineds.loginType = [
    { label: "PIN", value: "PIN" },
    { label: "OTP", value: "OTP" },
];

defineds.accountType = [
    { label: "Mobile", value: "MOBILE" },
    { label: "Email", value: "EMAIL" },
];

defineds.occupation = [
    { label: "Government officials", value: "O01" },
    { label: "Special-interest org officials", value: "O02" },
    { label: "Corporate executives", value: "O03" },
    { label: "Managers", value: "O04" },
    { label: "Supervisors", value: "O05" },
    { label: "Professionals", value: "O06" },
    { label: "Associate professionals", value: "O07" },
    { label: "Technicians", value: "O08" },
    { label: "Clerks", value: "O09" },
    { label: "Service workers", value: "O10" },
    { label: "Sales workers", value: "O11" },
    { label: "Farmers", value: "O12" },
    { label: "Forestry workers", value: "O13" },
    { label: "Fishermen", value: "O14" },
    { label: "Traders", value: "O15" },
    { label: "Operators", value: "O16" },
    { label: "Machine assemblers", value: "O17" },
    { label: "Laborers", value: "O18" },
    { label: "Unskilled workers", value: "O19" },
    { label: "Special occupations", value: "O20" },
    { label: "Others", value: "O21" },
];

defineds.monthlyFixedIncome = [
    { label: "Less than 9,100", value: "M01" },
    { label: "9,100 to 18,200", value: "M02" },
    { label: "18,200 to 36,400", value: "M03" },
    { label: "36,400 to 63,700", value: "M04" },
    { label: "63,700 to 109,200", value: "M05" },
];

defineds.winType = ["J", "F", "S", "Y"];

defineds.getDefinedLabel = (arr, value) => {
    if (arr && value) {
        const result = arr.find((item) => {
            return item.value === value;
        });

        return result?.label || value;
    }
    return "";
};

defineds.getDefinedLabels = (dataArr, valueArr) => {
    if (!dataArr || dataArr.length === 0 || !valueArr || valueArr.length === 0) {
        return "";
    }
    const fl = dataArr.filter((o) => valueArr.includes(o.value));
    return fl.map((o) => o.label).join(",");
};

defineds.getDefinedValue = (arr, label) => {
    if (arr && label) {
        const result = arr.find((item) => {
            return item.label === label;
        });
        return result.value;
    }
    return "";
};

defineds.getChild = (arr, value) => {
    const f = arr.filter((o) => o.value === value);
    if (!f || f.length === 0) {
        return [];
    }
    return f[0].child;
};

export default defineds;
