import { useEffect } from "react";

import _CK from "@util/cookie";
import _DX from "@util/dx";

const useBalance = (onSuccess) => {
    const loadBalance = () => {
        if (!_CK.isLogin()) return onSuccess?.(0);

        _DX.post({ path: "/api/searchPlayerChargeBalance.do" })
            .then((res) => res.data)
            .then((data) => {
                if (!data) {
                    onSuccess?.(0);
                    return;
                }
                const { BALANCEAMT: balance } = data;
                onSuccess?.(parseInt(balance));
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadBalance();
        const timer = setInterval(loadBalance, 10000);
        return () => {
            clearInterval(timer);
        };
    }, []);
};

export default useBalance;
